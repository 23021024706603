import {
  ACTION_CHARS,
  CATEGORY_ACTION,
  CHAR_ACTIONS,
  FilterAction,
  RangeParameters,
} from "features/Ranges/types"
import { useContext, useEffect, useMemo, useState } from "react"
import {
  useGetRangeByActionsQuery,
  useGetStrategiesQuery,
} from "services/rangesApi"
import { RangeInfo, RangesFilter } from "services/types"
import { HandActionIndex, hhContext } from "./HHContext"
import { HandPosition } from "./types"
import { endGameAction } from "./utils"

export function useGetDefaultStrategy() {
  const query = useGetStrategiesQuery()
  return query.isSuccess ? query.data.filter((v) => v.is_default)[0] : undefined
}

export function useGetPositionRange(
  position: HandPosition,
  currentAction: HandActionIndex
): (RangeInfo & RangeParameters) | undefined {
  const [filter, setFilter] = useState<RangesFilter | undefined>()
  const context = useContext(hhContext)
  const playerStackSize =
    context.hand.beginningState?.playerStates[position]?.stack
  const strategy = useGetDefaultStrategy()

  const rangeByActionsQuery = useGetRangeByActionsQuery(filter || {}, {
    skip: filter === undefined,
  })

  const filterActions = useMemo(() => {
    if (currentAction === undefined) return []
    if (currentAction.index <= 0) return []
    if (currentAction.street !== "preflop") return []
    return context.hand.preFlop?.actions
      .slice(0, currentAction.index)
      .map((action) => {
        const rv: FilterAction = {
          position: action.position,
          action: CHAR_ACTIONS[action.actionChar] || "FOLD",
          raiseAmount: action.raisingAmount ? action.raisingAmount : undefined,
        }
        return rv
      })
  }, [context.hand.preFlop?.actions, currentAction])

  const parameters = useMemo(() => {
    if (
      strategy &&
      strategy.id &&
      playerStackSize &&
      filterActions !== undefined
    ) {
      const closestStackSize = strategy.sizes.reduce(function (prev, curr) {
        return Math.abs(curr.size - playerStackSize) <
          Math.abs(prev.size - playerStackSize)
          ? curr
          : prev
      })

      return {
        rangeset: strategy?.id,
        stackSize: closestStackSize.size,
        heroPosition: position,
        category: CATEGORY_ACTION,
        filterActions: filterActions,
      } as RangeParameters
    }
  }, [strategy, playerStackSize, position, filterActions])

  useEffect(() => {
    if (
      parameters != undefined &&
      parameters.category &&
      parameters.heroPosition &&
      parameters.rangeset &&
      parameters.stackSize &&
      currentAction.street == "preflop"
    ) {
      setFilter({
        rangeset_id: parameters.rangeset,
        stack_size: parameters.stackSize,
        position: parameters.heroPosition,
        path: parameters.filterActions
          ? parameters.filterActions
              .map(
                (x) =>
                  x.position +
                  " " +
                  ACTION_CHARS[x.action] +
                  (x.action === "RAISE" ? x.raiseAmount : "")
              )
              .join(", ")
          : "",
      })
    }
  }, [parameters, setFilter, currentAction])

  const rv = useMemo(() => {
    if (currentAction.street !== "preflop") return undefined
    if (rangeByActionsQuery.isSuccess && parameters)
      return Object.assign({}, rangeByActionsQuery.data, parameters)
  }, [rangeByActionsQuery, parameters, currentAction])
  return rv
}

export function useIsCurrentActionLast(): boolean {
  const context = useContext(hhContext)
  return endGameAction(context.hand, context.currentActionIndex)
}

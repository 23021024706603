import { Box } from "@mui/material"
import { useContext } from "react"
import { HandActionIndex, HHContext, hhContext } from "./HHContext"
import { seatByCountAndPosition } from "./TableView"
import {
  Hand,
  HandAction,
  HandActionChar,
  HandPlayerStateInfo,
  HandPosition,
} from "./types"

export function getCurrentAction(context: HHContext): HandAction | undefined {
  if (context.currentActionIndex === undefined) return undefined
  switch (context.currentActionIndex.street) {
    case "preflop":
      return context.hand.preFlop?.actions
        ? context.hand.preFlop?.actions[context.currentActionIndex.index]
        : undefined
    case "flop":
      return context.hand.flop?.actions
        ? context.hand.flop?.actions[context.currentActionIndex.index]
        : undefined
    case "turn":
      return context.hand.turn?.actions
        ? context.hand.turn?.actions[context.currentActionIndex.index]
        : undefined
    case "river":
      return context.hand.river?.actions
        ? context.hand.river?.actions[context.currentActionIndex.index]
        : undefined
  }
}

export function getHandAction(
  hand: Hand,
  currentActionIndex: HandActionIndex
): HandAction | undefined {
  switch (currentActionIndex.street) {
    case "preflop":
      return hand.preFlop?.actions
        ? hand.preFlop?.actions[currentActionIndex.index]
        : undefined
    case "flop":
      return hand.flop?.actions
        ? hand.flop?.actions[currentActionIndex.index]
        : undefined
    case "turn":
      return hand.turn?.actions
        ? hand.turn?.actions[currentActionIndex.index]
        : undefined
    case "river":
      return hand.river?.actions
        ? hand.river?.actions[currentActionIndex.index]
        : undefined
  }
}

export function getPrevPositionState(
  context: HHContext,
  actionIndex: HandActionIndex,
  position: HandPosition
): HandPlayerStateInfo | undefined {
  switch (actionIndex.street) {
    case "preflop":
      if (actionIndex.index <= 0)
        return context.hand.forcedBetsState?.playerStates[position]
      else
        return context.hand.preFlop?.actions[actionIndex.index - 1].state
          .playerStates[position]
    case "flop":
      if (actionIndex.index <= 0)
        return context.hand.preFlop?.actions[
          context.hand.preFlop ? context.hand.preFlop.actions.length - 1 : 0
        ].state.playerStates[position]
      else
        return context.hand.flop?.actions[actionIndex.index - 1].state
          .playerStates[position]
    case "turn":
      if (actionIndex.index <= 0)
        return context.hand.flop?.actions[
          context.hand.flop ? context.hand.flop.actions.length - 1 : 0
        ].state.playerStates[position]
      else
        return context.hand.turn?.actions[actionIndex.index - 1].state
          .playerStates[position]
    case "river":
      if (actionIndex.index <= 0)
        return context.hand.turn?.actions[
          context.hand.turn ? context.hand.turn.actions.length - 1 : 0
        ].state.playerStates[position]
      else
        return context.hand.river?.actions[actionIndex.index - 1].state
          .playerStates[position]
  }
}

export function getCurrentActionSeat(context: HHContext): number | undefined {
  const action = getCurrentAction(context)

  if (!action || !action.state || !action.state.playerStates) return undefined
  if (!context.hand.playersCount || !context.hand.playersCount.playersCount)
    return undefined

  return seatByCountAndPosition(
    context.hand.playersCount.playersCount,
    action.position
  )
}

export function getActionString(chr: HandActionChar): string {
  switch (chr) {
    case "f":
      return "Fold"
    case "c":
      return "Call"
    case "x":
      return "Check"
    case "b":
      return "Bet"
    case "r":
      return "Raise"
    case "a":
      return "AllIn"
  }
}

export function ActionPointer() {
  const context = useContext(hhContext)
  const seat = getCurrentActionSeat(context)
  const action = getCurrentAction(context)
  const actionPointer = context.currentActionIndex

  if (actionPointer === undefined) return null
  if (!action) return null
  if (!seat || seat <= 0) return null

  const curentPlayerState: HandPlayerStateInfo | undefined =
    getPrevPositionState(context, context.currentActionIndex, action.position)
  if (curentPlayerState === undefined) return null

  const diagonalClass: Record<string, string> = {
    1: "diagonaltb",
    5: "diagonaltb",
    8: "diagonalbt",
    10: "diagonaltb",
    3: "diagonalbt",
    4: "diagonalbt",
    6: "diagonaltb",
    9: "diagonalbt",
  }
  return (
    <div
      className={`seat-pointer seat-${seat}-pointer ${
        diagonalClass[`${seat}` as string]
      }`}
    >
      <div className={`seat-pointer-action seat-${seat}-pointer-action`}>
        <Box sx={{ pr: 1 }}>
          {action.actionString}{" "}
          {action.actionChar == "r" ? action.raisingAmount?.toFixed(2) : ""}
          {action.actionChar == "r" ? "BB" : ""}
        </Box>
      </div>
    </div>
  )
}

import { HandActionChar, HandPosition } from "../HHReplay/types"

export const ACTION_COLORS = {
  ALL_IN: "darkred",
  RAISE: "orange",
  CALL: "seagreen",
  FOLD: "darkgray",
  CHECK: "seagreen",
}

export const ACTION_NAMES = {
  ALL_IN: "AllIn",
  RAISE: "Raise",
  CALL: "Call",
  FOLD: "Fold",
  CHECK: "Check",
}

export type Category = "RFI" | "vsRFI" | "vs3Bet" | "ActionSet"
export const CATEGORY_RFI: Category = "RFI"
export const CATEGORY_VSRFI: Category = "vsRFI"
export const CATEGORY_VS3BET: Category = "vs3Bet"
export const CATEGORY_ACTION: Category = "ActionSet"

export const CATEGORIES = [
  {
    category: CATEGORY_RFI,
    label: "RFI",
    enabled: true,
  },
  {
    category: CATEGORY_VSRFI,
    label: "vs. RFI",
    enabled: false,
  },
  {
    category: CATEGORY_VS3BET,
    label: "vs. 3bet",
    enabled: false,
  },
  {
    category: CATEGORY_ACTION,
    label: "Action Set",
    enabled: true,
  },
]

export interface HERO_POSITION {
  position: HandPosition
  label: string
  enabled: boolean
}

export const HERO_POSITIONS: HERO_POSITION[] = [
  {
    position: "BB",
    label: "BB",
    enabled: true,
  },
  {
    position: "SB",
    label: "SB",
    enabled: true,
  },
  {
    position: "BTN",
    label: "BTN",
    enabled: true,
  },
  {
    position: "CO",
    label: "CO",
    enabled: true,
  },
  {
    position: "HJ",
    label: "HJ",
    enabled: true,
  },
  {
    position: "LJ",
    label: "LJ",
    enabled: true,
  },
  {
    position: "UTG7",
    label: "UTG-7",
    enabled: true,
  },
  {
    position: "UTG8",
    label: "UTG-8",
    enabled: true,
  },
  {
    position: "UTG9",
    label: "UTG-9",
    enabled: true,
  },
  {
    position: "UTG10",
    label: "UTG-10",
    enabled: true,
  },
]

export type Action = "ALL_IN" | "RAISE" | "CALL" | "FOLD" | "CHECK"

export type ActionRatios = { [key in Action]?: number }

export const ACTION_CHARS: { [key in Action]?: HandActionChar } = {
  ALL_IN: "a",
  RAISE: "r",
  CALL: "c",
  FOLD: "f",
}

export const CHAR_ACTIONS: { [key in HandActionChar]?: Action } = {
  a: "ALL_IN" as Action,
  r: "RAISE" as Action,
  c: "CALL" as Action,
  f: "FOLD" as Action,
}

export interface HandActionsRatio {
  handCombinationId: string
  actionRatio: ActionRatios
}

export interface FilterAction {
  position: HandPosition
  action: keyof typeof ACTION_NAMES
  raiseAmount?: number
}

export type SearchCategory = "RFI" | "ActionSet"

export type RangeParameters = {
  rangeset?: string
  stackSize?: number
  category?: SearchCategory
  heroPosition?: HandPosition
  opponentPosition?: HandPosition
  filterActions?: FilterAction[]
}

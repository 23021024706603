/* eslint-disable  @typescript-eslint/no-explicit-any */

import { HandPosition } from "./HHReplay/types"

export const Cards: string[] = [
  "A",
  "K",
  "Q",
  "J",
  "T",
  "9",
  "8",
  "7",
  "6",
  "5",
  "4",
  "3",
  "2",
]

export const PositionLabels: { [key in HandPosition]: string } = {
  BB: "BB",
  SB: "SB",
  BTN: "BTN",
  CO: "CO",
  HJ: "HJ",
  LJ: "LJ",
  UTG7: "UTG-7",
  UTG8: "UTG-8",
  UTG9: "UTG-9",
  UTG10: "UTG-10",
}

export const Suits: string[] = ["d", "h", "c", "s"]

export type CardValue = typeof Cards[number]

export type CardSuit = typeof Suits[number]

export type Card = {
  value: CardValue
  suit: CardSuit
}

export function cardToString(card: Card): string {
  return card.value + card.suit
}

export function cardsToString(cards: Card[]): string {
  return cards.map((c) => cardToString(c)).join("")
}

export function pairToCombo(pairCards: Card[]): string {
  const cards = [...pairCards].sort((a, b) =>
    Cards.indexOf(a.value) > Cards.indexOf(b.value) ? 1 : -1
  )
  if (cards[0].value == cards[1].value)
    return `${cards[0].value}${cards[0].value}`
  if (cards[0].suit == cards[1].suit)
    return `${cards[0].value}${cards[1].value}${cards[0].suit}`
  return `${cards[0].value}${cards[0].suit}${cards[1].value}${cards[1].suit}`
}

export function deepCopy<T>(instance: T): T {
  if (instance == null) {
    return instance
  }

  // handle Dates
  if (instance instanceof Date) {
    return new Date(instance.getTime()) as any
  }

  // handle Array types
  if (instance instanceof Array) {
    const cloneArr = [] as any[]
    const instanceArray = instance as any[]
    instanceArray.forEach((value) => {
      cloneArr.push(value)
    })
    // for nested objects
    return cloneArr.map((value: any) => deepCopy<any>(value)) as any
  }
  // handle objects
  if (instance instanceof Object) {
    const copyInstance = { ...(instance as { [key: string]: any }) } as {
      [key: string]: any
    }
    for (const attr in instance) {
      if (instance[attr]) copyInstance[attr] = deepCopy<any>(instance[attr])
    }
    return copyInstance as T
  }
  // handling primitive data types
  return instance
}

import { Box } from "@mui/material"

export function PotView({ pot }: { pot: number | undefined }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* <Box sx={{ display: "flex", color: "gold" }}>
        <AttachMoneyIcon />
        <AttachMoneyIcon />
        <AttachMoneyIcon />
      </Box> */}
      <Box sx={{ fontWeight: "600" }}>Pot {pot?.toFixed(2)} BB</Box>
    </Box>
  )
}

import RestartAltIcon from "@mui/icons-material/RestartAlt"
import { Box, Typography } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import Slider from "@mui/material/Slider"
import { hhContext } from "features/HHReplay/HHContext"
import { handFromString, HandPositionOrder } from "features/HHReplay/types"
import { useCallback, useContext, useState } from "react"
import { RangeSet } from "services/types"
import { GameTreeView } from "./GameTreeView"

interface StackSizeSelectorProps {
  strategy: RangeSet
  stack: number
  changeStack: (n: number) => void
}

const StackSizeLinearSlider = (props: StackSizeSelectorProps) => {
  const { strategy, changeStack, stack } = props

  const sizes = [...strategy.sizes].sort((a, b) => (a.size > b.size ? 1 : -1))
  const selected = sizes.findIndex((e) => e.size == stack)
  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      changeStack(sizes[newValue].size)
    }
  }

  function valueLabelFormat(value: number) {
    return `${value}`
  }

  function calculateValue(value: number) {
    return strategy.sizes[value].size
  }

  const marks = sizes.map((stackSize, idx) => {
    return { value: idx, label: stackSize.size }
  })

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        pl: "24px",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography variant="body1" sx={{ fontWeight: "600" }}>
          Stack size
        </Typography>
      </Box>
      <Box sx={{ width: strategy.sizes.length * 30 }}>
        <Slider
          value={selected}
          min={0}
          step={1}
          max={sizes.length - 1}
          scale={calculateValue}
          getAriaValueText={valueLabelFormat}
          onChange={handleChange}
          aria-labelledby="non-linear-slider"
          marks={marks}
        />
      </Box>
    </Box>
  )
}

interface NumPlayersSelectorProps {
  players: number
  changeNPlayers: (n: number) => void
}

const NumPlayersSelector = (props: NumPlayersSelectorProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    props.changeNPlayers(Number(event.target.value))
  }

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small">Players</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={props.players.toString()}
        label="Players"
        onChange={handleChange}
      >
        {Array.from(Array(9), (_, i) => i + 2).map((nPplayers) => {
          return (
            <MenuItem
              key={nPplayers}
              value={nPplayers}
            >{`${nPplayers}-handed`}</MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

interface GameParameterProps {
  strategy: RangeSet
}

export const AnyScenarioGameParameters = (props: GameParameterProps) => {
  const [stack, setStack] = useState<number>(30)
  const [nPlayers, setNPlayers] = useState<number>(8)
  const context = useContext(hhContext)

  const setNewHand = useCallback(
    (n, stack) => {
      const players = HandPositionOrder.slice(0, n)
      const stackSection = players.join(` ${stack},`)
      const hhString = `${n}-handed;
      ${stackSection} ${stack}
    ;
    SB 0.5, BB 1;
    SB AcKc;`
      const newHand = handFromString(undefined, hhString)
      context.setHand(newHand)
      context.setCurrentActionIndex({
        street: "preflop",
        index: 0,
      })
    },
    [context]
  )

  const changeNPlayers = (n: number) => {
    if (nPlayers == n) return
    setNPlayers(n)
    setNewHand(n, stack)
  }

  const changeStack = (newStack: number) => {
    if (stack == newStack) return
    setStack(newStack)
    setNewHand(nPlayers, newStack)
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          pt: "8px",
          gap: "16px",
        }}
      >
        <StackSizeLinearSlider
          strategy={props.strategy}
          stack={stack}
          changeStack={changeStack}
        />
        <NumPlayersSelector
          players={nPlayers}
          changeNPlayers={changeNPlayers}
        />
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setNewHand(nPlayers, stack)
          }}
        >
          <RestartAltIcon />
        </Box>
      </Box>
      <GameTreeView />
    </>
  )
}

import FastForwardIcon from "@mui/icons-material/FastForward"
import FastRewindIcon from "@mui/icons-material/FastRewind"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import ReplayIcon from "@mui/icons-material/Replay"
import StopIcon from "@mui/icons-material/Stop"
import { Box } from "@mui/material"
import IconButton, { IconButtonProps } from "@mui/material/IconButton"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { hhContext } from "./HHContext"

function PlayerButton(props: IconButtonProps) {
  return (
    <IconButton
      {...props}
      sx={{
        "&.MuiIconButton-root": {
          p: { xs: 0, sm: 0.5 },
        },
      }}
    >
      {props.children}
    </IconButton>
  )
}

export function GameReplayView() {
  const context = useContext(hhContext)
  const [isPlaying, setPlay] = useState(false)

  const timer = useRef<NodeJS.Timeout | null>(null)

  const forward = useCallback(() => {
    if (!context.moveToNextAction()) setPlay(false)
  }, [context])

  useEffect(() => {
    const startTimer = () => {
      timer.current = setInterval(() => {
        forward()
      }, 2000)
    }

    const stopTimer = () => {
      if (!timer.current) return
      clearInterval(timer.current)
    }

    if (isPlaying) {
      startTimer()
    } else {
      stopTimer()
    }

    return () => {
      if (!timer.current) return
      clearInterval(timer.current)
    }
  }, [isPlaying, forward])

  const back = () => {
    context.moveToPrevAction()
  }

  const play = () => {
    forward()
    setPlay(true)
  }

  const stop = () => {
    setPlay(false)
    if (timer.current) clearInterval(timer.current)
    timer.current = null
  }

  const replay = () => {
    context.setCurrentActionIndex({
      street: "preflop",
      index: -1,
    })
  }
  return (
    <Box>
      <PlayerButton color="primary" onClick={replay}>
        <ReplayIcon />
      </PlayerButton>
      <PlayerButton color="primary" onClick={back}>
        <FastRewindIcon />
      </PlayerButton>
      <PlayerButton color="primary" onClick={play}>
        <PlayArrowIcon />
      </PlayerButton>
      <PlayerButton color="primary" onClick={stop}>
        <StopIcon />
      </PlayerButton>
      <PlayerButton color="primary" onClick={forward}>
        <FastForwardIcon />
      </PlayerButton>
    </Box>
  )
}

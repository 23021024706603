import { Box } from "@mui/material"
import { getCurrentAction } from "features/HHReplay/ActionPointer"
import { hhContext, HHContext } from "features/HHReplay/HHContext"
import { PotView } from "features/HHReplay/PotView"
import { RangePointer } from "features/HHReplay/RangePointer"
import {
  HandPlayerStateInfo,
  HandPosition,
  HandPositionOrder,
} from "features/HHReplay/types"
import { useContext, useMemo } from "react"
import { ActionPointer } from "./ActionPointer"
import { TableSeat } from "./Seat"

type PositionSeat = Record<HandPosition, number>

const UknownCommunityCard = () => {
  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ color: "#888888" }} className="tableCard">
        {"\uD83C\uDCA0"}
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          color: "white",
          fontSize: "40px",
          fontWeight: "600",
          transform: "translate(-50%, -50%)",
        }}
      >
        ?
      </Box>
    </Box>
  )
}
const CommunityCardsView = () => {
  const context = useContext(hhContext)
  const action = getCurrentAction(context)
  const streetFinished =
    action && action.isManual && action.state.streetFinished
  const gameFinished = action && action.isManual && action.state.gameFinished
  if (!streetFinished || gameFinished) return null
  return (
    <Box sx={{ display: "flex" }}>
      <UknownCommunityCard />
      <UknownCommunityCard />
      <UknownCommunityCard />
    </Box>
  )
}

export function seatByCountAndPosition(
  players: number,
  position: HandPosition
): number {
  const places: Record<number, PositionSeat> = {
    2: { SB: 2, BB: 7 } as PositionSeat,
    3: { SB: 2, BB: 5, BTN: 8 } as PositionSeat,
    4: { BB: 1, SB: 8, BTN: 6, CO: 3 } as PositionSeat,
    5: { BB: 1, SB: 8, BTN: 6, CO: 3, HJ: 2 } as PositionSeat,
    6: { BB: 7, SB: 6, BTN: 3, CO: 2, HJ: 1, LJ: 8 } as PositionSeat,
    7: { BB: 7, SB: 6, BTN: 3, CO: 2, HJ: 1, LJ: 9, UTG7: 8 } as PositionSeat,
    8: {
      BB: 3,
      SB: 2,
      BTN: 1,
      CO: 8,
      HJ: 7,
      LJ: 6,
      UTG7: 5,
      UTG8: 4,
    } as PositionSeat,
    9: {
      BB: 3,
      SB: 2,
      BTN: 1,
      CO: 9,
      HJ: 8,
      LJ: 7,
      UTG7: 6,
      UTG8: 5,
      UTG9: 4,
    } as PositionSeat,
    10: {
      BB: 3,
      SB: 2,
      BTN: 1,
      CO: 10,
      HJ: 9,
      LJ: 8,
      UTG7: 7,
      UTG8: 6,
      UTG9: 5,
      UTG10: 4,
    } as PositionSeat,
  }
  return places[`${players}`] ? places[`${players}`][position] : 0
}

export function getPot(context: HHContext): number | undefined {
  return context.getAction() == undefined
    ? context.hand.beginningState?.pot
    : context.getAction().state.pot
}

function getStackSize(
  context: HHContext,
  position: HandPosition
): number | undefined {
  if (!context.hand.beginningState) return undefined
  if (context.getAction() == undefined)
    return context.hand.beginningState.playerStates[position]?.stack

  if (!context.hand.preFlop?.actions) return undefined
  const action = context.getAction()
  if (!action || !action.state || !action.state.playerStates) return undefined
  return action.state.playerStates[position]?.stack
}

function getBetSum(
  context: HHContext,
  position: HandPosition
): number | undefined {
  if (!context.hand.beginningState) return undefined
  if (context.currentActionIndex === undefined)
    return context.hand.beginningState.playerStates[position]?.betSum

  if (!context.hand.preFlop?.actions) return undefined
  const action = context.getAction()
  if (!action || !action.state || !action.state.playerStates) return undefined
  return action.state.playerStates[position]?.betSum
}

function getPlayerState(
  context: HHContext,
  position: HandPosition
): HandPlayerStateInfo | undefined {
  if (!context.hand.beginningState) return undefined
  if (
    context.currentActionIndex.index == -1 &&
    context.currentActionIndex.street == "preflop"
  )
    return context.hand.beginningState.playerStates[position]

  if (!context.hand.preFlop?.actions) return undefined
  const action = context.getAction()
  if (!action || !action.state || !action.state.playerStates) return undefined
  return action.state.playerStates[position]
}

export function TableView() {
  const context = useContext(hhContext)
  const pot = getPot(context)

  const seats = useMemo(() => {
    const rv: React.ReactElement[] = []
    if (!context.hand || !context.hand.playersCount?.playersCount) return rv
    const playersCount = context.hand.playersCount.playersCount
    for (let i = 0; i < playersCount; i++) {
      const position = HandPositionOrder[i]
      if (!context.hand.beginningState) continue
      const stackSize = getStackSize(context, position)
      const betSum = getBetSum(context, position)
      const playerState = getPlayerState(context, position)
      const dealer =
        position == "BTN" || (position == "SB" && playersCount == 2)
      const seatN =
        seatByCountAndPosition(playersCount, position) || playersCount - i
      rv.push(
        <TableSeat
          key={i}
          n={seatN}
          position={position}
          stackSize={stackSize}
          dealer={dealer}
          hero={false}
          cards={[]}
          betSum={betSum}
          playerState={playerState}
          context={context}
        />
      )
    }
    return rv
  }, [context])

  return (
    <Box
      sx={{
        width: "100%",
        flexGrow: 1,
      }}
    >
      <Box
        className="poker-table-space"
        sx={{
          width: "100%",
          flexGrow: 1,
        }}
      >
        <div className="poker-table"></div>
        <ActionPointer />
        {context.shownRangePosition &&
          context.currentActionIndex === undefined && <RangePointer />}
        {seats}
        <div className={`poker-table-pot ${context.currentActionIndex.street}`}>
          <CommunityCardsView />
          <PotView pot={pot} />
        </div>
      </Box>
      <Box
        className="poker-table-space-actions"
        sx={{
          width: "100%",
          flexGrow: 1,
        }}
      >
        {/* <CurrentTurnActions /> */}
      </Box>
    </Box>
  )
}

import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { IconButton } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { showError, showSuccess } from "lib/Components/StatusMessage"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useGetRangeByActionsQuery, useGetRangeQuery } from "services/rangesApi"
import { RangeInfo } from "services/types"
import { Cards, PositionLabels } from "../../types"
import {
  Action,
  ACTION_CHARS,
  ACTION_COLORS,
  ACTION_NAMES,
  CATEGORY_ACTION,
  RangeParameters,
} from "../types"
import { getCellBackground, getCellId, getDefinedActions } from "../utils"
import { CardComboInfoView } from "./CardInfoView"
import { CardInfo } from "./Ranges"

export interface RangeAction {
  action: Action
  percent?: number
  combinations?: number
}

export function getRangeActions(
  range: RangeInfo,
  skipNotSuggested: boolean
): RangeAction[] {
  const definedActions: Action[] = getDefinedActions(range, skipNotSuggested)
  const rv: RangeAction[] = definedActions.map((action): RangeAction => {
    return {
      action: action,
      combinations: (range?.operationCombos || {})[action] || 0,
      percent: 0,
    }
  })

  let totalCombos = 0
  rv.map((op) => {
    if (op.combinations && op.combinations > 0) totalCombos += op.combinations
  })
  rv.map((op) => {
    if (op.combinations && op.combinations > 0)
      op.percent = (op.combinations / totalCombos) * 100
  })
  return rv
}

export function rangeCombo(cards: string[]): string | undefined {
  if (cards.length !== 2) return
  const showCard = cards.join("")
  const pair = [cards[0][0], cards[1][0]]
    .sort((a, b) => Cards.indexOf(a) - Cards.indexOf(b))
    .join("")
  const combo = pair + (showCard[1] == showCard[3] ? "s" : "o")
  return combo
}

export interface RangeViewImplProps {
  rangeInfo: RangeInfo
  parameters: RangeParameters
  combo?: string
  actionFilter?: Action
  skipNotSuggested?: boolean
  hideHeader?: boolean
  hidePath?: boolean
  title?: string
}

export function RangeView(props: RangeViewImplProps) {
  const {
    rangeInfo: range,
    parameters,
    combo: showCard,
    actionFilter,
    skipNotSuggested,
  } = props

  // ugly hack, corner case with BB call === check
  const sbLimpCase =
    parameters.filterActions?.length == 1 &&
    parameters.filterActions[0].position == "SB" &&
    parameters.filterActions[0].action == "CALL"

  const [filterAction, setActionFilter] = useState<Action | undefined>(
    actionFilter
  )
  const [currentCard, setCurrentCard] = useState<CardInfo | undefined>(
    undefined
  )
  const [fillFilterAction, setFillFilterAction] = useState(false)

  useEffect(() => {
    if (!showCard || !range || !range.ranges) {
      setCurrentCard(undefined)
      return
    }
    const decimalRatio = range.ranges[showCard]
    const ratio: Record<string, number> = {}
    for (const op in decimalRatio) {
      ratio[op] = decimalRatio[op]
    }
    setCurrentCard({ cardId: showCard, ratio: ratio })
  }, [showCard, setCurrentCard, range])

  const selectCell = useCallback(
    (card: CardInfo | undefined) => {
      setCurrentCard(card)
    },
    [setCurrentCard]
  )

  useEffect(
    () => setActionFilter(actionFilter),
    [actionFilter, setActionFilter]
  )

  const rows = useMemo(() => {
    const rows = []
    for (let i = 0; i < Cards.length; i++) {
      const cells = []
      for (let j = 0; j < Cards.length; j++) {
        const cellId: string = getCellId(i, j)
        const decimalRatio = range?.ranges[cellId] ?? {}
        const ratio: Record<string, number> = {}
        for (const op in decimalRatio) {
          ratio[op] = decimalRatio[op]
        }
        const cell = (
          <Box
            sx={{
              padding: { xs: "1px", sm: "2px", md: "4px" },
              fontSize: { xs: "12px", sm: "12px", md: "14px" },
            }}
            onClick={() =>
              range && currentCard?.cardId !== cellId
                ? selectCell({ cardId: cellId, ratio: ratio })
                : setCurrentCard(undefined)
            }
            key={cellId}
            className="range-cell"
            style={{
              background: getCellBackground(
                ratio,
                filterAction,
                true,
                fillFilterAction
              ),
            }}
          >
            {cellId}
          </Box>
        )
        cells.push(cell)
      }
      const row = (
        <div key={i} className="range-row">
          {cells}
        </div>
      )
      rows.push(row)
    }
    return rows
  }, [
    filterAction,
    selectCell,
    range,
    fillFilterAction,
    setCurrentCard,
    currentCard,
  ])

  const actions: RangeAction[] = useMemo(() => {
    return getRangeActions(range, skipNotSuggested || false)
  }, [range, skipNotSuggested])

  const handleExport = () => {
    if (range === undefined) return
    if (filterAction === undefined) {
      showError("Please select action to export")
      return
    }

    const toExport: string[] = []
    for (const card in range.ranges) {
      const ratio = range.ranges[card]
      const actionRatio = ratio[filterAction]
      if (actionRatio > 0) {
        toExport.push(actionRatio < 1 ? `${card}:${actionRatio}` : card)
      }
    }
    navigator.clipboard.writeText(toExport.join(","))
    showSuccess("Copied to clipboard")
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: { xs: 0.5, sm: 1 },
        borderRadius: "12px",
        border: "1px solid #D3D3D3",
        backgroundColor: "#EEEEEE",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          {!props.hideHeader && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {!props.title && (
                <Box sx={{ fontWeight: "bold" }}>
                  {`${parameters.stackSize}bb ${
                    PositionLabels[parameters.heroPosition || "SB"]
                  } ${
                    parameters.category !== "ActionSet"
                      ? parameters.category
                      : ""
                  }`}
                </Box>
              )}
              {props.title && (
                <Box sx={{ fontWeight: "bold" }}>{props.title}</Box>
              )}
              {range?.raiseSizing > 0 && (
                <Box sx={{ pl: 1, fontSize: "12px" }}>
                  Raise sizing: {range?.raiseSizing.toFixed(2)}xx
                </Box>
              )}
              <IconButton onClick={handleExport}>
                <ContentCopyIcon />
              </IconButton>
              {filterAction !== undefined && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                    justifyContent: "end",
                  }}
                >
                  {!fillFilterAction && (
                    <VisibilityIcon onClick={() => setFillFilterAction(true)} />
                  )}
                  {fillFilterAction && (
                    <VisibilityOffIcon
                      onClick={() => setFillFilterAction(false)}
                    />
                  )}
                </Box>
              )}
            </Box>
          )}
          {!props.hidePath && parameters.category == CATEGORY_ACTION && (
            <Box
              sx={{
                fontSize: "10px",
                // whiteSpace: "nowrap",
              }}
            >
              {parameters.filterActions
                ?.map(
                  (v) =>
                    `${PositionLabels[v.position]} ${v.action} ${
                      v.raiseAmount ? v.raiseAmount.toFixed(2) : ""
                    }`
                )
                .join(", ")}
            </Box>
          )}
          {!props.hidePath &&
            (!parameters.filterActions ||
              parameters.filterActions?.length == 0) && (
              <Box sx={{ fontSize: "10px", color: "black" }}>RFI</Box>
            )}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              pt: 0.5,
              // minHeight: "70px",
            }}
          >
            {actions.map((op) => (
              <Box key={op.action} sx={{ mr: "10px" }}>
                <Button
                  size="small"
                  sx={{
                    mt: "4px",
                    textTransform: "none",
                    "&.MuiButton-root": {
                      minWidth: { xs: "130px", sm: "170px" },
                      minHeight: "20px",
                      borderRadius: "8px",
                      justifyContent: "flex-start",
                      fontSize: { xs: "10px", sm: "12px" },
                    },
                    "&.MuiButton-root.MuiButton-contained": {
                      backgroundColor: "darkcyan",
                    },
                  }}
                  variant={
                    filterAction !== op.action ? "outlined" : "contained"
                  }
                  onClick={() => {
                    if (!op.combinations) return
                    setActionFilter(
                      op.action !== filterAction ? op.action : undefined
                    )
                  }}
                >
                  <Box
                    sx={{
                      minWidth: "14px",
                      minHeight: "14px",
                      border: "1px solid #333333",
                      borderRadius: "50%",
                      background: ACTION_COLORS[op.action],
                    }}
                  ></Box>
                  <Box sx={{ pl: "4px", flexWrap: "nowrap", display: "flex" }}>
                    {ACTION_NAMES[op.action]}
                    <Box
                      component="span"
                      sx={{ display: "flex", flexWrap: "nowrap" }}
                    >
                      {op.percent !== undefined && (
                        <Box
                          component="span"
                          sx={{ pl: "2px", color: "darkgrey" }}
                        >
                          {op.percent && op.percent < 0.01
                            ? op.percent.toFixed(4)
                            : op.percent.toFixed(2)}
                          %
                        </Box>
                      )}
                      <Box
                        component="span"
                        sx={{ pl: "2px", color: "darkgrey" }}
                      >
                        ({op.combinations?.toFixed(0)})
                      </Box>
                    </Box>
                    {/* )} */}
                  </Box>
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }}></Box>
      {currentCard && (
        <>
          <Box sx={{ pt: 0.5 }}></Box>
          <CardComboInfoView
            cardId={currentCard?.cardId}
            ratio={currentCard?.ratio}
          />
        </>
      )}
      <Box className="rangedata">
        <Box
          sx={{ pt: 0.5, maxWidth: "600px" }}
          className={`${!range ? "missed_range" : ""}`}
        >
          {rows}
        </Box>
        {!range && <Box className={"missed_label"}>Ranges do not exist</Box>}
      </Box>
    </Box>
  )
}

export interface RangeViewProps {
  parameters: RangeParameters
  actionFilter?: Action
  skipNotSuggested?: boolean
  hideHeader?: boolean
  hidePath?: boolean
  title?: string
}

export function RangeController(props: RangeViewProps) {
  const { parameters, skipNotSuggested } = props

  const rangeByCategoryQuery = useGetRangeQuery(
    {
      rangeset_id: parameters.rangeset || "",
      stack_size: Number(parameters.stackSize) || 0,
      position: parameters.heroPosition || "",
      category: parameters.category || "",
    },
    { skip: parameters.category == "ActionSet" }
  )

  const rangeByActionsQuery = useGetRangeByActionsQuery(
    {
      rangeset_id: parameters.rangeset || "",
      stack_size: Number(parameters.stackSize) || 0,
      position: parameters.heroPosition || "",
      path: parameters.filterActions
        ? parameters.filterActions
            .map(
              (x) =>
                x.position +
                " " +
                ACTION_CHARS[x.action] +
                (x.action === "RAISE" ? x.raiseAmount : "")
            )
            .join(", ")
        : "",
    },
    { skip: parameters.category != "ActionSet" }
  )

  // TODO, handle errors?
  if (parameters.category !== "ActionSet") {
    if (!rangeByCategoryQuery.data) {
      return null
    }
    return (
      <RangeView
        rangeInfo={rangeByCategoryQuery.data}
        parameters={parameters}
        skipNotSuggested={skipNotSuggested || false}
        hideHeader={props.hideHeader}
        hidePath={props.hidePath}
        title={props.title}
        actionFilter={props.actionFilter}
      />
    )
  } else {
    if (!rangeByActionsQuery.data) return null
    return (
      <RangeView
        rangeInfo={rangeByActionsQuery.data}
        parameters={parameters}
        skipNotSuggested={skipNotSuggested || false}
        hideHeader={props.hideHeader}
        hidePath={props.hidePath}
        title={props.title}
        actionFilter={props.actionFilter}
      />
    )
  }
}

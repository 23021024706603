import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone"
import {
  Avatar,
  Box,
  Button,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material"
import Badge from "@mui/material/Badge"
import FormGroup from "@mui/material/FormGroup"
import Popover from "@mui/material/Popover"
import { stringAvatar } from "features/profile/UserCard"
import { ChangeEvent, ReactElement, useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"
import {
  FollowerAddedMetadata,
  FoundUser,
  HandSharedMetadata,
  UserNotification,
} from "services/types"
import {
  useAckNotificationMutation,
  useFollowMutation,
  useGetNotificationsQuery,
  useGetUserByHandleQuery,
} from "services/userApi"

export function NotificationBadge() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [checked, setChecked] = useState(true)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const { data, error } = useGetNotificationsQuery(
    {
      unread: "true",
      size: 1,
    },
    {
      pollingInterval: 20000,
    }
  )

  return (
    <>
      <Badge
        sx={{ p: "4px" }}
        badgeContent={data?.totalItems}
        color="primary"
        onClick={handleMenu}
      >
        <NotificationsNoneIcon />
      </Badge>
      {Boolean(anchorEl) && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" sx={{ fontWeight: 600, p: 2 }}>
                Notifications
              </Typography>
              <Box sx={{ flexGrow: 1 }} />

              <FormGroup>
                <FormControlLabel
                  label={
                    <Box>
                      <Typography variant="body2">Unread</Typography>
                    </Box>
                  }
                  control={
                    <Switch
                      size="small"
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                />
              </FormGroup>
            </Box>
            <NotificationList unread={checked} />
          </Box>
        </Popover>
      )}
    </>
  )
}

interface LazyLoadProps {
  inViewCallback(page: number, notifications: UserNotification[]): void
  unread: boolean
  page: number
}

const NotificationList = ({ unread }: { unread: boolean }) => {
  const [ackNotification] = useAckNotificationMutation()
  const [page, setPage] = useState(0)
  const [notificationList, setNotification] = useState([] as UserNotification[])
  const container = useRef(null)

  useEffect(() => {
    setNotification([])
    setPage(0)
  }, [unread])

  const pageLoaded = (page: number, notifications: UserNotification[]) => {
    setPage(page + 1)
    setNotification((list) => list.concat(notifications))
  }

  const maskAsRead = (id: number) => {
    ackNotification({ handle: id })
      .unwrap()
      .then(() => {
        // mark as-read in loaded list...
        if (unread) {
          setNotification(notificationList.filter((v) => v.id != id))
        } else {
          const newList = notificationList.map((n) => {
            if (n.id == id) {
              const rv = { ...n }
              rv.acknowledged_at = new Date().toISOString()
              return rv
            }
            return n
          })
          setNotification(newList)
        }
      })
  }

  const LazyLoad = (props: LazyLoadProps): ReactElement => {
    const [ref, inView] = useInView({
      triggerOnce: true,
      root: container.current,
      // rootMargin: "-100px 0"
    })

    const bulkSize = 20

    const query = useGetNotificationsQuery(
      {
        unread: props.unread ? "true" : "false",
        size: bulkSize,
        page: props.page,
      },
      { skip: !inView }
    )

    useEffect(() => {
      if (query.currentData && query.currentData.notifications.length > 0) {
        props.inViewCallback(props.page, query.currentData.notifications)
      }
    }, [query, props])

    useEffect(() => {
      if (inView) {
        // console.log(`Trigger in-view callback page: ${props.page}`)
      }
    }, [inView, props])

    return (
      <Box className="loadOnView" ref={ref} sx={{ minHeight: "10px" }}>
        {/* marker that end of list is shown, need load more data */}
      </Box>
    )
  }

  return (
    <Box ref={container} sx={{ maxHeight: "600px", overflowY: "scroll" }}>
      {notificationList.map((notification) => {
        if (notification.kind == "FOLLOWER_ADDED") {
          return (
            <UserFoollowedNotification
              key={notification.id}
              id={notification.id}
              notification={notification}
              ackCallback={maskAsRead}
            />
          )
        } else if (notification.kind == "HAND_SHARED") {
          return (
            <UserSharedHandNotification
              key={notification.id}
              id={notification.id}
              notification={notification}
              ackCallback={maskAsRead}
            />
          )
        }
        return null
      })}
      <LazyLoad inViewCallback={pageLoaded} unread={unread} page={page} />
    </Box>
  )
}

interface UserFoollowedNotificationProps {
  id: number
  notification: UserNotification
  ackCallback(id: number): void
}

function UserFoollowedNotification(props: UserFoollowedNotificationProps) {
  const { id, notification, ackCallback } = props
  const metadata = notification.metadata as FollowerAddedMetadata
  const navigate = useNavigate()
  const [followUser] = useFollowMutation()

  const userInfoQuery = useGetUserByHandleQuery({ handle: metadata.username })
  const user = userInfoQuery.data

  const follow = (user: FoundUser) => {
    followUser({ handle: user.publicHandle })
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "start",
        gap: "8px",
        maxWidth: "300px",
        border: "1px solid #dddddd",
        p: "8px",
        ":hover": {
          bgcolor: "#cccccc",
        },
        cursor: "pointer",
      }}
      onClick={() => {
        ackCallback(id)
        navigate(`/@${metadata.username}`)
      }}
    >
      <Box>
        {metadata.avatar && <Avatar src={metadata.avatar} />}
        {!metadata.avatar && (
          <Avatar {...stringAvatar(metadata.name || "N A")} />
        )}
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: 600 }}>
          {metadata.name}
        </Typography>
        {metadata.username && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              @{metadata.username}
            </Typography>
            <Typography variant="body2">now following you</Typography>
          </Box>
        )}
        <Box sx={{ display: "flex" }}>
          {user && user.followed && !user.following && (
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                Followed
              </Typography>
            </Box>
          )}
          {user && user.followed && user.following && (
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                Friend
              </Typography>
            </Box>
          )}
          {/* <Box sx={{ flexGrow: 1 }}></Box> */}

          {user && !user.followed && (
            <Button
              variant="text"
              color="primary"
              sx={{
                textDecoration: "none",
                textTransform: "none",
                "&.MuiButton-root": {
                  p: 0,
                  pl: "4px",
                  minWidth: 0,
                },
              }}
              onClick={() => follow(user)}
            >
              Follow
            </Button>
          )}
        </Box>
      </Box>
      {!notification.acknowledged_at && (
        <Box
          sx={{
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            backgroundColor: "lightgray",
          }}
        ></Box>
      )}
    </Box>
  )
}

function UserSharedHandNotification(props: UserFoollowedNotificationProps) {
  const { id, notification, ackCallback } = props
  const metadata = notification.metadata as HandSharedMetadata
  const navigate = useNavigate()
  const [followUser] = useFollowMutation()

  const userInfoQuery = useGetUserByHandleQuery({ handle: metadata.username })
  const user = userInfoQuery.data

  const follow = (user: FoundUser) => {
    followUser({ handle: user.publicHandle })
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "start",
        gap: "8px",
        maxWidth: "300px",
        border: "1px solid #dddddd",
        p: "8px",
        ":hover": {
          bgcolor: "#cccccc",
        },
        cursor: "pointer",
      }}
      onClick={() => {
        ackCallback(id)
        navigate(`/hhreplay/${metadata.hand}`)
      }}
    >
      <Box>
        {metadata.avatar && <Avatar src={metadata.avatar} />}
        {!metadata.avatar && (
          <Avatar {...stringAvatar(metadata.name || "N A")} />
        )}
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: 600 }}>
          {metadata.name}
        </Typography>
        {metadata.username && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              @{metadata.username}
            </Typography>
            <Typography variant="body2">shared hand with you</Typography>
          </Box>
        )}
        <Box sx={{ display: "flex" }}>
          {user && user.followed && !user.following && (
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                Followed
              </Typography>
            </Box>
          )}
          {user && user.followed && user.following && (
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                Friend
              </Typography>
            </Box>
          )}

          {user && !user.followed && (
            <Button
              variant="text"
              color="primary"
              sx={{
                textDecoration: "none",
                textTransform: "none",
                "&.MuiButton-root": {
                  p: 0,
                  pl: "4px",
                  minWidth: 0,
                },
              }}
              onClick={() => follow(user)}
            >
              Follow
            </Button>
          )}
        </Box>
      </Box>
      {!notification.acknowledged_at && (
        <Box
          sx={{
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            backgroundColor: "lightgray",
          }}
        ></Box>
      )}
    </Box>
  )
}

import { AspectRatio, Money, Search } from "@mui/icons-material"
import Replay30Icon from "@mui/icons-material/Replay30"
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import Box from "@mui/material/Box"
import { Location, useLocation } from "react-router"
import { NavLink } from "react-router-dom"

export const menuItems = [
  {
    label: "Hands",
    icon: <Search />,
    link: "/hand-histories",
    isActive: (location: Location) => location.pathname === "/hand-histories",
  },
  {
    label: "Ranges",
    icon: <AspectRatio />,
    link: "/ranges",
    isActive: (location: Location) => location.pathname === "/ranges",
  },
  {
    label: "Range Explorer",
    icon: <AspectRatio />,
    link: "/pfrexplorer/any",
    isActive: (location: Location) =>
      location.pathname.startsWith("/pfrexplorer"),
  },
  {
    label: "Bet Sizing",
    icon: <Money />,
    link: "/betsizing",
    isActive: (location: Location) => location.pathname === "/betsizing",
  },
  {
    label: "Hand Analyzer",
    icon: <Replay30Icon />,
    link: "/hhreplay",
    isActive: (location: Location) => location.pathname.startsWith("/hhreplay"),
  },
]

export function LeftNavigation() {
  const location = useLocation()

  return (
    <Box
      sx={{
        overflow: "auto",
        minWidth: 118,
        maxWidth: 118,
        background: "#EEEEEE",
        display: { xs: "none", sm: "none", md: "block" },
      }}
    >
      <List>
        {menuItems.map(({ label, icon, link, isActive }) => (
          <ListItem key={label} disablePadding>
            <ListItemButton
              component={link ? NavLink : "span"}
              selected={isActive && isActive(location)}
              to={link}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  "& .MuiListItemText-root": {
                    textAlign: "center",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    "&.MuiListItemIcon-root": {
                      color: "inherit",
                    },
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={label} />
              </Box>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

import { api } from "./api"
import {
  AddBetSizingRangeRequest,
  BetSizingConfig,
  BetSizingRange,
  BetSizingRangeGroup,
  UpdateBetSizingRangeRequest,
} from "./types"

const betSizesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBetSizesRangeGroups: builder.query<BetSizingRangeGroup[], string>({
      query: (category: string) => ({
        url: `api/bet_sizing/rangegroups/${category}`,
      }),
      providesTags: ["BetSizingRangeGroups"],
    }),
    addBetSizesRange: builder.mutation<
      BetSizingRange,
      AddBetSizingRangeRequest
    >({
      query: ({ id, ...patch }) => ({
        url: `api/bet_sizing/rangegroups/${id}/ranges`,
        method: "POST",
        body: patch,
      }),
      invalidatesTags: ["BetSizingRangeGroups"],
    }),

    updateBetSizesRange: builder.mutation<
      BetSizingRange,
      UpdateBetSizingRangeRequest
    >({
      query: ({ id, ...patch }) => ({
        url: `api/bet_sizing/ranges/${id}`,
        method: "PUT",
        body: patch,
      }),
      invalidatesTags: ["BetSizingRangeGroups"],
    }),
    copyBetSizesRangeGroup: builder.mutation<
      BetSizingRangeGroup,
      { id: string; name: string }
    >({
      query: ({ id, name }) => ({
        url: `api/bet_sizing/rangegroups/${id}/copy`,
        method: "POST",
        body: name,
      }),
      invalidatesTags: ["BetSizingRangeGroups"],
    }),

    deleteRange: builder.mutation<BetSizingRange, string>({
      query: (id) => ({
        url: `api/bet_sizing/ranges/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BetSizingRangeGroups"],
    }),

    deleteRangeGroup: builder.mutation<BetSizingRange, string>({
      query: (id) => ({
        url: `api/bet_sizing/rangegroups/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BetSizingRangeGroups"],
    }),
    getBetSizesConfig: builder.query<BetSizingConfig, void>({
      query: () => ({
        url: `api/bet_sizing/config`,
      }),
      providesTags: ["BetSizingUserConfig"],
    }),

    updateBetSizesConfig: builder.mutation<void, BetSizingConfig>({
      query: (body) => ({
        url: `api/bet_sizing/config`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["BetSizingUserConfig"],
    }),
  }),
})

export const {
  useGetBetSizesRangeGroupsQuery,
  useAddBetSizesRangeMutation,
  useUpdateBetSizesRangeMutation,
  useCopyBetSizesRangeGroupMutation,
  useDeleteRangeMutation,
  useDeleteRangeGroupMutation,
  useGetBetSizesConfigQuery,
  useUpdateBetSizesConfigMutation,
} = betSizesApi

import GroupIcon from "@mui/icons-material/Group"
import { Avatar, Box, Button, Tab, Tabs } from "@mui/material"
import Typography from "@mui/material/Typography"
import type { RootState } from "app/store"
import { stringAvatar } from "features/profile/UserCard"
import { FollowerList, FollowingList, Users } from "features/profile/Users"
import { NotFound } from "lib/Components/PageNotFound"
import { PageTitle } from "lib/Components/PageTitile"
import { useSelector } from "react-redux"
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import { FoundUser } from "services/types"
import {
  useFollowMutation,
  useGetUserByHandleQuery,
  useUnfollowMutation,
} from "services/userApi"

export const UserRelations = ({
  handle,
  page,
  currentUser,
}: {
  handle: string
  page: string
  currentUser: boolean
}) => {
  const path = useLocation()

  const USER_TABS = [
    { label: "Following", route: `` },
    { label: "Followers", route: `/followers` },
  ]

  if (currentUser) {
    USER_TABS.push({ label: "Search Users", route: `/users` })
  }
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={path.pathname}
            aria-label="basic tabs example"
            sx={{
              "& .MuiTab-root": {
                textTransform: "none",
              },
            }}
          >
            {USER_TABS.map((v) => {
              return (
                <Tab
                  key={v.route}
                  label={v.label}
                  value={`${page}${v.route}`}
                  component={Link}
                  to={`.${v.route}`}
                />
              )
            })}
          </Tabs>
        </Box>
      </Box>

      <Routes>
        <Route path="" element={<FollowingList publicHandle={handle} />} />
        <Route
          path="/followers"
          element={<FollowerList publicHandle={handle} />}
        />
        <Route path="/users" element={<Users />} />
      </Routes>
    </>
  )
}

export const UserInfoController = () => {
  const { handle } = useParams()
  const authUser = useSelector((state: RootState) => state.auth.user)
  const navigate = useNavigate()

  const [followUser] = useFollowMutation()
  const [unfollowUser] = useUnfollowMutation()

  // TODO, error handling!
  const follow = (user: FoundUser) => {
    followUser({ handle: user.publicHandle })
  }

  const unfollow = (user: FoundUser) => {
    unfollowUser({ handle: user.publicHandle })
  }

  const userInfoQuery = useGetUserByHandleQuery(
    { handle: handle || "" },
    { skip: handle === undefined }
  )

  if (!handle) return null

  if (!userInfoQuery) return null

  if (!userInfoQuery.data) return <NotFound />

  const user = userInfoQuery.data

  return (
    <Box
      sx={{
        p: "16px",
        // flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <Box sx={{ display: "flex", gap: "100px" }}>
        <PageTitle text={`User Details`} />
        {authUser?.id == user.id && (
          <Button
            size="small"
            variant="contained"
            onClick={() => navigate("/profile")}
            sx={{
              textDecoration: "none",
              textTransform: "none",
              "&.MuiButton-root": {
                maxHeight: "30px",
              },
            }}
          >
            Configure Profile
          </Button>
        )}
      </Box>

      {user.imageURL && (
        <Avatar sx={{ width: 80, height: 80 }} src={user.imageURL} />
      )}
      {!user.imageURL && <Avatar {...stringAvatar(user.name || "N A")} />}

      <Typography variant="h6">{user.name}</Typography>
      <Box sx={{ fontWeight: 600 }}>@{user.publicHandle}</Box>
      {user.email && <Box>{user.email}</Box>}
      {user.member_since && (
        <Box>Joined {new Date(user.member_since).toLocaleDateString()}</Box>
      )}

      {authUser?.id != user.id && (
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <GroupIcon />
          {user.followed && !user.following && <Box>Following</Box>}
          {user.following && !user.followed && <Box>Follows you</Box>}
          {user.followed && user.following && <Box>Friend</Box>}
          {!user.followed && (
            <Box>
              <Button
                variant="text"
                color="primary"
                sx={{
                  textDecoration: "none",
                  textTransform: "none",
                  "&.MuiButton-root": {
                    p: 0,
                    pl: "4px",
                    minWidth: 0,
                  },
                }}
                onClick={() => follow(user)}
              >
                Follow
              </Button>
            </Box>
          )}
          {user.followed && (
            <Box>
              <Button
                variant="text"
                color="warning"
                sx={{
                  textDecoration: "none",
                  textTransform: "none",
                  "&.MuiButton-root": {
                    p: 0,
                    pl: "4px",
                    minWidth: 0,
                  },
                }}
                onClick={() => unfollow(user)}
              >
                UnFollow
              </Button>
            </Box>
          )}
        </Box>
      )}

      <UserRelations
        handle={handle}
        page={`/@${handle}`}
        currentUser={authUser?.id == user.id}
      />
    </Box>
  )
}

import {
  Avatar,
  Box,
  Button,
  Chip,
  FormControl,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material"
import { stringAvatar } from "features/profile/UserCard"
import { ChangeEvent, useEffect, useState } from "react"
import { toast, ToastContainer } from "react-toastify"
import {
  useGetHandSharingConfigQuery,
  useRemoveHandSharingMutation,
  useShareHandMutation,
} from "services/handHistoryApi"
import {
  HandShareRequest,
  HandSharingPolicy,
  SharedHandConfig,
  SharedTo,
} from "services/types"
import { AddUserControl } from "./Users"

interface HandSharingConfigProps {
  handle: string
  config: SharedHandConfig
}

export const HandSharingConfig = (props: HandSharingConfigProps) => {
  const { handle, config } = props

  const [policy, setPolicy] = useState<HandSharingPolicy>("PRIVATE")
  const [users, setUsers] = useState<SharedTo[]>([])
  const [submitting, setSubmitting] = useState(false)
  const [updateSettings] = useShareHandMutation()
  const [dropSharing] = useRemoveHandSharingMutation()
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setPolicy(config.policy)
    setUsers(config.users || [])
  }, [config])

  const dropUserAccess = (userId: number) => {
    setUsers(users.filter((v) => v.user_id != userId))
  }

  const addUserAccess = (user: SharedTo) => {
    if (users.filter((v) => v.user_id == user.user_id).length == 0) {
      setUsers([...users, ...[user]])
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const handleSave = () => {
    setSubmitting(true)
    const id = toast.loading("Please wait...", {
      position: toast.POSITION.BOTTOM_CENTER,
    })

    if (policy == "PRIVATE") {
      dropSharing(handle)
        .unwrap()
        .then(() => {
          toast.info(`Updated`, {
            position: toast.POSITION.BOTTOM_CENTER,
          })
        })
        .catch(() => {
          toast.error(`Please, try again later. Server unavailable`, {
            position: toast.POSITION.BOTTOM_CENTER,
          })
        })
        .finally(() => {
          toast.dismiss(id)
          setSubmitting(false)
        })
      return
    }

    updateSettings({
      handle: handle,
      config: {
        allow_share: checked, // TODO
        policy,
        users: users.map((v) => v.user_id),
      } as HandShareRequest,
    })
      .unwrap()
      .then(() => {
        toast.info(`Updated`, {
          position: toast.POSITION.BOTTOM_CENTER,
        })
      })
      .catch(() => {
        toast.error(`Please, try again later. Server unavailable`, {
          position: toast.POSITION.BOTTOM_CENTER,
        })
      })
      .finally(() => {
        toast.dismiss(id)
        setSubmitting(false)
      })
  }

  if (!config.can_share) {
    return (
      <Typography variant="body2">
        You are not allowed to share this hand
      </Typography>
    )
  }

  return (
    <Box
      sx={{ p: "8px", display: "flex", flexDirection: "column", gap: "8px" }}
    >
      <Typography variant="body2" sx={{ fontWeight: 600 }}>
        Sharing Configuration
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">Access</Typography>
        <FormControl sx={{ minWidth: "150px", pl: 2 }} size="small">
          <Select
            disabled={!config.can_share}
            value={policy}
            size="small"
            onChange={(v) => setPolicy(v.target.value as HandSharingPolicy)}
          >
            <MenuItem value={"PRIVATE"}>
              <Typography variant="body2">Private</Typography>
            </MenuItem>
            <MenuItem value={"PUBLIC"}>
              <Typography variant="body2">Public</Typography>
            </MenuItem>
            <MenuItem value={"INDIVIDUALS"}>
              <Typography variant="body2">Set of users</Typography>
            </MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* <FormControl component="fieldset"> */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2">Allow re-share</Typography>
        <Switch
          size="small"
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Box>
      {/* </FormControl> */}

      {policy == "INDIVIDUALS" && (
        <>
          <Typography variant="body2">Users</Typography>
          {users.length == 0 && (
            <Typography variant="body2">No users defined</Typography>
          )}
          <Box>
            {users.map((user) => {
              return (
                <Chip
                  size="small"
                  key={user.user_id}
                  avatar={
                    user.avatar ? (
                      <Avatar src={user.avatar} />
                    ) : (
                      <Avatar
                        {...stringAvatar(
                          `${user.username} ${user.username}` || "N A"
                        )}
                      />
                    )
                  }
                  label={`@${user.username}`}
                  onDelete={() => dropUserAccess(user.user_id)}
                  onClick={() => alert("I'm visited")}
                  sx={{
                    "& .MuiChip-avatar": {
                      color: "white",
                      textTransform: "uppercase",
                    },
                  }}
                />
              )
            })}
          </Box>
          <AddUserControl
            onChoose={(user: SharedTo) => {
              addUserAccess(user)
            }}
          />
        </>
      )}
      <Button
        disabled={submitting}
        variant="contained"
        size={"small"}
        onClick={handleSave}
        sx={{
          "&.MuiButton-root": {
            textTransform: "none",
            maxWidth: "80px",
          },
        }}
      >
        Update
      </Button>
      <ToastContainer autoClose={2000} />
    </Box>
  )
}

export const HandSharingView = ({ handle }: { handle: string }) => {
  const config = useGetHandSharingConfigQuery(handle)
  if (!config.data) return null
  return <HandSharingConfig handle={handle} config={config.data} />
}

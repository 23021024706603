import { createContext } from "react"
import { Hand, HandAction, HandPosition, HandStreet } from "./types"

export interface DetailedRangeInfo {
  position?: HandPosition
  detailedRangeAction: HandActionIndex
}

export type HandActionIndex = {
  index: number
  street: HandStreet
}

export function compareHandActionIndex(
  one: HandActionIndex,
  two: HandActionIndex
): boolean {
  return one && two && one.index == two.index && one.street == two.street
}

export interface HHContext {
  hand: Hand
  currentActionIndex: HandActionIndex
  shownRangePosition: HandPosition | undefined

  setHand(hand: Hand): void

  setCurrentActionIndex(actionIndex: HandActionIndex): void

  moveToNextAction(skipImplicit?: boolean): HandActionIndex | undefined
  moveToPrevAction(skipImplicit?: boolean): HandActionIndex | undefined

  setShownRangePosition(rfiRangePosition: HandPosition | undefined): void

  getAction(): HandAction
}
const hhContext = createContext({
  hand: {} as Hand,
  currentActionIndex: { index: -1, street: "preflop" },
  shownRangePosition: undefined,
  setHand: {},
  setCurrentActionIndex: {},
  moveToNextAction: (skipImplicit?: boolean) => {
    return
  },
  moveToPrevAction: (skipImplicit?: boolean) => {
    return
  },
  setShownRangePosition: {},
  getAction: {},
} as HHContext)

export { hhContext as hhContext }

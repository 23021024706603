import { HandActionIndex } from "./HHContext"
import { Hand, HandAction } from "./types"

export function endGameAction(hand: Hand, action: HandActionIndex): boolean {
  if (hand.lastState?.gameFinished && action.index >= 0) {
    if (hand.river?.actions.length) {
      if (
        action.street == "river" &&
        action.index == hand.river?.actions.length - 1
      ) {
        return true
      } else {
        // we have river but does not point to its last action
        return false
      }
    }

    if (hand.turn?.actions.length) {
      if (
        action.street == "turn" &&
        action.index == hand.turn?.actions.length - 1
      ) {
        return true
      } else {
        // we have turn but does not point to its last action
        return false
      }
    }

    if (hand.flop?.actions.length) {
      if (
        action.street == "flop" &&
        action.index == hand.flop?.actions.length - 1
      ) {
        return true
      } else {
        // we have flop but does not point to its last action
        return false
      }
    }

    if (hand.preFlop?.actions.length) {
      if (
        action.street == "preflop" &&
        action.index == hand.preFlop?.actions.length - 1
      ) {
        return true
      } else {
        // we have preflop but does not point to its last action
        return false
      }
    }
  }
  return false
}

export const getNextAction = (
  current: HandActionIndex,
  hand: Hand,
  skipImplicit?: boolean
): HandActionIndex | undefined => {
  const preflopActions = hand.preFlop?.actions
  const flopActions = hand.flop?.actions
  const turnActions = hand.turn?.actions
  const riverActions = hand.river?.actions
  let nextIndex: HandActionIndex = { index: 0, street: "preflop" }
  let action: HandAction | undefined = undefined
  switch (current.street) {
    case "preflop":
      if (!preflopActions || preflopActions.length == 0) return
      if (current.index >= preflopActions.length - 1) {
        if (flopActions && flopActions.length) {
          nextIndex = { index: 0, street: "flop" }
          action = flopActions[nextIndex.index]
        } else {
          return
        }
      } else {
        nextIndex = { index: current.index + 1, street: "preflop" }
        action = preflopActions[nextIndex.index]
      }
      break
    case "flop":
      if (!flopActions || flopActions.length == 0) return
      if (current.index >= flopActions.length - 1) {
        if (turnActions && turnActions.length) {
          nextIndex = { index: 0, street: "turn" }
          action = turnActions[nextIndex.index]
        } else {
          return
        }
      } else {
        nextIndex = { index: current.index + 1, street: "flop" }
        action = flopActions[nextIndex.index]
      }
      break
    case "turn":
      if (!turnActions || turnActions.length == 0) return
      if (current.index >= turnActions.length - 1) {
        if (riverActions && riverActions.length) {
          nextIndex = { index: 0, street: "river" }
          action = riverActions[nextIndex.index]
        } else {
          return
        }
      } else {
        nextIndex = { index: current.index + 1, street: "turn" }
        action = turnActions[nextIndex.index]
      }
      break
    case "river":
      if (!riverActions || riverActions.length == 0) return
      if (current.index >= riverActions.length - 1) {
        return
      } else {
        nextIndex = { index: current.index + 1, street: "river" }
        action = riverActions[nextIndex.index]
      }
      break
  }
  if (action && !action.isManual && skipImplicit) {
    return getNextAction(nextIndex, hand, skipImplicit)
  }
  return nextIndex
}

export const getPrevAction = (
  current: HandActionIndex,
  hand: Hand,
  skipImplicit?: boolean
): HandActionIndex | undefined => {
  const preflopActions = hand.preFlop?.actions
  const flopActions = hand.flop?.actions
  const turnActions = hand.turn?.actions
  const riverActions = hand.river?.actions
  let prevIndex: HandActionIndex = { index: 0, street: "preflop" }
  let action: HandAction | undefined = undefined
  switch (current.street) {
    case "preflop":
      if (!preflopActions) return
      if (current.index <= 0) {
        prevIndex = { index: 0, street: "preflop" }
        return prevIndex
      } else {
        prevIndex = { index: current.index - 1, street: "preflop" }
        action = preflopActions[prevIndex.index]
      }
      break
    case "flop":
      if (!flopActions) return
      if (current.index <= 0) {
        if (preflopActions) {
          prevIndex = { index: preflopActions.length - 1, street: "preflop" }
          action = preflopActions[prevIndex.index]
        } else {
          return
        }
      } else {
        prevIndex = { index: current.index - 1, street: "flop" }
        action = flopActions[prevIndex.index]
      }
      break
    case "turn":
      if (!turnActions) return
      if (current.index <= 0) {
        if (flopActions) {
          prevIndex = { index: flopActions.length - 1, street: "flop" }
          action = flopActions[prevIndex.index]
        } else {
          return
        }
      } else {
        prevIndex = { index: current.index - 1, street: "turn" }
        action = turnActions[prevIndex.index]
      }
      break
    case "river":
      if (!riverActions) return
      if (current.index <= 0) {
        if (turnActions) {
          prevIndex = { index: turnActions.length - 1, street: "turn" }
          action = turnActions[prevIndex.index]
        } else {
          return
        }
      } else {
        prevIndex = { index: current.index - 1, street: "river" }
        action = riverActions[prevIndex.index]
      }
      break
  }
  if (action && !action.isManual && skipImplicit) {
    return getPrevAction(prevIndex, hand, skipImplicit)
  }
  return prevIndex
}

import DeleteIcon from "@mui/icons-material/Delete"
import PencilIcon from "@mui/icons-material/Edit"
import PlayIcon from "@mui/icons-material/PlayArrow"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import { Box, Button, IconButton } from "@mui/material"
import { styled } from "@mui/system"
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { PageTitle } from "lib/Components/PageTitile"
import { ChangeEvent, useCallback, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { Link, useSearchParams } from "react-router-dom"
import { api } from "services/api"
import {
  useRemoveHandMutation,
  useRemoveHandsMutation,
  useSearchHandsQuery,
} from "services/handHistoryApi"
import { TEST_DATA } from "./TestData"

const Info = styled("div")({
  background: "green",
  color: "white",
  padding: "4px 8px",
  borderRadius: 4,
  marginLeft: "auto",
})

const Error = styled(Info)({
  background: "red",
})

export function HandHistories() {
  const [params] = useSearchParams()
  const [dropHand] = useRemoveHandMutation()
  const [dropHands] = useRemoveHandsMutation()
  const [selectedRows, setSelectionModel] = useState<GridRowId[]>([])

  const { isLoading, isFetching, data, isError, error } = useSearchHandsQuery({
    sort: "sourceId,DESC",
    size: 100000, // TODO, we need paging, search on UI X-Grid
    page: 0,
  })

  const { hands } = data || { hands: undefined }

  const dispatch = useDispatch()
  const handleImport = useCallback(
    (event: ChangeEvent) => {
      const file = (event.target as HTMLInputElement).files?.[0]
      if (file == null) {
        return
      }
      const url = "/api/hand-history/upload/acr"
      const formData = new FormData()
      formData.append("file", file)
      formData.append("fileName", file.name)
      fetch(url, { body: formData, method: "POST" }).then(() => {
        dispatch(api.util.invalidateTags(["HandHistory"]))
      })
    },
    [dispatch]
  )

  const columns: GridColDef[] = useMemo(() => {
    const handleDelete = (id: string | number) => {
      dropHand(id)
        .unwrap()
        .then(() => {
          // mark as-read in loaded list...
          // if (unread) {
          //   setNotification(notificationList.filter((v) => v.id != id))
          // } else {
          //   const newList = notificationList.map((n) => {
          //     if (n.id == id) {
          //       const rv = { ...n }
          //       rv.acknowledged_at = new Date().toISOString()
          //       return rv
          //     }
          //     return n
          //   })
          //   setNotification(newList)
          // }
        })
    }

    const handleBulkDelete = (ids: (string | number)[]) => {
      dropHands(ids)
        .unwrap()
        .then(() => {
          // mark as-read in loaded list...
          // if (unread) {
          //   setNotification(notificationList.filter((v) => v.id != id))
          // } else {
          //   const newList = notificationList.map((n) => {
          //     if (n.id == id) {
          //       const rv = { ...n }
          //       rv.acknowledged_at = new Date().toISOString()
          //       return rv
          //     }
          //     return n
          //   })
          //   setNotification(newList)
          // }
        })
    }

    return [
      {
        field: "id",
        headerName: "ID",
        width: 150,
        editable: false,
        hide: false,
        renderCell: (params) => (
          <Link key={`${params.id}`} to={`/hhreplay/${params.id}`}>
            {params.id}
          </Link>
        ),
      },
      {
        field: "source",
        headerName: "Source",
        width: 120,
        editable: true,
        hide: true,
      },
      {
        field: "playedOn",
        headerName: "Played on",
        width: 140,
        editable: true,
        type: "date",
        hide: true,
        valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
      },
      {
        field: "importedOn",
        headerName: "Imported on",
        width: 150,
        editable: true,
        type: "date",
        hide: true,
        valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
      },
      {
        field: "tournamentId",
        headerName: "Tournament ID",
        width: 150,
        editable: true,
        hide: true,
      },
      {
        field: "sourceHandId",
        headerName: "Source Hand ID",
        width: 150,
        editable: true,
        hide: true,
      },
      {
        field: "playerCards",
        headerName: "Cards",
        width: 60,
        editable: true,
        hide: false,
      },
      {
        field: "playerCardsImg",
        headerName: "Cards View",
        width: 100,
        valueGetter: (params) =>
          params.row.playerCards
            .replace(/(c|s|h|d)/g, "$1 ")
            .trim()
            .split(" "),
        renderCell: (params) => params.value.map((c: string) => cardToEmoji(c)),
        sortable: false,
      },
      {
        field: "boardCards",
        headerName: "Board Cards",
        width: 120,
        editable: true,
        hide: false,
      },
      {
        field: "boardCardsImg",
        headerName: "Board Cards View",
        width: 180,
        valueGetter: (params) =>
          params.row.boardCards
            .replace(/(c|s|h|d)/g, "$1 ")
            .trim()
            .split(" "),
        renderCell: (params) => params.value.map((c: string) => cardToEmoji(c)),
        sortable: false,
      },
      {
        field: "pot",
        headerName: "Pot",
        width: 100,
        type: "number",
      },
      {
        field: "effectiveStack",
        headerName: "Effective Stack",
        width: 150,
        type: "number",
      },
      {
        field: "position",
        headerName: "Position",
        width: 100,
      },
      {
        field: "metadata",
        headerName: "Simulation",
        width: 80,
        renderCell: (params) => (
          <>
            {params.value &&
              params.value.simulationAvailabilityStatus === "AVAILABLE" && (
                <Link key={`${params.id}`} to={`/hhreplay/${params.id}`}>
                  {params.value.simulationId &&
                    params.value.simulationRunStatus === "FINISHED" && (
                      <IconButton
                        size="small"
                        title={`${params.row.metadata.simulationRunStatus}`}
                      >
                        <RemoveRedEyeIcon
                          fontSize="small"
                          style={{ color: "green" }}
                        />
                      </IconButton>
                    )}
                  {!params.value.simulationId &&
                    !params.value.simulationRunStatus && (
                      <IconButton
                        size="small"
                        title="Simulation available but not run"
                      >
                        <RemoveRedEyeIcon
                          fontSize="small"
                          style={{ color: "yellow" }}
                        />
                      </IconButton>
                    )}
                  {params.value.simulationRunStatus &&
                    params.value.simulationRunStatus !== "FINISHED" && (
                      <IconButton
                        size="small"
                        title={`Simulation status is ${params.row.metadata.simulationRunStatus}`}
                      >
                        <RemoveRedEyeIcon
                          fontSize="small"
                          style={{ color: "yellow" }}
                        />
                      </IconButton>
                    )}
                </Link>
              )}
            {params.value &&
              params.value.simulationAvailabilityStatus === "NOT_AVAILABLE" && (
                <IconButton
                  size="small"
                  title={`${params.row.metadata.simulationAvailabilityStatusDescription}`}
                >
                  <RemoveRedEyeIcon
                    fontSize="small"
                    style={{ color: "gray" }}
                  />
                </IconButton>
              )}
            {params.value &&
              params.value.simulationAvailabilityStatus === "UNKNOWN" && (
                <IconButton
                  size="small"
                  title={`${params.row.metadata.simulationAvailabilityStatusDescription}`}
                >
                  <RemoveRedEyeIcon fontSize="small" style={{ color: "red" }} />
                </IconButton>
              )}
          </>
        ),
        sortComparator: (v1, v2) =>
          v1?.simulationAvailabilityStatus.localeCompare(
            v2?.simulationAvailabilityStatus
          ),
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 120,
        sortable: false,
        renderCell: (params) => (
          <>
            <IconButton size="small" aria-label="delete" title="Run Simulation">
              <PlayIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" aria-label="delete">
              <PencilIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              aria-label="delete"
              onClick={() => {
                handleDelete(params.id)
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </>
        ),
      },
      {
        field: "results",
        headerName: "Results",
        width: 80,
        sortable: false,
        renderCell: () => "Sim results",
      },
      {
        field: "delete",
        width: 75,
        sortable: false,
        disableColumnMenu: true,
        renderHeader: () => {
          return (
            <IconButton
              onClick={() => {
                handleBulkDelete([...selectedRows])
                // you can call an API to delete the selected IDs
                // and get the latest results after the deletion
                // then call setRows() to update the data locally here
                //setRows((r) => r.filter((x) => !selectedIDs.has(x.id)))
              }}
            >
              <DeleteIcon />
            </IconButton>
          )
        },
      },
    ]
  }, [dropHand, dropHands, selectedRows])

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <PageTitle text="Hand History" />
      <Box
        sx={{ display: "flex", gap: 1, marginBottom: 1, alignItems: "center" }}
      >
        <input
          style={{ display: "none" }}
          id="import-file"
          multiple
          type="file"
          onChange={handleImport}
        />
        <label htmlFor="import-file">
          <Button component="span">Import</Button>
        </label>
        {(isLoading || isFetching) && <Info>Loading…</Info>}
        {isError && (
          <Error>
            {(error as FetchBaseQueryError).status}{" "}
            {"data" in error &&
              (error as { data: { error: string } }).data.error}
          </Error>
        )}
      </Box>
      <DataGrid
        checkboxSelection
        onSelectionModelChange={(ids) => {
          setSelectionModel(ids)
        }}
        // rows={params.has("test") ? TEST_DATA : data || []}
        rows={params.has("test") ? TEST_DATA : hands || []}
        columns={columns}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        initialState={{
          sorting: {
            sortModel: [{ field: "importedOn", sort: "desc" }],
          },
        }}
      />
    </Box>
  )
}

export function cardToEmoji(card: string, className?: string) {
  if (!card) return null
  const rank = card.slice(0, card.length - 1)
  const suit = card[card.length - 1]
  let pos = 0
  switch (rank) {
    case "A":
      pos = 0
      break
    case "T":
      pos = 9
      break
    case "J":
      pos = 10
      break
    case "Q":
      pos = 11
      break
    case "K":
      pos = 12
      break
    default:
      pos = parseInt(rank) - 1
  }
  let mult = 0
  switch (suit) {
    case "s":
      mult = 0
      break
    case "h":
      mult = 1
      break
    case "d":
      mult = 2
      break
    case "c":
      mult = 3
      break
    default:
      mult = 0
  }
  return (
    <span key={card} className={(className || "defaultCard") + ` suit-${suit}`}>
      {CARDS_EMOJI[mult * 13 + pos]}
    </span>
  )
}

const CARDS_EMOJI = [
  "🂡",
  "🂢",
  "🂣",
  "🂤",
  "🂥",
  "🂦",
  "🂧",
  "🂨",
  "🂩",
  "🂪",
  "🂫",
  "🂭",
  "🂮",
  "🂱",
  "🂲",
  "🂳",
  "🂴",
  "🂵",
  "🂶",
  "🂷",
  "🂸",
  "🂹",
  "🂺",
  "🂻",
  "🂽",
  "🂾",
  "🃁",
  "🃂",
  "🃃",
  "🃄",
  "🃅",
  "🃆",
  "🃇",
  "🃈",
  "🃉",
  "🃊",
  "🃋",
  "🃍",
  "🃎",
  "🃑",
  "🃒",
  "🃓",
  "🃔",
  "🃕",
  "🃖",
  "🃗",
  "🃘",
  "🃙",
  "🃚",
  "🃛",
  "🃝",
  "🃞",
]

import { useGetRangeByActionsQuery } from "services/rangesApi"
import { RangeInfo } from "services/types"
import { ACTION_CHARS, RangeParameters } from "../types"

export function useGetRange(props: RangeParameters): RangeInfo | undefined {
  const query = useGetRangeByActionsQuery({
    rangeset_id: props.rangeset || "",
    stack_size: Number(props.stackSize) || 0,
    position: props.heroPosition || "",
    path: props.filterActions
      ? props.filterActions
          .map(
            (x) =>
              x.position +
              " " +
              ACTION_CHARS[x.action] +
              (x.action === "RAISE" ? x.raiseAmount : "")
          )
          .join(", ")
      : "",
  })
  return query.isSuccess ? query.data : undefined
}

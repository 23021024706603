import { Box } from "@mui/material"
import { cardToEmoji } from "features/HandHistories/HandHistories"
import { useContext } from "react"
import { Winner } from "services/types"
import { hhContext } from "./HHContext"
import { HandPosition } from "./types"

const WinnerView = (props: Winner) => {
  const context = useContext(hhContext)
  const nick = context.hand?.nicknames
    ? context.hand?.nicknames[props.position]
    : ""

  const cards = !props.cards ? [] : props.cards.match(/.{2}/g)

  return (
    <Box sx={{ py: "5px", display: "flex", alignItems: "center" }}>
      <Box>
        {" "}
        <span className="winner-nick">
          {props.position} {nick ? `(${nick})` : ""}
        </span>{" "}
        won <span className="winner-amount">{props.pot}</span>
        {"BB "}
        <span className="winner-desc">
          {props.description ? `with ${props.description}` : ""}
        </span>
      </Box>
      {cards && <Box>{cards.map((v) => cardToEmoji(v, "streetCard"))}</Box>}
    </Box>
  )
}

export function GameResults() {
  const context = useContext(hhContext)

  const winners = context.hand.winners

  if (!winners) return null

  return (
    <Box>
      <Box sx={{ fontWeight: "bold" }}>Game Results</Box>
      {winners && (
        <>
          {Object.keys(winners).map((v) => {
            return (
              <Box key={v}>
                <WinnerView {...winners[v as HandPosition]} />
              </Box>
            )
          })}
        </>
      )}
    </Box>
  )
}

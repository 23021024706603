import { Box } from "@mui/material"
import { cardToEmoji } from "features/HandHistories/HandHistories"
import { hhContext } from "features/HHReplay/HHContext"
import {
  HandActionChar,
  HandPosition,
  HAND_ACTIONS_STRINGS,
} from "features/HHReplay/types"
import { Cards } from "features/types"
import { useCallback, useContext, useMemo, useState } from "react"
import { PFCardPair, PostflopRange } from "services/types"
import { getCellId } from "../utils"
import "./index.css"
import { pairInBoard } from "./PFRangeView"
import { operationOrder, sortedOperations } from "./PostflopRangeView"

export type PFRANGE_TYPE = "IP" | "OOP"

interface PFStrategyViewProps {
  range: PostflopRange
  rangeType: PFRANGE_TYPE
  position: HandPosition
}

export const betColors = [
  "hsl(10, 39%, 45%)",
  "hsl(10, 39%, 50%)",
  "hsl(10, 39%, 55%)",
  "hsl(10, 39%, 60%)",
  "hsl(10, 39%, 65%)",
  "hsl(10, 39%, 70%)",
  "hsl(10, 39%, 75%)",
  "hsl(10, 39%, 80%)",
  "hsl(10, 39%, 85%)",
  "hsl(10, 39%, 90%)",
  "hsl(10, 39%, 95%)",
]

export const opColors: Record<string, string> = {
  f: "rgb(109,	162,	192)",
  c: "lightgreen",
  x: "lightgreen",
}

export const comparePair = (a: string, b: string): number => {
  const suitOrder = ["s", "h", "d", "c"]
  const aOne = a.substring(1, 2)
  const bOne = b.substring(1, 2)
  if (aOne != bOne) {
    return suitOrder.indexOf(aOne) > suitOrder.indexOf(bOne) ? 1 : -1
  }
  const aTwo = a.substring(3, 4)
  const bTwo = b.substring(3, 4)
  return suitOrder.indexOf(aTwo) > suitOrder.indexOf(bTwo) ? 1 : -1
}

export function PFStrategyView(props: PFStrategyViewProps) {
  const context = useContext(hhContext)
  const { range, rangeType, position } = props
  const [currentCell, setCurrentCell] = useState<string | undefined>("AA")

  const selectCell = useCallback(
    (card: string | undefined) => {
      setCurrentCell(card)
    },
    [setCurrentCell]
  )

  const getPFStrategyPairBackground = useCallback(
    (pair: string, none_color?: string) => {
      if (!currentCell) return ""

      let ratio = 0
      if (range !== null)
        ratio =
          rangeType == "IP"
            ? range.rangeGroups[currentCell].hands[pair].ipRangeInfo.probability
            : range.rangeGroups[currentCell].hands[pair].oopRangeInfo
                .probability

      const operations = sortedOperations(
        range ? range.rangeGroups[currentCell].hands[pair].strategy : {}
      )

      const none_bgcolor = none_color || "#999999"
      if (ratio === undefined || ratio === null) {
        return `linear-gradient(${none_bgcolor} 0%, ${none_bgcolor} 100%)`
      }

      const scale = 100

      const probabilityPercent: number = ratio * scale
      if (probabilityPercent <= 0) return none_bgcolor

      let strategies = "linear-gradient(to right, "
      let opPercent = 0
      let opColorIdx = 0
      for (const op of operations) {
        const opRatio = op.ratio * 100
        let opColor = opColors[op.op.slice(0, 1)]
        if (!opColor) {
          opColor = betColors[opColorIdx] || "yellow"
          opColorIdx++
        }
        strategies += `${opColor} ${opPercent}%, ${opColor} ${
          opRatio + opPercent
        }%, `
        opPercent += opRatio
      }
      strategies += `black ${opPercent}%, black 100%)`

      const result =
        `linear-gradient(to bottom, ${none_color} 0%, ${none_color} 100%) ` +
        `0% 0%/100% ${100 - probabilityPercent}% no-repeat, ` +
        `${strategies} no-repeat 0% 100%/100% ${probabilityPercent}%`

      return result
    },
    [range, rangeType, currentCell]
  )

  const getPFStrategyCellBackground = useCallback(
    (cellId: string, none_color?: string) => {
      let ratio = 0
      if (range !== null)
        ratio =
          rangeType == "IP"
            ? range.rangeGroups[cellId].ipRange
            : range.rangeGroups[cellId].oopRange

      const operations = Object.keys(
        range !== null ? range.rangeGroups[cellId].strategy : {}
      )
        .map((op) => {
          return {
            op: op.slice(0, 1),
            value: op.slice(1),
            ratio: range ? range.rangeGroups[cellId].strategy[op] : 0,
          }
        })
        .sort((a, b) => {
          const order = ["b", "r", "c", "x", "f"]
          if (a.op == b.op)
            return parseFloat(a.value) > parseFloat(b.value) ? -1 : 1
          return order.indexOf(a.op) > order.indexOf(b.op) ? 1 : -1
        })

      const none_bgcolor = none_color || "#999999"
      if (ratio === undefined || ratio === null) {
        return `linear-gradient(${none_bgcolor} 0%, ${none_bgcolor} 100%)`
      }

      const scale = 100

      const probabilityPercent: number = ratio * scale
      if (probabilityPercent <= 0) return none_bgcolor

      let strategies = "linear-gradient(to right, "
      let opPercent = 0
      let opColorIdx = 0
      for (const op of operations) {
        const opRatio = op.ratio * 100
        let opColor = opColors[op.op.slice(0, 1)]
        if (!opColor) {
          opColor = betColors[opColorIdx] || "yellow"
          opColorIdx++
        }

        strategies += `${opColor} ${opPercent}%, ${opColor} ${
          opRatio + opPercent
        }%, `
        opPercent += opRatio
      }
      strategies += `black ${opPercent}%, black 100%)`

      const result =
        `linear-gradient(to bottom, ${none_color} 0%, ${none_color} 100%) ` +
        `0% 0%/100% ${100 - probabilityPercent}% no-repeat, ` +
        `${strategies} no-repeat 0% 100%/100% ${probabilityPercent}%`

      return result
    },
    [range, rangeType]
  )

  const rows = useMemo(() => {
    const rows: JSX.Element[] = []

    for (let i = 0; i < Cards.length; i++) {
      const cells = []
      for (let j = 0; j < Cards.length; j++) {
        const cellId: string = getCellId(i, j)
        const strategy =
          range === null ? {} : range.rangeGroups[cellId].strategy
        const actions = Object.keys(strategy).sort((a, b) => {
          const op1 = a[0],
            op2 = b[0]
          const val1 = a.substring(1),
            val2 = b.substring(2)
          if (op1 == op2) return parseFloat(val1) > parseFloat(val2) ? -1 : 1
          return operationOrder.indexOf(op1) > operationOrder.indexOf(op2)
            ? 1
            : -1
        })

        const cell = (
          <Box
            onClick={() => (range ? selectCell(cellId) : undefined)}
            key={cellId}
            className={`pfstrategy-cell ${
              cellId == currentCell ? "pfrange-selected" : ""
            }`}
            style={{
              background: getPFStrategyCellBackground(
                cellId,
                i == j ? "#cccccc" : "#aaaaaa"
              ),
            }}
          >
            <Box sx={{ flexGrow: 1 }}>{cellId}</Box>
            {actions.map((v) => {
              const ratio = strategy[v]
              // if (ratio == 0) return null
              return (
                <Box key={v} sx={{ alignSelf: "end" }}>
                  {ratio < 1 ? ratio.toFixed(2) : ratio}
                </Box>
              )
            })}
          </Box>
        )
        cells.push(cell)
      }
      const row = (
        <div key={i} className="pfrange-row">
          {cells}
        </div>
      )
      rows.push(row)
    }
    return rows
  }, [range, selectCell, currentCell, getPFStrategyCellBackground])

  const PairInfo = ({ pair }: { pair: PFCardPair }) => {
    if (!currentCell || range === null) return null
    const operations = sortedOperations(
      range.rangeGroups[currentCell].hands[pair.hand].strategy
    )
    return (
      <Box
        sx={{
          mx: "2px",
          mb: "2px",
          display: "flex",
          flex: "1",
          flexDirection: "column",
          minHeight: `${operations.length * 14 + 40}px`,
          lineHeight: "1",
          border: "1px solid gray",
          fontSize: "14px",
          position: "relative",
          background: getPFStrategyPairBackground(pair.hand, "#cccccc"),
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          {cardToEmoji(pair.hand.substring(0, 2), "pfCard")}
          {cardToEmoji(pair.hand.substring(2, 4), "pfCard")}
        </Box>
        {!pairInBoard(range?.board || "", pair.hand) &&
          operations.map((v) => {
            const action = `${v.op}${v.value}`
            return (
              <Box key={action} sx={{ display: "flex" }}>
                <Box sx={{ flexGrow: 1 }}>
                  {HAND_ACTIONS_STRINGS[v.op as HandActionChar]} {v.value}
                </Box>
                <Box>{v.ratio.toFixed(2)}</Box>
              </Box>
            )
          })}
      </Box>
    )
  }

  const CellInfo = () => {
    if (!currentCell || range === null) return null

    const pairs = range.rangeGroups[currentCell].hands
    const pairKeys = Object.keys(pairs).sort(comparePair)
    const nPairs = pairKeys.length

    const rows: JSX.Element[] = []
    if (nPairs % 3 == 0) {
      for (let i = 0; i < nPairs; i += 3) {
        const row = []
        let key = pairKeys[i]
        row.push(<PairInfo key={key} pair={pairs[key]} />)
        key = pairKeys[i + 1]
        row.push(<PairInfo key={key} pair={pairs[key]} />)
        key = pairKeys[i + 2]
        row.push(<PairInfo key={key} pair={pairs[key]} />)
        rows.push(
          <Box key={i} sx={{ display: "flex" }}>
            {row}
          </Box>
        )
      }
    } else if (nPairs % 2 == 0) {
      for (let i = 0; i < nPairs; i += 2) {
        const row = []
        let key = pairKeys[i]
        row.push(<PairInfo key={key} pair={pairs[key]} />)
        key = pairKeys[i + 1]
        row.push(<PairInfo key={key} pair={pairs[key]} />)
        rows.push(
          <Box key={i} sx={{ display: "flex" }}>
            {row}
          </Box>
        )
      }
    }

    return <Box sx={{ minWidth: "400px" }}>{rows}</Box>
  }

  const nick = context.hand?.nicknames?.[position]

  return (
    <Box>
      <Box>
        <Box sx={{ fontWeight: "bold", textAlign: "center" }}>
          Strategy {rangeType} ({position})
          <span className="pfrange_nick">{nick}</span>
        </Box>
        <Box sx={{ display: "flex", pt: 0.5 }}>
          <Box className="pfstrategy">{rows}</Box>
          <Box sx={{ minWidth: "10px" }} />
          <CellInfo />
        </Box>
      </Box>
    </Box>
  )
}

import { Box } from "@mui/material"
import { useContext, useMemo } from "react"
import { hhContext } from "./HHContext"
import { useIsCurrentActionLast } from "./hooks"
import { HandPosition } from "./types"

export function GameFinishedView() {
  const context = useContext(hhContext)
  const gameFinished = useIsCurrentActionLast()

  const winner = context.hand.lastState?.winner
  const winners = context.hand.winners

  const winnerAmount = useMemo(() => {
    if (!winner) return
    if (!context.hand.lastState?.pot) return
    return parseFloat(context.hand.lastState.pot.toFixed(2))
  }, [winner, context])

  if (!gameFinished) return null

  return (
    <Box className="poker-table-results" sx={{ display: "flex" }}>
      <Box>Game Finished!&nbsp;</Box>
      {winners && (
        <>
          {Object.keys(winners).map((v) => {
            return (
              <Box key={v}>
                {v} won {winners[v as HandPosition].pot}
              </Box>
            )
          })}
        </>
      )}
      {!winners && winner && (
        <Box>
          {" "}
          {winner} won {winnerAmount}
        </Box>
      )}
    </Box>
  )
}

import { Box } from "@mui/material"
import { useGetStrategiesQuery } from "services/rangesApi"
import { CommonSpotsGameParameters } from "./CommonSpotsGameParameters"

export function CommonSpotsExplorerView(): JSX.Element {
  const {
    data: strategies,
    // isLoading,
    isSuccess: haveStrategies,
    // isError,
  } = useGetStrategiesQuery()

  if (!strategies) return <></>

  const strategy = strategies.filter((v) => v.is_default)[0]
  if (!strategy) return <></>

  return (
    <>
      <CommonSpotsGameParameters strategy={strategy} />
      <Box
        className="overflow-check2"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflowY: "scroll",
          overflowX: "hidden",
          width: { xs: "100vw", sm: "100vw", md: "auto" },
        }}
      ></Box>
    </>
  )
}

// import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt"
import DoneAllIcon from "@mui/icons-material/DoneAll"
import PsychologyAltOutlinedIcon from "@mui/icons-material/PsychologyAltOutlined"
import { Box } from "@mui/material"
import {
  getCurrentAction,
  getPrevPositionState,
} from "features/HHReplay/ActionPointer"
import {
  HandActionIndex,
  HHContext,
  hhContext,
} from "features/HHReplay/HHContext"
import {
  Hand,
  HandAction,
  HandActionChar,
  HandPlayerStateInfo,
} from "features/HHReplay/types"
import { useContext } from "react"
import { seatByCountAndPosition } from "./TableView"

export function getHandAction(
  hand: Hand,
  currentActionIndex: HandActionIndex
): HandAction | undefined {
  switch (currentActionIndex.street) {
    case "preflop":
      return hand.preFlop?.actions
        ? hand.preFlop?.actions[currentActionIndex.index]
        : undefined
    case "flop":
      return hand.flop?.actions
        ? hand.flop?.actions[currentActionIndex.index]
        : undefined
    case "turn":
      return hand.turn?.actions
        ? hand.turn?.actions[currentActionIndex.index]
        : undefined
    case "river":
      return hand.river?.actions
        ? hand.river?.actions[currentActionIndex.index]
        : undefined
  }
}

export function getCurrentActionSeat(context: HHContext): number | undefined {
  const action = getCurrentAction(context)

  if (!action || !action.state || !action.state.playerStates) return undefined
  if (!context.hand.playersCount || !context.hand.playersCount.playersCount)
    return undefined

  return seatByCountAndPosition(
    context.hand.playersCount.playersCount,
    action.position
  )
}

export function getActionString(chr: HandActionChar): string {
  switch (chr) {
    case "f":
      return "Fold"
    case "c":
      return "Call"
    case "x":
      return "Check"
    case "b":
      return "Bet"
    case "r":
      return "Raise"
    case "a":
      return "AllIn"
  }
}

export function ActionPointer() {
  const context = useContext(hhContext)
  const seat = getCurrentActionSeat(context)
  const action = getCurrentAction(context)
  const actionPointer = context.currentActionIndex

  if (actionPointer === undefined) return null
  if (!action) return null
  if (!seat || seat <= 0) return null

  const curentPlayerState: HandPlayerStateInfo | undefined =
    getPrevPositionState(context, context.currentActionIndex, action.position)
  if (curentPlayerState === undefined) return null

  const streetFinished = action.isManual && action.state.streetFinished

  const diagonalClass: Record<string, string> = {
    1: "diagonaltb",
    5: "diagonaltb",
    8: "diagonalbt",
    10: "diagonaltb",
    3: "diagonalbt",
    4: "diagonalbt",
    6: "diagonaltb",
    9: "diagonalbt",
  }
  return (
    <div
      className={`seat-pointer seat-${seat}-pointer ${
        diagonalClass[`${seat}` as string]
      }`}
    >
      <div className={`seat-pointer-action seat-${seat}-pointer-action`}>
        <Box>
          {!streetFinished && (
            <PsychologyAltOutlinedIcon sx={{ fontSize: "48px" }} />
          )}
          {streetFinished && (
            <>
              <DoneAllIcon sx={{ fontSize: "48px" }} />
              {/* flop */}
            </>
          )}
        </Box>
      </div>
    </div>
  )
}

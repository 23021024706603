import { Box } from "@mui/material"
import { useContext } from "react"
import { hhContext, HHContext } from "./HHContext"
import { seatByCountAndPosition } from "./TableView"

function getCurrentActionSeat(context: HHContext): number | undefined {
  if (!context.shownRangePosition) return undefined
  if (!context.hand.playersCount || !context.hand.playersCount.playersCount)
    return undefined

  return seatByCountAndPosition(
    context.hand.playersCount.playersCount,
    context.shownRangePosition
  )
}

export function RangePointer() {
  const context = useContext(hhContext)
  const seat = getCurrentActionSeat(context)
  if (!seat || seat <= 0) return null

  const diagonalClass: Record<string, string> = {
    1: "diagonalrangetb",
    5: "diagonalrangetb",
    8: "diagonalrangebt",
    10: "diagonalrangetb",
    3: "diagonalrangebt",
    4: "diagonalrangebt",
    6: "diagonalrangetb",
    9: "diagonalrangebt",
  }
  return (
    <Box
      sx={
        {
          // display: { xs: "none", sm: "block" },
        }
      }
      className={`seat-range-pointer seat-range-${seat}-pointer ${
        diagonalClass[`${seat}` as string]
      }`}
    ></Box>
  )
}

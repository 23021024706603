import { Box, Typography } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import Switch from "@mui/material/Switch"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import {
  HandPosition,
  HandPositionOrder,
  PostflopHandPositionOrder,
} from "features/HHReplay/types"
import { useGetRange } from "features/Ranges/preflop/hooks"
import { RangeView } from "features/Ranges/preflop/RangeView"
import {
  Action,
  CATEGORY_ACTION,
  FilterAction,
  RangeParameters,
} from "features/Ranges/types"
import { useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { RangeSet } from "services/types"

enum RANGE_CATEGORY {
  CATEGORY_SRP = "CATEGORY_SRP",
  CATEGORY_3BET = "CATEGORY_3BET",
  CATEGORY_4BET = "CATEGORY_4BET",
}
type RangeCategory = keyof typeof RANGE_CATEGORY

interface PairRangeParameters {
  oopRangeTitle: string
  oopRangeParameters: RangeParameters
  oopActionFilter: Action
  ipRangeTitle: string
  ipRangeParameters: RangeParameters
  ipActionFilter: Action
}

type CategoryHandler = (
  oop: HandPosition,
  ip: HandPosition,
  stackSize: number
) => PairRangeParameters[] | undefined

type CategoryHandlers = { [key in RangeCategory]?: CategoryHandler }

const CATEGORIES = [
  { label: "SRP HU", value: RANGE_CATEGORY.CATEGORY_SRP },
  { label: "3B HU", value: RANGE_CATEGORY.CATEGORY_3BET },
  { label: "4B HU", value: RANGE_CATEGORY.CATEGORY_4BET },
]

const CATEGORY_HANDLERS: CategoryHandlers = {}
CATEGORY_HANDLERS.CATEGORY_SRP = (
  oop: HandPosition,
  ip: HandPosition,
  stackSize: number
) => {
  const oopFirst =
    HandPositionOrder.indexOf(oop) > HandPositionOrder.indexOf(ip)

  return [
    {
      oopRangeTitle: oopFirst
        ? `RFI ${oop} @${stackSize}`
        : `${oop} vs ${ip} @${stackSize}`,
      oopActionFilter: oopFirst ? "RAISE" : "CALL",
      oopRangeParameters: {
        stackSize: stackSize,
        heroPosition: oop,
        category: CATEGORY_ACTION,
        filterActions: oopFirst
          ? []
          : [
              {
                position: ip,
                action: "RAISE",
                raiseAmount: 1, // TODO, does raise amount matter? do we need iteratively get recommended raise?
              } as FilterAction,
            ],
      } as RangeParameters,
      ipRangeTitle: !oopFirst
        ? `RFI ${ip} @${stackSize}`
        : `${ip} vs ${oop} @${stackSize}`,
      ipActionFilter: !oopFirst ? "RAISE" : "CALL",
      ipRangeParameters: {
        stackSize: stackSize,
        heroPosition: ip,
        category: CATEGORY_ACTION,
        filterActions: !oopFirst
          ? []
          : [
              {
                position: oop,
                action: "RAISE",
                raiseAmount: 1,
              } as FilterAction,
            ],
      } as RangeParameters,
    },
    {
      oopRangeTitle: oopFirst
        ? `RFI ${oop} @${stackSize}`
        : `${oop} vs ${ip} @${stackSize}`,
      oopActionFilter: oopFirst ? "ALL_IN" : "CALL",
      oopRangeParameters: {
        stackSize: stackSize,
        heroPosition: oop,
        category: CATEGORY_ACTION,
        filterActions: oopFirst
          ? []
          : [
              {
                position: ip,
                action: "ALL_IN",
              } as FilterAction,
            ],
      } as RangeParameters,
      ipRangeTitle: !oopFirst
        ? `RFI ${ip} @${stackSize}`
        : `${ip} vs ${oop} @${stackSize}`,
      ipActionFilter: !oopFirst ? "ALL_IN" : "CALL",
      ipRangeParameters: {
        stackSize: stackSize,
        heroPosition: ip,
        category: CATEGORY_ACTION,
        filterActions: !oopFirst
          ? []
          : [
              {
                position: oop,
                action: "ALL_IN",
              } as FilterAction,
            ],
      } as RangeParameters,
    },
  ]
}

const buildRangeFilter = (
  stackSize: number,
  position: HandPosition,
  filter: FilterAction[]
): RangeParameters => {
  return {
    stackSize: stackSize,
    heroPosition: position,
    category: CATEGORY_ACTION,
    filterActions: filter,
  } as RangeParameters
}

const filter3bet = (
  heroPosition: HandPosition,
  openPosition: HandPosition,
  oppositePosition: HandPosition,
  actions: Action[]
): FilterAction[] => {
  return openPosition == heroPosition
    ? [
        {
          position: openPosition,
          action: actions[0],
          raiseAmount: 1, // TODO, does raise amount matter? do we need iteratively get recommended raise?
        } as FilterAction,
        {
          position: oppositePosition,
          action: actions[1],
          raiseAmount: 1, // TODO, does raise amount matter? do we need iteratively get recommended raise?
        } as FilterAction,
      ]
    : [
        {
          position: openPosition,
          action: actions[0],
          raiseAmount: 1, // TODO, does raise amount matter? do we need iteratively get recommended raise?
        } as FilterAction,
      ]
}

CATEGORY_HANDLERS.CATEGORY_3BET = (
  oop: HandPosition,
  ip: HandPosition,
  stackSize: number
) => {
  const oopFirst =
    HandPositionOrder.indexOf(oop) > HandPositionOrder.indexOf(ip)

  return [
    {
      oopRangeTitle: !oopFirst
        ? `3Bet ${oop} @${stackSize}`
        : `${oop} vs ${ip} @${stackSize}`,
      oopActionFilter: oopFirst ? "CALL" : "RAISE",
      oopRangeParameters: buildRangeFilter(
        stackSize,
        oop,
        filter3bet(oop, oopFirst ? oop : ip, oopFirst ? ip : oop, [
          "RAISE",
          "RAISE",
          "CALL",
        ])
      ),
      ipRangeTitle: oopFirst
        ? `3Bet ${ip} @${stackSize}`
        : `${ip} vs ${oop} @${stackSize}`,
      ipActionFilter: !oopFirst ? "CALL" : "RAISE",
      ipRangeParameters: buildRangeFilter(
        stackSize,
        ip,
        filter3bet(ip, oopFirst ? oop : ip, oopFirst ? ip : oop, [
          "RAISE",
          "RAISE",
          "CALL",
        ])
      ),
    },
    {
      oopRangeTitle: !oopFirst
        ? `3Bet ${oop} @${stackSize}`
        : `${oop} vs ${ip} @${stackSize}`,
      oopActionFilter: oopFirst ? "CALL" : "ALL_IN",
      oopRangeParameters: buildRangeFilter(
        stackSize,
        oop,
        filter3bet(oop, oopFirst ? oop : ip, oopFirst ? ip : oop, [
          "RAISE",
          "ALL_IN",
          "CALL",
        ])
      ),
      ipRangeTitle: oopFirst
        ? `3Bet ${ip} @${stackSize}`
        : `${ip} vs ${oop} @${stackSize}`,
      ipActionFilter: !oopFirst ? "CALL" : "ALL_IN",
      ipRangeParameters: buildRangeFilter(
        stackSize,
        ip,
        filter3bet(ip, oopFirst ? oop : ip, oopFirst ? ip : oop, [
          "RAISE",
          "ALL_IN",
          "CALL",
        ])
      ),
    },
  ]
}

const filter4bet = (
  heroPosition: HandPosition,
  openPosition: HandPosition,
  oppositePosition: HandPosition,
  actions: Action[]
): FilterAction[] => {
  return openPosition == heroPosition
    ? [
        {
          position: openPosition,
          action: actions[0],
          raiseAmount: 1, // TODO, does raise amount matter? do we need iteratively get recommended raise?
        } as FilterAction,
        {
          position: oppositePosition,
          action: actions[1],
          raiseAmount: 1, // TODO, does raise amount matter? do we need iteratively get recommended raise?
        } as FilterAction,
      ]
    : [
        {
          position: openPosition,
          action: actions[0],
          raiseAmount: 1, // TODO, does raise amount matter? do we need iteratively get recommended raise?
        } as FilterAction,
      ]
}

CATEGORY_HANDLERS.CATEGORY_4BET = () => {
  return undefined
}

interface CategorySelectorProps {
  category: string
  changeCategory: (category: string) => void
}

const CategorySelector = (props: CategorySelectorProps) => {
  const { category, changeCategory } = props

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (newAlignment) changeCategory(newAlignment)
  }

  return (
    <ToggleButtonGroup
      value={category}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
    >
      {CATEGORIES.map((v) => {
        return (
          <ToggleButton size="small" key={v.value} value={v.value}>
            {v.label}
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
}

interface StackSizesSelectorProps {
  strategy: RangeSet
  stacks: string[]
  changeStacks: (stacks: string[]) => void
  multiselect: boolean
}

const StackSizesSelector = (props: StackSizesSelectorProps) => {
  const { strategy, changeStacks, stacks, multiselect } = props
  const sizes = [...strategy.sizes].sort((a, b) => (a.size > b.size ? 1 : -1))

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[] | string
  ) => {
    let newVal: string[] = []
    if (typeof newFormats == "string") newVal = [newFormats]
    else newVal = newFormats
    newVal.sort((n1, n2) => (Number(n1) > Number(n2) ? 1 : -1))
    changeStacks(newVal)
  }

  return (
    <>
      <Typography variant="body1" sx={{ fontWeight: "600" }}>
        Stack sizes
      </Typography>
      <ToggleButtonGroup
        value={stacks}
        exclusive={!multiselect}
        onChange={handleFormat}
        aria-label="text formatting"
      >
        {sizes.map((v) => {
          return (
            <ToggleButton
              size="small"
              key={v.size}
              value={v.size.toString()}
              aria-label="bold"
            >
              {v.size}
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup>
    </>
  )
}

interface NumPlayersSelectorProps {
  players: number
  changeNPlayers: (n: number) => void
}

const NumPlayersSelector = (props: NumPlayersSelectorProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    props.changeNPlayers(Number(event.target.value))
  }

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small">Players</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={props.players.toString()}
        label="Players"
        onChange={handleChange}
      >
        {Array.from(Array(9), (_, i) => i + 2).map((nPplayers) => {
          return (
            <MenuItem
              key={nPplayers}
              value={nPplayers}
            >{`${nPplayers}-handed`}</MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

const allowedPosition = (nPlayers: number) => {
  return [
    ...["SB", "BB"],
    ...PostflopHandPositionOrder.slice(0, nPlayers - 2).reverse(),
  ]
}

interface OOPSelectorProps {
  nPlayers: number
  positions: HandPosition[]
  changeOOP: (positions: HandPosition[]) => void
  multiselect: boolean
}

const OOPSelector = (props: OOPSelectorProps) => {
  const { nPlayers, positions, changeOOP, multiselect } = props
  const players = allowedPosition(nPlayers)

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: HandPosition[] | HandPosition
  ) => {
    let newVal: HandPosition[] = []
    if (typeof newFormats == "string") newVal = [newFormats]
    else newVal = newFormats
    newVal.sort((n1, n2) =>
      PostflopHandPositionOrder.indexOf(n1) <
      PostflopHandPositionOrder.indexOf(n2)
        ? 1
        : -1
    )
    changeOOP(newVal)
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <Typography variant="body1" sx={{ fontWeight: "600" }}>
        OOP
      </Typography>
      <ToggleButtonGroup
        exclusive={!multiselect}
        value={positions}
        onChange={handleFormat}
        aria-label="text formatting"
      >
        {players.map((v) => {
          return (
            <ToggleButton size="small" key={v} value={v} aria-label="bold">
              {v}
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup>
    </Box>
  )
}

interface IPSelectorProps {
  nPlayers: number
  positions: HandPosition[]
  changeIP: (positions: HandPosition[]) => void
  multiselect: boolean
}

const IPSelector = (props: IPSelectorProps) => {
  const { nPlayers, positions, changeIP, multiselect } = props
  const players = allowedPosition(nPlayers)

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: HandPosition[] | HandPosition
  ) => {
    let newVal: HandPosition[] = []
    if (typeof newFormats == "string") newVal = [newFormats]
    else newVal = newFormats
    newVal.sort((n1, n2) =>
      PostflopHandPositionOrder.indexOf(n1) <
      PostflopHandPositionOrder.indexOf(n2)
        ? 1
        : -1
    )
    changeIP(newVal)
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <Typography variant="body1" sx={{ fontWeight: "600" }}>
        IP
      </Typography>
      <ToggleButtonGroup
        exclusive={!multiselect}
        value={positions}
        onChange={handleFormat}
        aria-label="text formatting"
      >
        {players.map((v) => {
          return (
            <ToggleButton size="small" key={v} value={v} aria-label="bold">
              {v}
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup>
    </Box>
  )
}

interface GameParameterProps {
  strategy: RangeSet
}

export const CommonSpotsGameParameters = (props: GameParameterProps) => {
  const [stacks, setStacks] = useState<string[]>(["30"])
  const [oopPositions, setOOP] = useState<HandPosition[]>(["BB"])
  const [ipPositions, setIP] = useState<HandPosition[]>(["BTN"])
  const [nPlayers, setNPlayers] = useState<number>(8)
  const [category, setCategory] = useState<string>(RANGE_CATEGORY.CATEGORY_SRP)
  const [multiselect, setMultiselect] = useState(false)

  const changeMultiselect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMultiselect(event.target.checked)
    if (!event.target.checked) {
      setStacks([[...stacks][0]])
      setOOP([[...oopPositions][0]])
      setIP([[...ipPositions][0]])
    }
  }

  const changeNPlayers = (n: number) => {
    if (nPlayers == n) return
    if (n < nPlayers) {
      const all = allowedPosition(n)
      // filter out dissapeared positions
      setIP(ipPositions.filter((v) => all.indexOf(v) >= 0))
      setOOP(oopPositions.filter((v) => all.indexOf(v) >= 0))
    }
    setNPlayers(n)
  }

  const rangePairs = useMemo(() => {
    const ipoopPairs: {
      pairRangeParameters: PairRangeParameters
    }[] = []
    oopPositions.forEach((oopPosition) => {
      ipPositions.forEach((ipPosition) => {
        // check that pair is valid, IP is really in position
        const validPair =
          PostflopHandPositionOrder.indexOf(ipPosition) <
          PostflopHandPositionOrder.indexOf(oopPosition)
        if (!validPair) return

        stacks.forEach((stackSize) => {
          const rangesParameters = (
            CATEGORY_HANDLERS[category as RangeCategory] ||
            function () {
              return undefined
            }
          )(oopPosition, ipPosition, Number(stackSize))
          if (!rangesParameters || rangesParameters.length == 0) return

          rangesParameters.forEach((pair) => {
            pair.ipRangeParameters.rangeset = props.strategy.id
            pair.oopRangeParameters.rangeset = props.strategy.id
            ipoopPairs.push({
              pairRangeParameters: pair,
            })
          })
        })
      })
    })
    return ipoopPairs
  }, [oopPositions, ipPositions, stacks, category, props.strategy.id])

  const changeStacks = (newStacks: string[]) => {
    setStacks(newStacks)
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          pt: "8px",
          gap: "16px",
        }}
      >
        <CategorySelector
          category={category}
          changeCategory={(v) => setCategory(v)}
        />
        <NumPlayersSelector
          players={nPlayers}
          changeNPlayers={changeNPlayers}
        />
        <StackSizesSelector
          strategy={props.strategy}
          stacks={stacks}
          changeStacks={changeStacks}
          multiselect={multiselect}
        />

        <FormControlLabel
          sx={{ flexGrow: 1, justifyContent: "end" }}
          control={
            <Switch
              checked={multiselect}
              onChange={changeMultiselect}
              name="multiselect"
            />
          }
          label="Multiple filter selectors"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          py: "8px",
          gap: "16px",
        }}
      >
        <OOPSelector
          nPlayers={nPlayers}
          positions={oopPositions}
          changeOOP={(v) => setOOP(v)}
          multiselect={multiselect}
        />
        <IPSelector
          nPlayers={nPlayers}
          positions={ipPositions}
          changeIP={(v) => setIP(v)}
          multiselect={multiselect}
        />
      </Box>
      <Box
        className="overflow-check2"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflowY: "scroll",
          overflowX: "hidden",
          width: { xs: "100vw", sm: "100vw", md: "auto" },
        }}
      >
        <Box sx={{ display: "flex", gap: "32px", flexWrap: "wrap" }}>
          {rangePairs.map((v, idx) => {
            const pairRangeInfo = v.pairRangeParameters
            return <PairRanges key={`pair-range-${idx}`} {...pairRangeInfo} />
          })}
        </Box>
      </Box>
    </>
  )
}

const PairRanges = (props: PairRangeParameters) => {
  const [searchParams] = useSearchParams()
  const showPath = searchParams.get("showPath") == "1"

  const oopRange = useGetRange(props.oopRangeParameters)
  const ipRange = useGetRange(props.ipRangeParameters)
  if (!ipRange || !oopRange) return null

  return (
    <Box
      className="range_pair"
      sx={{
        p: "2px",
        background: "#eeeeee",
        display: "flex",
        gap: "8px",
        flexWrap: "nowrap",
        width: { xs: "calc(100vw - 8px)", sm: "auto" },
      }}
    >
      <Box
        sx={{
          maxWidth: { xs: "calc(100vw - 48px)", sm: "450px" },
          width: { xs: "calc(100vw - 48px)", sm: "450px" },
          flexShrink: 0,
          scrollSnapAlign: "center",
          display: "flex",
        }}
      >
        <RangeView
          rangeInfo={oopRange}
          parameters={props.oopRangeParameters}
          skipNotSuggested={false}
          hideHeader={false}
          hidePath={!showPath}
          title={props.oopRangeTitle}
          // actionFilter={props.oopActionFilter}
        />
      </Box>
      <Box
        sx={{
          maxWidth: { xs: "calc(100vw - 48px)", sm: "450px" },
          width: { xs: "calc(100vw - 48px)", sm: "450px" },
          flexShrink: 0,
          scrollSnapAlign: "center",
          display: "flex",
        }}
      >
        <RangeView
          rangeInfo={ipRange}
          parameters={props.ipRangeParameters}
          skipNotSuggested={false}
          hideHeader={false}
          hidePath={!showPath}
          title={props.ipRangeTitle}
          // actionFilter={props.ipActionFilter}
        />
      </Box>
    </Box>
  )
}

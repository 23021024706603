import { Box } from "@mui/material"
import classnames from "classnames"
import { cardToEmoji } from "features/HandHistories/HandHistories"
import { Card, cardToString, PositionLabels } from "features/types"
import { useContext, useMemo, useState } from "react"
import { CardSelectorDialog } from "./CardSelector"
import { GameFinishedView } from "./GameFinishedView"
import { GameResults } from "./GameResults"
import { compareHandActionIndex, hhContext } from "./HHContext"
import {
  HandAction,
  HandState,
  HandStreet,
  HAND_STREETS_STRINGS,
  setCommunityCard,
} from "./types"

interface GTActionProps {
  action: HandAction
  prevState?: HandState
  onClick: () => void
  active: boolean
  ident: number
  hero: boolean
}
const GTAction = (props: GTActionProps): React.ReactElement => {
  const { action, onClick, active, ident, hero, prevState } = props
  const stack = prevState?.playerStates[action.position]?.stack.toFixed(2)
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ minWidth: "24px" }}>{active && "\u21e8"}</Box>
      <Box
        className={classnames(`gtaction gtaction-${action.actionChar}`, {
          ["hero"]: hero,
          ["active"]: active,
        })}
        onClick={onClick}
        sx={{ ml: ident * 1 }}
      >
        <Box sx={{ flexGrow: 1 }}>
          {PositionLabels[action.position]} {parseFloat(stack || "0")}bb{" "}
          {action.actionString}{" "}
          {action.raisingAmount
            ? parseFloat(action.raisingAmount?.toFixed(2) || "0")
            : ""}
        </Box>
      </Box>
    </Box>
  )
}

export function getStreetHeader(street: HandStreet) {
  return HAND_STREETS_STRINGS[street]
}

interface StreetViewProps {
  startPot: string | undefined
  endPot: string | undefined
  street: HandStreet
  actions: { [key in HandStreet]: React.ReactElement[] }
  cards: { [key in HandStreet]: Card[] }
}
const StreetView = (props: StreetViewProps) => {
  const { street, startPot, endPot, actions, cards } = props
  const context = useContext(hhContext)
  const [manageCard, openCard] = useState<number | undefined>(undefined)
  return (
    <>
      {actions[street].length > 0 && (
        <Box
          sx={{
            flexShrink: 0,
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            borderRight: "1px solid #dddddd",
            pl: "5px",
          }}
        >
          <Box className="gtstreet">{getStreetHeader(street)}</Box>
          <Box className="startingpot">
            <span>Pot {startPot}BB</span>
            <span className="potsymbol"></span>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {cards[street].map((v, idx) => (
              <Box key={idx} onClick={() => openCard(idx)}>
                {cardToEmoji(cardToString(v), "streetCard")}
              </Box>
            ))}
            {manageCard !== undefined && (
              <CardSelectorDialog
                open={manageCard !== undefined}
                close={(newval) => {
                  if (newval) {
                    setCommunityCard(context, manageCard, {
                      value: newval[0],
                      suit: newval[1],
                    })
                  }
                  openCard(undefined)
                }}
                card={cardToString(cards[street][manageCard])}
              />
            )}
          </Box>
          <Box sx={{ flexGrow: 1 }}>{actions[street]}</Box>
          <Box className="endingpot">
            <span>Pot {endPot}BB</span>
            <span className="potsymbol"></span>
          </Box>
        </Box>
      )}
    </>
  )
}

export function GameTreeView() {
  const context = useContext(hhContext)

  const actions: { [key in HandStreet]: React.ReactElement[] } = {
    preflop: [],
    flop: [],
    turn: [],
    river: [],
  }

  const cards: { [key in HandStreet]: Card[] } = useMemo(() => {
    return {
      preflop: [],
      flop: context.hand.flopCards?.cards || [],
      turn: [
        ...(context.hand.flopCards?.cards || []),
        ...(context.hand.turnCards?.cards || []),
      ],
      river: [
        ...(context.hand.flopCards?.cards || []),
        ...(context.hand.turnCards?.cards || []),
        ...(context.hand.riverCards?.cards || []),
      ],
    }
  }, [context])

  function addActionElement(
    actions: HandAction[] | undefined,
    actionElements: React.ReactElement[],
    street: HandStreet,
    prevState?: HandState
  ) {
    let rident = 0
    if (!actions) return

    actions.forEach((action, idx) => {
      if (
        action.actionChar == "b" ||
        action.actionChar == "r" ||
        action.actionChar == "c" ||
        action.actionChar == "a"
      ) {
        rident++
      }
      actionElements.push(
        <GTAction
          key={idx}
          action={action}
          prevState={idx > 0 ? actions[idx - 1].state : prevState}
          onClick={() =>
            context.setCurrentActionIndex({ index: idx, street: street })
          }
          active={compareHandActionIndex(context.currentActionIndex, {
            index: idx,
            street: street,
          })}
          ident={rident}
          hero={context.hand.hero?.heroPosition == action.position}
        />
      )
    })
  }

  addActionElement(
    context.hand.preFlop?.actions,
    actions.preflop,
    "preflop",
    context.hand.beginningState
  )
  addActionElement(
    context.hand.flop?.actions,
    actions.flop,
    "flop",
    context.hand.preFlop?.actions.slice(-1)[0]?.state
  )
  addActionElement(
    context.hand.turn?.actions,
    actions.turn,
    "turn",
    context.hand.flop?.actions.slice(-1)[0]?.state
  )
  addActionElement(
    context.hand.river?.actions,
    actions.river,
    "river",
    context.hand.turn?.actions.slice(-1)[0]?.state
  )

  const startingPot = context.hand.forcedBetsState?.pot.toFixed(2)
  const endPreflopPot =
    context.hand.preFlop?.actions && context.hand.preFlop?.actions.length > 0
      ? context.hand.preFlop?.actions.slice(-1)[0]?.state.pot.toFixed(2)
      : startingPot

  const endFlopPot =
    context.hand.flop?.actions && context.hand.flop?.actions.length > 0
      ? context.hand.flop?.actions.slice(-1)[0]?.state.pot.toFixed(2)
      : endPreflopPot

  const endTurnPot =
    context.hand.turn?.actions && context.hand.turn?.actions.length > 0
      ? context.hand.turn?.actions.slice(-1)[0]?.state.pot.toFixed(2)
      : endFlopPot

  const endRiverPot =
    context.hand.river?.actions && context.hand.river?.actions.length > 0
      ? context.hand.river?.actions.slice(-1)[0]?.state.pot.toFixed(2)
      : endTurnPot

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <StreetView
          startPot={startingPot}
          endPot={endPreflopPot}
          street="preflop"
          actions={actions}
          cards={cards}
        />
        <StreetView
          startPot={endPreflopPot}
          endPot={endFlopPot}
          street="flop"
          actions={actions}
          cards={cards}
        />
        <StreetView
          startPot={endFlopPot}
          endPot={endTurnPot}
          street="turn"
          actions={actions}
          cards={cards}
        />
        <StreetView
          startPot={endTurnPot}
          endPot={endRiverPot}
          street="river"
          actions={actions}
          cards={cards}
        />
        <Box
          sx={{
            flexShrink: 0,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box className={classnames(`gtaction gtaction-a`)}>
            <Box sx={{ flexGrow: 1 }}>AllIn</Box>
          </Box>
          <Box className={classnames(`gtaction gtaction-r`)}>
            <Box sx={{ flexGrow: 1 }}>Raise/Bet</Box>
          </Box>
          <Box className={classnames(`gtaction gtaction-x`)}>
            <Box sx={{ flexGrow: 1 }}>Check</Box>
          </Box>
          <Box className={classnames(`gtaction gtaction-c`)}>
            <Box sx={{ flexGrow: 1 }}>Call</Box>
          </Box>
          <Box className={classnames(`gtaction gtaction-f`)}>
            <Box sx={{ flexGrow: 1 }}>Fold</Box>
          </Box>
        </Box>
      </Box>
      <GameFinishedView />
      <GameResults />
    </Box>
  )
}

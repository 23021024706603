import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import Typography from "@mui/material/Typography"
import { useAuthUser } from "features/auth/hooks"
import { PageTitle } from "lib/Components/PageTitile"
import { ChangeEvent, useEffect, useState } from "react"
import { Link, Route, Routes, useLocation } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useUpdateUserMutation } from "services/userApi"
import { Privacy } from "./Privacy"

export const Profile = () => {
  const profile = useAuthUser()
  const [nameError, setNameFieldError] = useState<string | undefined>()
  const [submitting, setSubmitting] = useState(false)
  const [inSync, setInSync] = useState(true)
  const [usernameInput, setUsernameInputValue] = useState<string>("")
  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsernameInputValue(event.target.value)
  }

  const [updateUser] = useUpdateUserMutation()

  useEffect(() => {
    if (profile?.username) {
      setUsernameInputValue(profile.username)
    }
  }, [profile?.username])

  useEffect(() => {
    if (!profile) return
    setInSync(profile.username == usernameInput)
  }, [profile, usernameInput])

  if (!profile) return <></>

  const handleSave = () => {
    if (!usernameInput) return

    setSubmitting(true)
    const id = toast.loading("Please wait...", {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    updateUser({
      username: usernameInput,
    })
      .unwrap()
      .then(() => {
        toast.info(`Updated`, {
          position: toast.POSITION.BOTTOM_CENTER,
        })
        setNameFieldError(undefined)
      })
      .catch((error) => {
        const handledError = error as {
          status?: number
          data?: Record<string, string>
        }
        if (handledError?.status == 400) {
          setNameFieldError(handledError?.data?.["username"])
        } else if (handledError?.status == 409) {
          setNameFieldError("Username is already taken")
        } else {
          toast.error(`Unexpected error. Please, try again later.`, {
            position: toast.POSITION.BOTTOM_CENTER,
          })
        }
      })
      .finally(() => {
        toast.dismiss(id)
        setSubmitting(false)
      })
  }

  return (
    <Container
      sx={{
        "&.MuiContainer-root": {
          marginLeft: "unset",
        },
      }}
    >
      <Grid container spacing={2} mt={1}>
        <Grid item xs={2}>
          <Typography variant="subtitle1">Avatar</Typography>
        </Grid>
        <Grid item xs={10}>
          <img src={profile.imageUrl} referrerPolicy="no-referrer" />
        </Grid>

        <Grid item xs={2}>
          <Typography variant="subtitle1">Email</Typography>
        </Grid>
        <Grid item xs={10}>
          {profile.email}
        </Grid>

        <Grid item xs={2}>
          <Typography variant="subtitle1">Name</Typography>
        </Grid>
        <Grid item xs={10}>
          {profile.name}
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle1">Username</Typography>
        </Grid>
        <Grid item xs={10}>
          {profile.username && <Box>{profile.username}</Box>}
          {!profile.username && (
            <TextField
              size="small"
              error={nameError !== undefined || usernameInput == ""}
              helperText={
                nameError ||
                (usernameInput == "" ? "You should setup username once!" : "")
              }
              placeholder="Pick username"
              value={usernameInput}
              onChange={handleUsernameChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">@</InputAdornment>
                ),
              }}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          <Button
            disabled={inSync || submitting}
            variant="contained"
            size={"small"}
            onClick={handleSave}
            sx={{
              "&.MuiButton-root": {
                textTransform: "none",
              },
            }}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export const ProfileController = () => {
  const path = useLocation()
  const [tab, setTab] = useState(path.pathname)

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
  }

  const PROFILE_TABS = [
    { label: "Details", route: "" },
    { label: "Privacy", route: "/privacy" },
    // { label: "Users", route: "/users" },
  ]

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <PageTitle text="Profile" />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              "& .MuiTab-root": {
                textTransform: "none",
              },
            }}
          >
            {PROFILE_TABS.map((v) => {
              return (
                <Tab
                  key={v.route}
                  label={v.label}
                  value={`/profile${v.route}`}
                  component={Link}
                  to={`.${v.route}`}
                />
              )
            })}
          </Tabs>
        </Box>
      </Box>
      <Routes>
        <Route path="" element={<Profile />} />
        <Route path="/privacy" element={<Privacy />} />
        {/* <Route path="/users" element={<Users />} /> */}
      </Routes>
      <ToastContainer autoClose={2000} />
    </Box>
  )
}

import { Box } from "@mui/material"
import { cardToEmoji } from "features/HandHistories/HandHistories"
import { Card, cardToString } from "features/types"
import { useContext, useMemo, useState } from "react"
import { CardSelectorDialog } from "./CardSelector"
import { hhContext } from "./HHContext"
import { useIsCurrentActionLast } from "./hooks"
import { setCommunityCard } from "./types"

export function CommunityCardsView() {
  const context = useContext(hhContext)
  const gameFinished = useIsCurrentActionLast()
  const [manageCard, openCard] = useState<number | undefined>(undefined)

  const cards: Card[] = useMemo(() => {
    if (gameFinished && !context.hand.lastState?.winner) {
      // winner not defined we need to show all cards to calculate winner
      return [
        ...(context.hand.flopCards?.cards || []),
        ...(context.hand.turnCards?.cards || []),
        ...(context.hand.riverCards?.cards || []),
      ]
    }
    switch (context.currentActionIndex.street) {
      case "flop":
        return context.hand.flopCards?.cards || []
      case "turn":
        return [
          ...(context.hand.flopCards?.cards || []),
          ...(context.hand.turnCards?.cards || []),
        ]
      case "river":
        return [
          ...(context.hand.flopCards?.cards || []),
          ...(context.hand.turnCards?.cards || []),
          ...(context.hand.riverCards?.cards || []),
        ]
    }
    return []
  }, [context, gameFinished])

  const placement = "poker-table-cc-above-pot"

  return (
    <div className={`${placement}`}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {cards.map((v, idx) => (
          <Box
            key={idx}
            onClick={() => openCard(idx)}
            className={`cardWrapper ${gameFinished ? "gameFinished" : ""}`}
          >
            {cardToEmoji(
              cardToString(v),
              gameFinished ? "gameFinishedCard" : "streetCard"
            )}
          </Box>
        ))}
        {manageCard !== undefined && (
          <CardSelectorDialog
            open={manageCard !== undefined}
            close={(newval) => {
              if (newval) {
                setCommunityCard(context, manageCard, {
                  value: newval[0],
                  suit: newval[1],
                })
              }
              openCard(undefined)
            }}
            card={cardToString(cards[manageCard])}
          />
        )}
      </Box>
    </div>
  )
}

import { Box } from "@mui/material"
import { HandSharingView } from "features/HandSharing"
import { PostflopRangeController } from "features/Ranges/postflop/PostflopRangeView"
import {
  PlayerInfo,
  PostflopTreeViewController,
} from "features/Ranges/postflop/simulation_tree/TreeView"
import { getPFRangePath } from "features/Ranges/utils"
import {
  AccordionInfo,
  AccordionLabel,
  AccordionWrapper,
} from "lib/Components/CustomAccordion"
import { PageTitle } from "lib/Components/PageTitile"
import { useCallback, useContext, useEffect, useMemo } from "react"
import { SimulationTreeInfoView } from "../Ranges/postflop/simulation_tree/SimulationTreeInfoView"
import { GameTreeView } from "./GameTreeView"
import { hhContext } from "./HHContext"
import { HHHeader } from "./HHHeader"
import { HHInfo } from "./HHInfo"
import { HHARangesView } from "./RangesView"
import { ScriptView } from "./ScriptView"
import { TableView } from "./TableView"
import { HandPlayerStateInfos, HandPosition } from "./types"

export function HHReplayView({ id }: { id: string | undefined }): JSX.Element {
  const context = useContext(hhContext)
  const pfRangePath = getPFRangePath(context)

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.target instanceof Element) {
        if (
          ["TEXTAREA", "INPUT"].indexOf((event.target as Element).tagName) >= 0
        )
          return
      }
      if (event.key == "ArrowRight") {
        context.moveToNextAction()
      }
      if (event.key == "ArrowLeft") {
        context.moveToPrevAction()
      }
    },
    [context]
  )

  useEffect(() => {
    window.addEventListener("keyup", handleKeyPress)
    return () => {
      window.removeEventListener("keyup", handleKeyPress)
    }
  }, [handleKeyPress])

  const flopPlayers: PlayerInfo[] = useMemo(() => {
    const flopPlayerState = context.hand.preFlop?.actions.slice(-1)[0].state
    if (!flopPlayerState) return []

    const heroPostition = context.hand.hero?.heroPosition
    const heroCards = context.hand.hero?.heroCards

    const inGamePlayers: HandPlayerStateInfos = {}
    Object.keys(flopPlayerState.playerStates).forEach((position) => {
      const playerState = flopPlayerState.playerStates[position as HandPosition]
      if (!playerState) return
      if (playerState.state == "IN_GAME") {
        inGamePlayers[position as HandPosition] = playerState
      }
    })
    const rv: PlayerInfo[] = []
    Object.keys(inGamePlayers).forEach((position) => {
      rv.push({
        position: position as HandPosition,
        initialStreetStack: inGamePlayers[position as HandPosition]?.stack || 0,
        streetBid: 0,
        streetDebt: 0,
        cards:
          position == heroPostition && heroCards !== undefined
            ? heroCards
            : undefined,
      })
    })
    return rv
  }, [context.hand.preFlop?.actions, context.hand.hero])

  return (
    <>
      <PageTitle text="Hand Analyzer" />
      {context.hand.id && (
        <>
          <AccordionWrapper defaultExpanded={true}>
            <AccordionLabel>Hand Information</AccordionLabel>
            <AccordionInfo>
              <HHInfo />
            </AccordionInfo>
          </AccordionWrapper>
          <AccordionWrapper>
            <AccordionLabel>Hand Sharing</AccordionLabel>
            <AccordionInfo>
              <HandSharingView handle={context.hand.id} />
            </AccordionInfo>
          </AccordionWrapper>
        </>
      )}
      <HHHeader />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflowY: "scroll",
          overflowX: "hidden",
          width: { xs: "100vw", sm: "100vw", md: "auto" },
        }}
      >
        <AccordionWrapper>
          <AccordionLabel>Script</AccordionLabel>
          <AccordionInfo>
            <ScriptView />
          </AccordionInfo>
        </AccordionWrapper>
        <AccordionWrapper>
          <AccordionLabel>Game Tree</AccordionLabel>
          <AccordionInfo>
            <GameTreeView />
          </AccordionInfo>
        </AccordionWrapper>
        <AccordionWrapper defaultExpanded={true}>
          <AccordionLabel>Table</AccordionLabel>
          <AccordionInfo>
            <TableView />
          </AccordionInfo>
        </AccordionWrapper>
        {id && context.hand.hasSimulation && (
          <AccordionWrapper defaultExpanded={false}>
            <AccordionLabel>Simulation parameters</AccordionLabel>
            <AccordionInfo>
              <SimulationTreeInfoView hhId={id} />
            </AccordionInfo>
          </AccordionWrapper>
        )}
        <AccordionWrapper defaultExpanded={true}>
          <AccordionLabel>Range Explorer</AccordionLabel>
          <AccordionInfo>
            <Box
              sx={{ display: "flex", overflowX: "scroll", overflowY: "hidden" }}
            >
              {context.currentActionIndex.street == "preflop" && (
                <HHARangesView skipNotSuggested={false} />
              )}
              {context.hand.hasSimulation &&
                context.currentActionIndex.street !== "preflop" &&
                pfRangePath !== undefined &&
                id && <PostflopRangeController id={id} path={pfRangePath} />}
            </Box>
          </AccordionInfo>
        </AccordionWrapper>
        {id && context.hand.hasSimulation && (
          <AccordionWrapper defaultExpanded={false}>
            <AccordionLabel>Postflop Tree</AccordionLabel>
            <AccordionInfo>
              {/* <SimulationTreeInfoView hhId={id} /> */}
              <PostflopTreeViewController
                id={id}
                path={pfRangePath || ""}
                initialStreetPot={
                  context.hand.preFlop?.actions.slice(-1)[0].state.pot || 0
                }
                players={flopPlayers}
              />
            </AccordionInfo>
          </AccordionWrapper>
        )}
      </Box>
    </>
  )
}

import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import type { RootState } from "app/store"
import { setCredentials } from "features/auth/authSlice"
import { BetSizing } from "features/BetSizing/BetSizing"
import { ProfileController } from "features/profile/Profile"
import { Ranges } from "features/Ranges/preflop/Ranges"
import { useTheme } from "lib/useTheme"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import { useProfileQuery } from "services/userApi"
import { LeftNavigation } from "../adminapp/LeftNavigation"
import "./App.css"
import { TopBar } from "./TopBar"

const NotFound = () => {
  return <>Page not found</>
}
function AuthenticatedApp() {
  const theme = useTheme()
  const navigate = useNavigate()
  const user = useSelector((state: RootState) => state.auth.user)
  useEffect(() => {
    if (!user) {
      let locationUrl = location.pathname
      if (location.search) locationUrl += location.search
      const redirect = encodeURIComponent(locationUrl)
      navigate(`/signin/?redirect=${redirect}`)
    }
  }, [navigate, user])

  if (!user) return null

  if (user && !user.systemAdmin) {
    return <div>You do not have access to this application</div>
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        className="full-height"
        sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
      >
        <TopBar user={user} />

        <Box sx={{ flexGrow: 1, display: "flex", overflow: "hidden" }}>
          <LeftNavigation />

          <Box
            component="main"
            sx={{ flexGrow: 1, p: { xs: 1, sm: 2 }, display: "flex" }}
          >
            <Routes>
              <Route path="/" element={<Navigate to="/ranges" replace />} />
              <Route path="/profile" element={<ProfileController />} />
              <Route path="/ranges" element={<Ranges manageable={true} />} />
              <Route path="/betsizing" element={<BetSizing admin={true} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

function AdminApp() {
  const dispatch = useDispatch()

  const {
    isFetching: userIsFetching,
    data: user,
    error: userError,
    refetch,
  } = useProfileQuery()
  const navigate = useNavigate()

  const authuser = useSelector((state: RootState) => state.auth.user)

  useEffect(() => {
    if (!authuser) refetch()
  }, [authuser, refetch])

  useEffect(() => {
    if (
      (!userIsFetching && !user) ||
      (userError && "status" in userError && userError.status === 403)
    ) {
      navigate("/signin/")
    }
    if (user) dispatch(setCredentials({ user }))
  }, [navigate, user, userError, userIsFetching, dispatch])

  if (!user) return null

  return <AuthenticatedApp />
}

export default AdminApp

import { Box } from "@mui/material"
import TextareaAutosize from "@mui/material/TextareaAutosize"
import { useContext, useRef } from "react"
import { HandActionIndex, hhContext } from "./HHContext"
import {
  actionForPosition,
  actionPosition,
  contextActionForPosition,
  Hand,
  handFromString,
} from "./types"

export function ScriptView() {
  const context = useContext(hhContext)
  const inputEl = useRef<HTMLTextAreaElement>(null)

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const hand: Hand = handFromString(
      context.hand.id,
      e.target.value.substring(0, inputEl.current?.selectionEnd),
      context.hand.nicknames
    )
    context.setHand(hand)
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!inputEl.current?.selectionStart) {
      return
    }
    if (e.key === "Tab") {
      const action = actionForPosition(
        context.hand.stringRepresentation,
        inputEl.current.selectionStart
      ) as HandActionIndex
      if (action) {
        e.preventDefault()
        const next = e.shiftKey
          ? context.moveToPrevAction(true)
          : context.moveToNextAction(true)
        if (!next) return
        const offset = actionPosition(context, next)
        if (offset) {
          inputEl.current.selectionStart = offset
          inputEl.current.selectionEnd = offset
        }
      }
    } else if (!e.metaKey) {
      contextActionForPosition(context, inputEl.current.selectionStart)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!inputEl.current?.selectionStart) {
      return
    }
    if (e.key === "Tab") {
      const action = actionForPosition(
        context.hand.stringRepresentation,
        inputEl.current.selectionStart
      ) as HandActionIndex
      if (action) {
        e.preventDefault()
      }
    }
  }

  const handleMouse = () => {
    if (!inputEl.current?.selectionStart) {
      return
    }
    contextActionForPosition(context, inputEl.current.selectionStart)
  }

  const hand: Hand = context.hand
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TextareaAutosize
        ref={inputEl}
        className="scripted-hand"
        aria-label="minimum height"
        minRows={3}
        placeholder="Hand defintion"
        style={{ width: "100%" }}
        value={hand.stringRepresentation}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onKeyDown={handleKeyDown}
        onClick={handleMouse}
      />
      <Box sx={{ color: "red", fontSize: "12px" }}>
        {hand.playersCount?.errors && (
          <div>{hand.playersCount?.errors.join(". ")}</div>
        )}
        {hand.beginningState?.errors && (
          <div>{hand.beginningState?.errors.join(". ")}</div>
        )}
        {hand.forcedBetsState?.errors && (
          <div>{hand.forcedBetsState.errors.join(". ")}</div>
        )}
        {hand.preFlop?.errors && <div>{hand.preFlop.errors.join(". ")}</div>}
        {hand.flopCards?.errors && (
          <div>{hand.flopCards.errors.join(". ")}</div>
        )}
        {hand.flop?.errors && <div>{hand.flop.errors.join(". ")}</div>}
        {hand.turnCards?.errors && (
          <div>{hand.turnCards.errors.join(". ")}</div>
        )}
        {hand.turn?.errors && <div>{hand.turn.errors.join(". ")}</div>}
        {hand.riverCards?.errors && (
          <div>{hand.riverCards.errors.join(". ")}</div>
        )}
        {hand.river?.errors && <div>{hand.river.errors.join(". ")}</div>}
        {hand.showDownSection?.errors && (
          <div>{hand.showDownSection.errors.join(". ")}</div>
        )}
      </Box>
    </Box>
  )
}

import { HHContext } from "features/HHReplay/HHContext"
import { RangeInfo } from "services/types"
import { Cards, cardsToString, Suits } from "../types"
import { Action, ActionRatios } from "./types"

export function getCellId(row: number, cell: number): string {
  if (row > cell) {
    return Cards[cell] + Cards[row] + "o"
  } else if (row < cell) {
    return Cards[row] + Cards[cell] + "s"
  }
  return Cards[row] + Cards[cell]
}
function getActionRatio(
  ratio: ActionRatios,
  action: Action,
  filterAction: Action | undefined,
  fillByFilterAction: boolean | undefined
): number {
  if (filterAction && filterAction !== action) return 0
  const actionRatio = ratio[action]
  if (actionRatio === undefined) return 0
  if (
    filterAction &&
    filterAction == action &&
    fillByFilterAction &&
    actionRatio > 0
  )
    return 100
  return actionRatio > 0 ? actionRatio : 0
}

export function checkRatio(ratio: ActionRatios): {
  valid: boolean
  totalPercent: number
} {
  let total = 0
  for (const action in ratio) {
    const percent = ratio[action as Action]
    total += Math.trunc((percent || 0) * 100)
  }
  // if (total > 100) {
  //   console.warn(`bad ${total} ${ratio}`)
  // }
  return {
    totalPercent: total,
    valid: !(total > 100),
  }
}

export function toPercent(val: number): string {
  return (val * 100).toFixed(0)
}

export function getCellBackground(
  ratio: ActionRatios | undefined,
  filterAction: Action | undefined,
  scaleWrongRatios: boolean | undefined,
  fillByFilterAction: boolean | undefined
): string {
  if (ratio === undefined || ratio === null) {
    return "linear-gradient(#444444 0%, #444444 100%)"
  }

  const { valid, totalPercent: total } = checkRatio(ratio)
  let scale = 100
  if (!valid) {
    scale = (100 * 100) / total
    if (!scaleWrongRatios) return ""
  }

  let percent = 0
  let result = "linear-gradient(to right, "

  const allInPercent: number = getActionRatio(
    ratio,
    "ALL_IN",
    filterAction,
    fillByFilterAction
  )
  if (allInPercent) {
    const newPercent = percent + allInPercent * scale
    result = result + "DarkRed " + percent + "%, DarkRed " + newPercent + "%"
    percent = newPercent
  }

  const raisePercent: number = getActionRatio(
    ratio,
    "RAISE",
    filterAction,
    fillByFilterAction
  )
  if (raisePercent) {
    if (percent > 0) {
      result = result + ", "
    }
    const newPercent = percent + raisePercent * scale
    result = result + "Orange " + percent + "%, Orange " + newPercent + "%"
    percent = newPercent
  }

  const callPercent: number = getActionRatio(
    ratio,
    "CALL",
    filterAction,
    fillByFilterAction
  )
  if (callPercent) {
    if (percent > 0) {
      result = result + ", "
    }
    const newPercent = percent + callPercent * scale
    result = result + "SeaGreen " + percent + "%, SeaGreen " + newPercent + "%"
    percent = newPercent
  }

  const foldPercent: number = getActionRatio(
    ratio,
    "FOLD",
    filterAction,
    fillByFilterAction
  )
  if (foldPercent) {
    if (percent > 0) {
      result = result + ", "
    }
    const newPercent = percent + foldPercent * scale
    result = result + "DarkGray " + percent + "%, DarkGray " + newPercent + "%"
    percent = newPercent
  }

  if (percent < 100) {
    if (percent > 0) {
      result = result + ", "
    }
    result = result + "#444444 " + percent + "%, #444444 100%"
  }
  result = result + ")"
  return result
}

export function getDefinedActions(range: RangeInfo, skipNotSuggested: boolean) {
  const rv = new Set<Action>()
  if (!skipNotSuggested) {
    rv.add("ALL_IN")
    rv.add("CALL")
    rv.add("FOLD")
    rv.add("RAISE")
  } else {
    Object.keys(range.operationCombos || {}).forEach((v) => {
      const combos = range.operationCombos ? range.operationCombos[v] : 0
      if (combos > 0) rv.add(v as Action)
    })
  }
  return Array.from(rv).sort()
}

export function getAvailableActions(): Action[] {
  const rv = new Set<Action>()
  rv.add("ALL_IN")
  rv.add("RAISE")
  rv.add("CALL")
  rv.add("FOLD")
  return Array.from(rv)
}

export function validPIOCombo(combo: string): {
  valid: boolean
  combos?: string[]
} {
  // AA, KTo, 53s, 53, ...
  if (combo.length != 2 && combo.length != 3) return { valid: false }
  if (combo.length == 2) {
    if (Cards.indexOf(combo[0]) < 0 || Cards.indexOf(combo[1]) < 0)
      return { valid: false }
    if (combo[0] != combo[1]) {
      return { valid: true, combos: [`${combo}o`, `${combo}s`] }
    }
  }

  if (combo.length == 3) {
    if (combo[0] == combo[1]) return { valid: false }
    if (combo[2] != "o" && combo[2] != "s") return { valid: false }
    if (Cards.indexOf(combo[0]) < 0 || Cards.indexOf(combo[1]) < 0)
      return { valid: false }
  }

  return { valid: true, combos: [combo] }
}

export function validMonkerCombo(combo: string): {
  valid: boolean
  combos?: string[]
} {
  // AsJs,...
  if (combo.length != 4) return { valid: false }
  if (Cards.indexOf(combo[0]) < 0 || Cards.indexOf(combo[2]) < 0)
    return { valid: false }

  // suit in [d|h|c|s]
  if (Suits.indexOf(combo[1]) < 0 || Suits.indexOf(combo[3]) < 0)
    return { valid: false }

  if (combo[0] == combo[2]) {
    return { valid: true, combos: [`${combo[0]}${combo[0]}`] }
  }

  if (combo[1] == combo[3]) {
    // same suit
    return { valid: true, combos: [`${combo[0]}${combo[2]}s`] }
  } else {
    return { valid: true, combos: [`${combo[0]}${combo[2]}o`] }
  }
}

export function getPFCellBackground(
  ratio: number,
  none_color?: string,
  fill_color?: string
): string {
  const none_bgcolor = none_color || "#999999"
  const fill_bgcolor = fill_color || "yellow"
  if (ratio === undefined || ratio === null) {
    return `linear-gradient(${none_bgcolor} 0%, ${none_bgcolor} 100%)`
  }

  const scale = 100
  let percent = 0
  let result = "linear-gradient(to top, "

  const allInPercent: number = ratio
  if (allInPercent) {
    const newPercent = percent + allInPercent * scale
    result =
      result +
      `${fill_bgcolor} ` +
      percent +
      `%, ${fill_bgcolor} ` +
      newPercent +
      "%"
    percent = newPercent
  }

  if (percent < 100) {
    if (percent > 0) {
      result = result + ", "
    }
    result = result + `${none_bgcolor} ` + percent + `%, ${none_bgcolor} 100%`
  }
  result = result + ")"
  return result
}

export function getPFRangePath(context: HHContext): string | undefined {
  if (
    context.currentActionIndex === undefined ||
    context.currentActionIndex.street == "preflop"
  )
    return

  let result = ""
  if (
    context.hand.flop &&
    (context.currentActionIndex.street == "flop" ||
      context.currentActionIndex.street == "turn" ||
      context.currentActionIndex.street == "river")
  ) {
    result += context.hand.flop.actions
      .slice(
        0,
        context.currentActionIndex.street == "flop"
          ? context.currentActionIndex.index
          : context.hand.flop.actions.length
      )
      .map(
        (action) =>
          `${action.actionChar}${
            action.raisingAmount ? action.raisingAmount : ""
          }`
      )
      .join(",")
  }

  if (
    context.hand.turnCards &&
    context.hand.turn &&
    (context.currentActionIndex.street == "turn" ||
      context.currentActionIndex.street == "river")
  ) {
    result += ","
    result += cardsToString(context.hand.turnCards.cards)

    if (
      context.currentActionIndex.street != "turn" ||
      context.currentActionIndex.index > 0
    ) {
      result += ","
    }

    result += context.hand.turn.actions
      .slice(
        0,
        context.currentActionIndex.street == "turn"
          ? context.currentActionIndex.index
          : context.hand.turn.actions.length
      )
      .map(
        (action) =>
          `${action.actionChar}${
            action.raisingAmount ? action.raisingAmount : ""
          }`
      )
      .join(",")
  }

  if (
    context.hand.riverCards &&
    context.hand.river &&
    context.currentActionIndex.street == "river"
  ) {
    result += ","
    result += cardsToString(context.hand.riverCards.cards)
    if (context.currentActionIndex.index > 0) {
      result += ","
    }

    result += context.hand.river.actions
      .slice(
        0,
        context.currentActionIndex.street == "river"
          ? context.currentActionIndex.index
          : context.hand.river.actions.length
      )
      .map(
        (action) =>
          `${action.actionChar}${
            action.raisingAmount ? action.raisingAmount : ""
          }`
      )
      .join(",")
  }

  return result
}

import { Box, ButtonProps, useMediaQuery, useTheme } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import { styled } from "@mui/system"
import { useContext, useRef, useState } from "react"
import {
  getActionString,
  getCurrentAction,
  getPrevPositionState,
} from "./ActionPointer"
import { hhContext } from "./HHContext"
import { useGetPositionRange } from "./hooks"
import {
  HandAction,
  HandActionChar,
  handFromString,
  HandPlayerStateInfo,
  HandState,
  handToString,
  stripHandToSection,
} from "./types"
import { getNextAction } from "./utils"

export const ActionButton = styled((props: ButtonProps) => (
  <Box sx={{ p: "2px" }}>
    <Button variant="contained" {...props} />
  </Box>
))((props) => ({
  textTransform: "none",
  "&.MuiButton-root": {
    fontSize: "10px",
    minWidth: { sm: "50px", md: "80px" },
    maxWidth: { sm: "50px", md: "80px" },
    minHeight: "40px",
    borderRadius: "20px",
    whiteSpace: "nowrap",
    flexDirection: "column",
    padding: "0px",
    [props.theme.breakpoints.down("sm")]: {
      fontSize: "8px",
      minWidth: "50px",
      maxWidth: "50px",
      minHeight: "30px",
      borderRadius: "15px",
    },
  },
  "&.MuiButton-root.MuiButton-contained": {
    backgroundColor: "#35654d",
  },
}))

export function CurrentTurnActions() {
  const context = useContext(hhContext)
  const action = getCurrentAction(context)
  const [manage, openManageDialog] = useState(false)
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"))

  const stackSizeInput = useRef<HTMLInputElement>(null)

  const range = useGetPositionRange(
    action?.position || "SB",
    context.currentActionIndex
  )
  const recommendedRaise = range?.raiseSizing || 0
  if (!action) return null

  const curentPlayerState: HandPlayerStateInfo | undefined =
    getPrevPositionState(context, context.currentActionIndex, action.position)
  const actionPointer = context.currentActionIndex

  if (curentPlayerState === undefined) return null

  const changeHandAction = (action: HandAction) => {
    let hhString = handToString(context.hand)
    switch (actionPointer.street) {
      case "preflop":
        if (!context.hand.preFlop?.actions) return
        context.hand.preFlop.actions[actionPointer.index] = action
        context.hand.preFlop.actions.splice(actionPointer.index + 1)
        hhString = stripHandToSection("PREFLOP_ACTIONS", context.hand)
        break
      case "flop":
        if (!context.hand.flop?.actions) return
        context.hand.flop.actions[actionPointer.index] = action
        context.hand.flop.actions.splice(actionPointer.index + 1)
        hhString = stripHandToSection("FLOP_ACTIONS", context.hand)
        break
      case "turn":
        if (!context.hand.turn?.actions) return
        context.hand.turn.actions[actionPointer.index] = action
        context.hand.turn.actions.splice(actionPointer.index + 1)
        hhString = stripHandToSection("TURN_ACTIONS", context.hand)
        break
      case "river":
        if (!context.hand.river?.actions) return
        context.hand.river.actions[actionPointer.index] = action
        context.hand.river.actions.splice(actionPointer.index + 1)
        hhString = stripHandToSection("RIVER_ACTIONS", context.hand)
        break
    }
    const newHand = handFromString(context.hand.id, hhString)
    context.setHand(newHand)

    const next = getNextAction(context.currentActionIndex, newHand)
    if (next) {
      context.setCurrentActionIndex(next)
    }
  }

  function changeTo(action: HandAction, chr: HandActionChar, amount?: number) {
    if (action.actionChar == chr) {
      if (!amount || amount == action.raisingAmount) context.moveToNextAction()
    } else {
      changeHandAction({
        actionChar: chr,
        actionString: getActionString(chr),
        position: action.position,
        raisingAmount: amount || 0,
        state: action.state as HandState,
        isManual: true,
      } as HandAction)
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      <ActionButton onClick={() => changeTo(action, "f")}>Fold</ActionButton>
      {curentPlayerState.debt > 0 && (
        <ActionButton onClick={() => changeTo(action, "c")}>Call</ActionButton>
      )}
      {curentPlayerState.debt == 0 && (
        <ActionButton onClick={() => changeTo(action, "x")}>Check</ActionButton>
      )}
      {curentPlayerState.debt > 0 && (
        <>
          {recommendedRaise > 0 && (
            <ActionButton
              onClick={() => changeTo(action, "r", range?.raiseSizing || 0)}
              disabled={(range?.raiseSizing || 0) <= 0}
            >
              <Box>Raise</Box>
              <Box>{recommendedRaise}BB</Box>
            </ActionButton>
          )}
          <ActionButton onClick={() => openManageDialog(true)}>
            <Box>Raise</Box>
            <Box>???BB</Box>
          </ActionButton>
        </>
      )}
      {curentPlayerState.debt == 0 && (
        <>
          {recommendedRaise > 0 && (
            <ActionButton
              onClick={() => changeTo(action, "b", range?.raiseSizing || 0)}
              disabled={(range?.raiseSizing || 0) <= 0}
            >
              <Box>Bet</Box>
              <Box>{recommendedRaise}BB (range)</Box>
            </ActionButton>
          )}
          <ActionButton onClick={() => openManageDialog(true)}>
            <Box>Bet</Box>
            <Box>???BB</Box>
          </ActionButton>
        </>
      )}
      <ActionButton onClick={() => changeTo(action, "a")}>All In</ActionButton>
      {manage && (
        <Dialog open={manage} fullScreen={mobileView}>
          <DialogTitle>{`Set raise for ${action.position}`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To manage player raise amount, set value below and click
              &apos;Save&apos;
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="raiseamount"
              label="Raise amount"
              type="number"
              fullWidth
              variant="standard"
              inputRef={stackSizeInput}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => openManageDialog(false)}>Cancel</Button>
            <Button
              onClick={() => {
                // TODO, wrong raise vs bet also should be on postflop non first raise?
                if (stackSizeInput.current) {
                  if (actionPointer.street == "preflop")
                    changeTo(
                      action,
                      "r",
                      parseFloat(stackSizeInput.current.value)
                    )
                  else
                    changeTo(
                      action,
                      "b",
                      parseFloat(stackSizeInput.current.value)
                    )
                }
                openManageDialog(false)
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  )
}

import { api } from "./api"
import {
  HandHistory,
  HandHistoryDetails,
  HandShareRequest,
  SearchHandsParams,
  SearchHandsResponse,
  SharedHandConfig,
} from "./types"

const handHistoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    handHistory: builder.query<HandHistory[], void>({
      query: () => "api/hand-history",
      providesTags: ["HandHistory"],
    }),

    searchHands: builder.query<SearchHandsResponse, SearchHandsParams>({
      query: (filter) => ({
        url: "api/hand-history/search",
        params: filter,
      }),
      providesTags: ["HandHistory"],
    }),

    handHistoryDetails: builder.query<HandHistoryDetails, string | undefined>({
      query: (id: string | undefined) => ({
        url: `api/hand-history/${id}`,
      }),
      providesTags: ["HandHistoryDetails"],
    }),

    removeHand: builder.mutation<void, string | number>({
      query: (handle: string) => ({
        url: `/api/hand-history/${handle}`,
        method: "delete",
      }),
      invalidatesTags: ["HandHistory"],
    }),

    removeHands: builder.mutation<void, (string | number)[]>({
      query: (body) => ({
        url: `/api/hand-history/delete`,
        method: "post",
        body,
      }),
      invalidatesTags: ["HandHistory"],
    }),

    getHandSharingConfig: builder.query<SharedHandConfig, string>({
      query: (handle: string) => ({
        url: `/api/hand-history/${handle}/share`,
      }),
      providesTags: ["SharedHandConfig"],
    }),

    shareHand: builder.mutation<
      void,
      { handle: string; config: HandShareRequest }
    >({
      query: ({ handle, config }) => ({
        url: `/api/hand-history/${handle}/share`,
        method: "post",
        body: config,
      }),
      invalidatesTags: ["SharedHandConfig"],
    }),

    removeHandSharing: builder.mutation<void, string>({
      query: (handle: string) => ({
        url: `/api/hand-history/${handle}/share`,
        method: "delete",
      }),
      invalidatesTags: ["SharedHandConfig"],
    }),
  }),
})

export const {
  useHandHistoryQuery,
  useHandHistoryDetailsQuery,
  useSearchHandsQuery,
  useGetHandSharingConfigQuery,
  useShareHandMutation,
  useRemoveHandSharingMutation,
  useRemoveHandMutation,
  useRemoveHandsMutation,
} = handHistoryApi

import GradeIcon from "@mui/icons-material/Grade"
import { Box, useMediaQuery } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import classnames from "classnames"
import { cardToEmoji } from "features/HandHistories/HandHistories"
import { useTheme } from "lib/useTheme"
import { useMemo, useRef, useState } from "react"
import { Card, cardToString, PositionLabels } from "../types"
import { getCurrentAction } from "./ActionPointer"
import { CardSelectorDialog } from "./CardSelector"
import { HHContext } from "./HHContext"
import { useIsCurrentActionLast } from "./hooks"
import {
  handFromString,
  HandPlayerStateInfo,
  HandPosition,
  setHandPositionCards,
  stripHandToSection,
} from "./types"

export interface SeatProps {
  n: number
  position: HandPosition
  stackSize?: number
  betSum?: number
  dealer?: boolean
  hero?: boolean
  cards: Card[]
  playerState?: HandPlayerStateInfo
  context: HHContext
}

export function TableSeat(props: SeatProps) {
  const {
    n,
    position,
    stackSize,
    dealer,
    hero,
    cards,
    betSum,
    playerState: state,
    context,
  } = props

  const [manage, openManageDialog] = useState(false)
  const stackSizeInput = useRef<HTMLInputElement>(null)

  const [manageCard0, openCard0] = useState(false)
  const [manageCard1, openCard1] = useState(false)

  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"))
  const gameFinished = useIsCurrentActionLast()
  const winner =
    gameFinished &&
    context.hand.lastState?.winner &&
    position == context.hand.lastState?.winner

  const candidate =
    gameFinished &&
    !context.hand.lastState?.winner &&
    state?.state != "OUT_OF_GAME"

  const playerCards = useMemo(() => {
    if (cards.length) return cards

    if (gameFinished && candidate && context.hand?.showDownSection) {
      return context.hand?.showDownSection[position]
    }
    return cards
  }, [cards, gameFinished, context, position, candidate])

  const action = getCurrentAction(context)
  const activePosition =
    context.currentActionIndex.street == "preflop" &&
    (action?.position == position || action === undefined)

  const shouldAct = !state?.actionIsMade || state.debt > 0

  const nick = context.hand?.nicknames
    ? context.hand?.nicknames[position]
    : undefined

  return (
    <Box
      className={`${dealer ? `btn seat-${n}-btn` : ""} ${
        hero ? " hero" : ""
      } seat-${n} seat`}
    >
      {playerCards && (
        <Box className={`seat-${n}-cards`} sx={{ display: "flex" }}>
          {playerCards[0] && (
            <>
              <Box onClick={() => openCard0(true)}>
                {cardToEmoji(cardToString(playerCards[0]), "tableCard")}
              </Box>
              {manageCard0 && (
                <CardSelectorDialog
                  open={manageCard0}
                  close={(newval) => {
                    openCard0(false)
                    if (newval) {
                      playerCards[0] = { value: newval[0], suit: newval[1] }
                      setHandPositionCards(context, position, playerCards)
                    }
                  }}
                  card={cardToString(playerCards[0])}
                />
              )}
            </>
          )}
          {playerCards[1] && (
            <>
              <Box onClick={() => openCard1(true)}>
                {cardToEmoji(cardToString(playerCards[1]), "tableCard")}
              </Box>
              {manageCard1 && (
                <CardSelectorDialog
                  open={manageCard1}
                  close={(newval) => {
                    openCard1(false)
                    if (newval) {
                      playerCards[1] = { value: newval[0], suit: newval[1] }
                      setHandPositionCards(context, position, playerCards)
                    }
                  }}
                  card={cardToString(playerCards[1])}
                />
              )}
            </>
          )}
        </Box>
      )}
      {playerCards?.length == 0 && (
        <Box className={`seat-${n}-cards`} sx={{ display: "flex" }}>
          <Box sx={{ color: "lightgray" }} className="tableCard">
            {"\uD83C\uDCA0"}
          </Box>
          <Box sx={{ color: "lightgray" }} className="tableCard">
            {"\uD83C\uDCA0"}
          </Box>
        </Box>
      )}
      <Box
        className={classnames(`seatinfo state-${state?.state}`, {
          ["winner"]: winner,
          ["candidate"]: candidate,
          ["acting"]: activePosition,
          ["shouldact"]: shouldAct,
        })}
        sx={{
          position: "absolute",
          left: "0%",
          top: "0%",
          width: "100%",
          height: "100%",
        }}
      >
        <Box sx={{ fontWeight: "600" }}>{PositionLabels[position]}</Box>
        <div onClick={() => openManageDialog(true)}>
          {stackSize !== undefined ? Number(stackSize.toFixed(2)) : "?"} BB
        </div>

        <Box
          className={`seat-nick seat-${n}-nick`}
          sx={{ maxWidth: { xs: "50px", sm: "75px" } }}
        >
          {nick}
        </Box>
        {betSum !== undefined && betSum > 0 && (
          <div className={`seat-${n}-bet seat-bet`}>
            {Number(betSum.toFixed(2))} BB
          </div>
        )}
        {hero && (
          <Box className="hero icon" sx={{ color: "gold" }}>
            <GradeIcon sx={{ width: "100%", height: "100%" }} />
          </Box>
        )}
      </Box>{" "}
      {manage && (
        <Dialog open={manage} fullScreen={mobileView}>
          <DialogTitle>{`Change player ${position} ${
            nick ? "(" + nick + ")" : ""
          }`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To manage player, fill information below and click
              &apos;Save&apos;
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="stacksize"
              label="Stack size"
              type="number"
              fullWidth
              variant="standard"
              defaultValue={state?.stack}
              inputRef={stackSizeInput}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => openManageDialog(false)}>Cancel</Button>
            <Button
              onClick={() => {
                if (
                  stackSizeInput.current &&
                  context.hand.beginningState &&
                  context.hand.beginningState.playerStates &&
                  context.hand.beginningState.playerStates[position] &&
                  state
                ) {
                  openManageDialog(false)
                  const stateToModify =
                    context.hand.beginningState.playerStates[position]
                  if (!stateToModify) return
                  stateToModify.stack = parseFloat(stackSizeInput.current.value)
                  const hhString = stripHandToSection(
                    "STACK_SIZES",
                    context.hand
                  )
                  context.setHand(handFromString(context.hand.id, hhString))
                }
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  )
}

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { HandPosition } from "features/HHReplay/types"
import { HERO_POSITION, RangeParameters } from "../types"
import { ActionKind } from "./Ranges"

interface PositionFilterProps {
  handleChange: (field: ActionKind, value: string | HandPosition) => void
  state: RangeParameters
  positions: HERO_POSITION[]
}

export function PositionFilter(props: PositionFilterProps) {
  const { state, handleChange, positions } = props
  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Box>Hero Position</Box>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {positions.map((positionInfo) => (
          <Box
            key={positionInfo.position}
            sx={{
              mr: "10px",
              mt: "10px",
            }}
          >
            <Button
              sx={{
                textTransform: "none",
                "&.MuiButton-root": {
                  fontSize: "10px",
                  minWidth: "40px",
                  maxWidth: "40px",
                  minHeight: "40px",
                  borderRadius: "50%",
                  whiteSpace: "nowrap",
                },
                "&.MuiButton-root.MuiButton-contained": {
                  backgroundColor: "darkcyan",
                },
              }}
              variant={
                state.heroPosition !== positionInfo.position
                  ? "outlined"
                  : "contained"
              }
              disabled={!positionInfo.enabled}
              onClick={() =>
                handleChange("heroPosition", positionInfo.position)
              }
            >
              {positionInfo.label}
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "app/store"
import { User } from "services/types"

const slice = createSlice({
  name: "auth",
  initialState: { user: null } as {
    user: null | User
  },
  reducers: {
    setCredentials: (
      state,
      { payload: { user } }: PayloadAction<{ user: User }>
    ) => {
      state.user = user
    },
    logout: (state) => {
      state.user = null
    },
  },
})

export const { setCredentials, logout } = slice.actions

export default slice.reducer

export const selectCurrentUser = (state: RootState) => state.auth.user

import { Button, Container, Grid } from "@mui/material"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { PrivacyVisibility } from "services/types"
import {
  usePrivacySettingsQuery,
  useSetPrivacySettingsMutation,
} from "services/userApi"
// import { VisibilityControl } from "./Profile"
import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"

export const VisibilityControl = ({
  value,
  onChange,
}: {
  value: PrivacyVisibility
  onChange: (value: PrivacyVisibility) => void
}) => {
  return (
    <FormControl sx={{ minWidth: "150px" }} size="small">
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={(v) => onChange(v.target.value as PrivacyVisibility)}
      >
        <MenuItem value={"PRIVATE"}>Private</MenuItem>
        <MenuItem value={"PUBLIC"}>Public</MenuItem>
        <MenuItem value={"FRIENDS"}>Friends</MenuItem>
      </Select>
    </FormControl>
  )
}

export function Privacy() {
  const privacySettings = usePrivacySettingsQuery()
  const [submitting, setSubmitting] = useState(false)
  const [nameVisibility, setNameVisibility] =
    useState<PrivacyVisibility>("PRIVATE")
  const [emailVisibility, setEmailVisibility] =
    useState<PrivacyVisibility>("PRIVATE")
  const [followersVisibility, setFollowersVisibility] =
    useState<PrivacyVisibility>("PRIVATE")
  const [followingVisibility, setFollowingVisibility] =
    useState<PrivacyVisibility>("PRIVATE")
  const [memberSinceVisibility, setMemberSinceVisibility] =
    useState<PrivacyVisibility>("PRIVATE")
  const [inSync, setInSync] = useState(true)

  useEffect(() => {
    if (!privacySettings) return

    const inSync =
      nameVisibility == privacySettings.data?.name &&
      followersVisibility == privacySettings.data?.followers &&
      followingVisibility == privacySettings.data?.following &&
      memberSinceVisibility == privacySettings.data?.member_since &&
      emailVisibility == privacySettings.data.email

    setInSync(inSync)
  }, [
    privacySettings,
    nameVisibility,
    emailVisibility,
    followersVisibility,
    followingVisibility,
    memberSinceVisibility,
  ])

  useEffect(() => {
    if (!privacySettings.data) return
    setNameVisibility(privacySettings.data.name)
    setEmailVisibility(privacySettings.data.email)
    setFollowersVisibility(privacySettings.data.followers)
    setFollowingVisibility(privacySettings.data.following)
    setMemberSinceVisibility(privacySettings.data.member_since)
  }, [privacySettings])

  const [updateSettings] = useSetPrivacySettingsMutation()

  const handleSave = () => {
    setSubmitting(true)
    const id = toast.loading("Please wait...", {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    updateSettings({
      name: nameVisibility,
      email: emailVisibility,
      followers: followersVisibility,
      following: followingVisibility,
      member_since: memberSinceVisibility,
    })
      .unwrap()
      .then(() => {
        toast.info(`Updated`, {
          position: toast.POSITION.BOTTOM_CENTER,
        })
      })
      .catch(() => {
        toast.error(`Please, try again later. Server unavailable`, {
          position: toast.POSITION.BOTTOM_CENTER,
        })
      })
      .finally(() => {
        toast.dismiss(id)
        setSubmitting(false)
      })
  }

  if (!privacySettings) return <></>

  return (
    <Container
      sx={{
        "&.MuiContainer-root": {
          marginLeft: "unset",
        },
      }}
    >
      <Grid
        container
        spacing={1}
        mt={1}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="h6">Visibility</Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="subtitle1">Name</Typography>
        </Grid>
        <Grid item xs={10}>
          <VisibilityControl
            value={nameVisibility}
            onChange={(v) => setNameVisibility(v)}
          />
        </Grid>

        <Grid item xs={2}>
          <Typography variant="subtitle1">Email</Typography>
        </Grid>
        <Grid item xs={10}>
          <VisibilityControl
            value={emailVisibility}
            onChange={(v) => setEmailVisibility(v)}
          />
        </Grid>

        <Grid item xs={2}>
          <Typography variant="subtitle1">Followers</Typography>
        </Grid>
        <Grid item xs={10}>
          <VisibilityControl
            value={followersVisibility}
            onChange={(v) => setFollowersVisibility(v)}
          />
        </Grid>

        <Grid item xs={2}>
          <Typography variant="subtitle1">Following</Typography>
        </Grid>
        <Grid item xs={10}>
          <VisibilityControl
            value={followingVisibility}
            onChange={(v) => setFollowingVisibility(v)}
          />
        </Grid>

        <Grid item xs={2}>
          <Typography variant="subtitle1">Member Since</Typography>
        </Grid>
        <Grid item xs={10}>
          <VisibilityControl
            value={memberSinceVisibility}
            onChange={(v) => setMemberSinceVisibility(v)}
          />
        </Grid>

        <Grid item xs={2}>
          <Button
            disabled={inSync || submitting}
            variant="contained"
            size={"small"}
            onClick={handleSave}
            sx={{
              "&.MuiButton-root": {
                textTransform: "none",
              },
            }}
          >
            Update
          </Button>
        </Grid>
      </Grid>{" "}
    </Container>
  )
}

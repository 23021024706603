import SearchIcon from "@mui/icons-material/Search"
import { Box, IconButton, InputAdornment, TextField } from "@mui/material"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import {
  useSearchFollowersQuery,
  useSearchFollowingUsersQuery,
  useSearchUsersQuery,
} from "services/userApi"
import { useDebounce } from "use-debounce"
import { UserCard } from "./UserCard"

export function Users() {
  const [searchInputValue, setSearchInputValue] = useState<string>("")
  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(event.target.value)
  }

  const [searchFilter, setSearchFilter] = useState<string>("")
  const searchUsers = useCallback(
    (filter: string) => {
      setSearchFilter(filter)
    },
    [setSearchFilter]
  )

  const [value] = useDebounce(searchInputValue, 1000, {})

  useEffect(() => {
    if (value) {
      setSearchFilter(value)
    }
  }, [value, setSearchFilter])

  return (
    <Box sx={{ pt: "16px", pl: "24px" }}>
      <TextField
        size="small"
        placeholder="Search Users"
        value={searchInputValue}
        onChange={handleSearchInputChange}
        onKeyUp={(ev) => {
          if (ev.key === "Enter") {
            ev.preventDefault()
            searchUsers(searchInputValue)
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={() => searchUsers(searchInputValue)}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box sx={{ pt: "16px" }}>
        <UserList
          search={searchFilter
            .split(" ")
            .filter((v) => v != "")
            .join(" ")}
        />
      </Box>
    </Box>
  )
}

export const UserList = ({ search }: { search: string | undefined }) => {
  const usersQuery = useSearchUsersQuery(
    {
      filter: search || "",
    },
    { skip: !search || search == "" || search == "@" }
  )
  if (!search || search === "" || search == "@" || !usersQuery.data?.users)
    return null
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        {usersQuery.data?.users.map((user) => {
          return <UserCard key={user.id} user={user} />
        })}
      </Box>
    </Box>
  )
}

export const FollowerList = ({
  search,
  publicHandle,
}: {
  search?: string | undefined
  publicHandle: string
}) => {
  const usersQuery = useSearchFollowersQuery({
    query: { filter: search || "" },
    publicHandle: publicHandle,
  })
  if (!usersQuery.data?.users) return null
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        {usersQuery.data?.users.map((user) => {
          return <UserCard key={user.id} user={user} />
        })}
      </Box>
    </Box>
  )
}

export const FollowingList = ({
  search,
  publicHandle,
}: {
  search?: string | undefined
  publicHandle: string
}) => {
  const usersQuery = useSearchFollowingUsersQuery({
    query: { filter: search || "" },
    publicHandle: publicHandle,
  })
  if (!usersQuery.data?.users) return null
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        {usersQuery.data?.users.map((user) => {
          return <UserCard key={user.id} user={user} />
        })}
      </Box>
    </Box>
  )
}

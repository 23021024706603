import { Box } from "@mui/material"
import classnames from "classnames"
import { SelectedCardView } from "features/HHReplay/CardSelector"
import {
  HandActionChar,
  HandPosition,
  HAND_ACTIONS_STRINGS,
} from "features/HHReplay/types"
import { pairInBoard } from "features/Ranges/postflop/PFRangeView"
import {
  operationOrder,
  sortedOperations,
} from "features/Ranges/postflop/PostflopRangeView"
import { getCellId } from "features/Ranges/utils"
import { Cards } from "features/types"
import { useCallback, useEffect, useMemo, useState } from "react"
import { PFActionPath, PFCardPair, PostflopRange } from "services/types"
import "../index.css"
import "./tree.css"

export type PFRANGE_TYPE = "IP" | "OOP"

interface PFStrategyViewProps {
  range: PostflopRange
  rangeType: PFRANGE_TYPE
  position: HandPosition
  combo?: string
}

export const betColors = [
  "hsl(10, 39%, 45%)",
  "hsl(10, 39%, 50%)",
  "hsl(10, 39%, 55%)",
  "hsl(10, 39%, 60%)",
  "hsl(10, 39%, 65%)",
  "hsl(10, 39%, 70%)",
  "hsl(10, 39%, 75%)",
  "hsl(10, 39%, 80%)",
  "hsl(10, 39%, 85%)",
  "hsl(10, 39%, 90%)",
  "hsl(10, 39%, 95%)",
]

export const opColors: Record<string, string> = {
  f: "rgb(109,	162,	192)",
  c: "lightgreen",
  x: "lightgreen",
}

export const comparePair = (a: string, b: string): number => {
  const suitOrder = ["s", "h", "d", "c"]
  const aOne = a.substring(1, 2)
  const bOne = b.substring(1, 2)
  if (aOne != bOne) {
    return suitOrder.indexOf(aOne) > suitOrder.indexOf(bOne) ? 1 : -1
  }
  const aTwo = a.substring(3, 4)
  const bTwo = b.substring(3, 4)
  return suitOrder.indexOf(aTwo) > suitOrder.indexOf(bTwo) ? 1 : -1
}

export function StrategyTreeView(props: PFStrategyViewProps) {
  const { range, rangeType, position } = props
  const [currentCell, setCurrentCell] = useState<string | undefined>("AA")
  const [choosenOp, chooseOp] = useState<
    { op: string; color: string } | undefined
  >()

  const selectCell = useCallback(
    (card: string | undefined) => {
      setCurrentCell(card)
    },
    [setCurrentCell]
  )

  useEffect(() => {
    if (!props.combo) return
    setCurrentCell(props.combo)
  }, [props.combo, setCurrentCell])

  const getPFStrategyPairBackground = useCallback(
    (pair: string, none_color?: string) => {
      if (!currentCell) return ""

      let ratio = 0
      if (range !== null)
        ratio =
          rangeType == "IP"
            ? range.rangeGroups[currentCell].hands[pair].ipRangeInfo.probability
            : range.rangeGroups[currentCell].hands[pair].oopRangeInfo
                .probability

      const operations = sortedOperations(
        range ? range.rangeGroups[currentCell].hands[pair].strategy : {}
      )

      const none_bgcolor = none_color || "#999999"
      if (ratio === undefined || ratio === null) {
        return `linear-gradient(${none_bgcolor} 0%, ${none_bgcolor} 100%)`
      }

      const scale = 100

      const probabilityPercent: number = ratio * scale
      if (probabilityPercent <= 0) return none_bgcolor

      let strategies = "linear-gradient(to right, "
      let opPercent = 0
      let opColorIdx = 0
      for (const op of operations) {
        const opRatio = op.ratio * 100
        let opColor = opColors[op.op.slice(0, 1)]
        if (!opColor) {
          opColor = betColors[opColorIdx] || "yellow"
          opColorIdx++
        }
        strategies += `${opColor} ${opPercent}%, ${opColor} ${
          opRatio + opPercent
        }%, `
        opPercent += opRatio
      }
      strategies += `black ${opPercent}%, black 100%)`

      const result =
        `linear-gradient(to bottom, ${none_color} 0%, ${none_color} 100%) ` +
        `0% 0%/100% ${100 - probabilityPercent}% no-repeat, ` +
        `${strategies} no-repeat 0% 100%/100% ${probabilityPercent}%`

      return result
    },
    [range, rangeType, currentCell]
  )

  const getPFStrategyCellBackground = useCallback(
    (cellId: string, none_color?: string) => {
      let ratio = 0
      if (range !== null)
        ratio =
          rangeType == "IP"
            ? range.rangeGroups[cellId].ipRange
            : range.rangeGroups[cellId].oopRange

      const operations = Object.keys(
        range !== null ? range.rangeGroups[cellId].strategy : {}
      )
        .map((op) => {
          return {
            op: op.slice(0, 1),
            value: op.slice(1),
            ratio: range ? range.rangeGroups[cellId].strategy[op] : 0,
          }
        })
        .sort((a, b) => {
          const order = ["b", "r", "c", "x", "f"]
          if (a.op == b.op)
            return parseFloat(a.value) > parseFloat(b.value) ? -1 : 1
          return order.indexOf(a.op) > order.indexOf(b.op) ? 1 : -1
        })

      if (choosenOp) {
        if (
          operations.some(
            (v) =>
              `${v.op}${v.value}` == choosenOp.op && v.ratio > 0 && ratio > 0
          )
        ) {
          return choosenOp.color
        }
        return "#666666"
      }

      const none_bgcolor = none_color || "#999999"
      if (ratio === undefined || ratio === null) {
        return `linear-gradient(${none_bgcolor} 0%, ${none_bgcolor} 100%)`
      }

      const scale = 100

      const probabilityPercent: number = ratio * scale
      if (probabilityPercent <= 0) return none_bgcolor

      let strategies = "linear-gradient(to right, "
      let opPercent = 0
      let opColorIdx = 0
      for (const op of operations) {
        const opRatio = op.ratio * 100
        let opColor = opColors[op.op.slice(0, 1)]
        if (!opColor) {
          opColor = betColors[opColorIdx] || "yellow"
          opColorIdx++
        }

        strategies += `${opColor} ${opPercent}%, ${opColor} ${
          opRatio + opPercent
        }%, `
        opPercent += opRatio
      }
      strategies += `black ${opPercent}%, black 100%)`

      const result =
        `linear-gradient(to bottom, ${none_color} 0%, ${none_color} 100%) ` +
        `0% 0%/100% ${100 - probabilityPercent}% no-repeat, ` +
        `${strategies} no-repeat 0% 100%/100% ${probabilityPercent}%`

      return result
    },
    [range, rangeType, choosenOp]
  )

  const operations = useMemo(() => {
    if (!range) return
    const rv: Record<string, number> = {}
    const combos = Object.keys(range?.rangeGroups || {})
    for (const group of combos) {
      const hands = range?.rangeGroups[group].hands
      const pairs = Object.keys(hands)
      for (const pair of pairs) {
        const strategy = range?.rangeGroups[group].hands[pair].strategy
        const handProbability =
          props.rangeType == "IP"
            ? range?.rangeGroups[group].hands[pair].ipRangeInfo.probability
            : range?.rangeGroups[group].hands[pair].oopRangeInfo.probability
        const operations = Object.keys(strategy)
        for (const op of operations) {
          const opRatio = strategy[op]
          rv[op] = (rv[op] || 0) + opRatio * handProbability
        }
      }
    }
    return sortedOperations(rv)
  }, [range, props.rangeType])

  const opCombos =
    operations?.reduce((accumulator, op) => accumulator + op.ratio, 0) || 0

  const rows = useMemo(() => {
    const rows: JSX.Element[] = []

    for (let i = 0; i < Cards.length; i++) {
      const cells = []
      for (let j = 0; j < Cards.length; j++) {
        const cellId: string = getCellId(i, j)
        const strategy =
          range === null ? {} : range.rangeGroups[cellId].strategy
        const actions = Object.keys(strategy).sort((a, b) => {
          const op1 = a[0],
            op2 = b[0]
          const val1 = a.substring(1),
            val2 = b.substring(2)
          if (op1 == op2) return parseFloat(val1) > parseFloat(val2) ? -1 : 1
          return operationOrder.indexOf(op1) > operationOrder.indexOf(op2)
            ? 1
            : -1
        })

        const cell = (
          <Box
            onClick={() => (range ? selectCell(cellId) : undefined)}
            key={cellId}
            className={`pfstrategy-cell tree-view ${
              cellId == currentCell ? "pfrange-selected" : ""
            }`}
            style={{
              background: getPFStrategyCellBackground(
                cellId,
                i == j ? "#cccccc" : "#aaaaaa"
              ),
            }}
          >
            <Box sx={{ flexGrow: 1 }}>{cellId}</Box>
          </Box>
        )
        cells.push(cell)
      }
      const row = (
        <div key={i} className="pfrange-row">
          {cells}
        </div>
      )
      rows.push(row)
    }
    return rows
  }, [range, selectCell, currentCell, getPFStrategyCellBackground])

  const PairInfo = ({ pair }: { pair: PFCardPair }) => {
    if (!currentCell || range === null) return null
    const operations = sortedOperations(
      range.rangeGroups[currentCell].hands[pair.hand].strategy
    )
    return (
      <Box
        sx={{
          mx: "1px",
          mb: "2px",
          display: "flex",
          flex: "1",
          flexDirection: "column",
          minHeight: `${operations.length * 12 + 20}px`,
          lineHeight: "1",
          border: "1px solid gray",
          fontSize: "12px",
          position: "relative",
          background: getPFStrategyPairBackground(pair.hand, "#cccccc"),
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "start",
          }}
        >
          <SelectedCardView card={pair.hand.substring(0, 2)} />
          <SelectedCardView card={pair.hand.substring(2, 4)} />
        </Box>
        {!pairInBoard(range?.board || "", pair.hand) &&
          operations.map((v) => {
            const action = `${v.op}${v.value}`
            return (
              <Box key={action} sx={{ display: "flex", gap: "8px" }}>
                <Box sx={{ flexGrow: 1 }}>
                  {HAND_ACTIONS_STRINGS[v.op as HandActionChar]} {v.value}
                </Box>
                <Box>
                  {v.ratio > 0.01 || v.ratio < 0.0001
                    ? v.ratio.toFixed(2)
                    : v.ratio.toFixed(4)}
                </Box>
              </Box>
            )
          })}
      </Box>
    )
  }

  const CellInfo = () => {
    if (!currentCell || range === null) return null

    const pairs = range.rangeGroups[currentCell].hands
    const pairKeys = Object.keys(pairs).sort(comparePair)
    const nPairs = pairKeys.length

    const rows: JSX.Element[] = []
    if (nPairs % 3 == 0) {
      for (let i = 0; i < nPairs; i += 3) {
        const row = []
        let key = pairKeys[i]
        row.push(<PairInfo key={key} pair={pairs[key]} />)
        key = pairKeys[i + 1]
        row.push(<PairInfo key={key} pair={pairs[key]} />)
        key = pairKeys[i + 2]
        row.push(<PairInfo key={key} pair={pairs[key]} />)
        rows.push(
          <Box key={i} sx={{ display: "flex" }}>
            {row}
          </Box>
        )
      }
    } else if (nPairs % 2 == 0) {
      for (let i = 0; i < nPairs; i += 2) {
        const row = []
        let key = pairKeys[i]
        row.push(<PairInfo key={key} pair={pairs[key]} />)
        key = pairKeys[i + 1]
        row.push(<PairInfo key={key} pair={pairs[key]} />)
        rows.push(
          <Box key={i} sx={{ display: "flex" }}>
            {row}
          </Box>
        )
      }
    }

    return <Box sx={{ minWidth: "250px" }}>{rows}</Box>
  }

  let opColorIdx = 0

  return (
    <Box>
      <Box>
        <Box sx={{ fontWeight: "bold", textAlign: "center" }}>
          Strategy {rangeType} ({position})
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", fontSize: "12px" }}>
          {[0, 2, 4].map((v) => (
            <Box key={v}>
              <SelectedCardView card={range.board.substring(v, v + 2)} />
            </Box>
          ))}
          <RangeActions range={range} />
        </Box>
        <Box sx={{ display: "flex", pt: 0.5, overflow: "hidden" }}>
          <Box className="pfstrategy tree-view">{rows}</Box>
          <Box sx={{ minWidth: "5px" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // maxHeight: "390px",
              overflowY: "scroll",
            }}
          >
            <>
              {operations &&
                operations.map((op) => {
                  const thisOp = `${op.op}${op.value}`
                  const opColor = opColors[op.op] || betColors[opColorIdx++]
                  return (
                    <Box
                      key={`${op.op}_${op.value}`}
                      onClick={() => {
                        const newOp =
                          thisOp == choosenOp?.op ? undefined : thisOp
                        chooseOp(
                          newOp ? { op: newOp, color: opColor } : undefined
                        )
                      }}
                      className={classnames({
                        ["choosen-op"]: thisOp == choosenOp?.op,
                        ["non-choosen-op"]: choosenOp && thisOp != choosenOp.op,
                      })}
                      sx={{
                        fontSize: "12px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        m: "1px",
                        p: "2px",
                        cursor: "pointer",
                        // border: "1px solid gray",
                        borderRadius: "2px",
                        boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
                        background: opColor,
                      }}
                    >
                      <Box>
                        {`${HAND_ACTIONS_STRINGS[op.op as HandActionChar]}${
                          op.value
                        }`}
                      </Box>
                      <Box>
                        {`${op.ratio.toFixed(1)}/${opCombos.toFixed(1)} combos
                      (${((op.ratio * 100) / opCombos).toFixed(2)}%)`}
                      </Box>
                    </Box>
                  )
                })}
            </>
            <Box sx={{ flexGrow: 1 }}></Box>
            <CellInfo />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const PFRangeCard = ({ pfaction }: { pfaction: PFActionPath }) => {
  return (
    <Box>
      <SelectedCardView card={pfaction.action} />
    </Box>
  )
}

const PFRangeAction = ({ pfaction }: { pfaction: PFActionPath }) => {
  const operations = pfaction.alternatives
    ?.filter((op) => ["r", "b"].indexOf(op[0]) >= 0)
    .sort()
    .reverse()
  const color =
    opColors[pfaction.action] ||
    betColors[operations?.indexOf(pfaction.action) || 0]

  return (
    <Box
      sx={{
        p: "1px",
        minWidth: "16px",
        border: `1px solid gray`,
        borderRadius: "2px",
        background: `${color}`,
        textAlign: "center",
      }}
    >
      {`${pfaction.action[0]}${pfaction.action.substring(1)}`}
    </Box>
  )
}

const RangeActions = ({ range }: { range: PostflopRange }) => {
  const actions = useMemo(() => {
    const rv: JSX.Element[] = []
    let action: PFActionPath = range.actionPath
    while (action && action != null && action.action != null) {
      const playerAction =
        HAND_ACTIONS_STRINGS[action.action[0] as HandActionChar]
      if (playerAction) rv.push(<PFRangeAction pfaction={action} />)
      else {
        const cardAction = action
        rv.push(
          <Box key={3}>
            <PFRangeCard pfaction={cardAction} />
          </Box>
        )
      }

      if (action.parent == null) break

      action = action.parent
    }
    return rv.reverse()
  }, [range])

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {actions.map((v, idx) => (
        <Box key={idx} sx={{ p: "1px" }}>
          {v}
        </Box>
      ))}
    </Box>
  )
}

import CopyAllIcon from "@mui/icons-material/CopyAll"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd"
import { Alert, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import FormControlLabel from "@mui/material/FormControlLabel"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import { styled } from "@mui/system"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { PageTitle } from "lib/Components/PageTitile"
import * as React from "react"
import { FormEvent } from "react"
import {
  useAddBetSizesRangeMutation,
  useCopyBetSizesRangeGroupMutation,
  useDeleteRangeGroupMutation,
  useDeleteRangeMutation,
  useGetBetSizesRangeGroupsQuery,
  useUpdateBetSizesRangeMutation,
} from "services/betSizesApi"
import {
  AddBetSizingRangeRequest,
  BetSizingRange,
  BetSizingRangeGroup,
  getErrorData,
  PostFlopStreetsSizing,
  SimplePositionSizing,
  UpdateBetSizingRangeRequest,
} from "services/types"
import { BetSizingConfig } from "./BetSizingConfig"

const rangeLabel = (v: BetSizingRange) => {
  return v.rangeEnd ? `${v.rangeStart} - ${v.rangeEnd}` : `${v.rangeStart}+`
}

const OperationInfo = styled(Box)({
  height: "1.5rem",
  lineHeight: "1.5rem",
})

const PFActionButton = styled(ToggleButton)({
  minWidth: "80px",
  "&.MuiButtonBase-root.MuiToggleButton-root": {
    textTransform: "none",
  },
})

const ActionButton = styled(Button)({
  "&.MuiButton-root": {
    fontSize: "10px",
    width: "50px",
    background: "#444444",
    textTransform: "none",
  },
})

interface BetSizingOpValueProps {
  value: SimplePositionSizing
  position: "OOP" | "IP"
  operation: "BET" | "RAISE"
  street: string
}

const BetSizingOpValue = (props: BetSizingOpValueProps) => {
  const { value: cellValue, position, operation } = props
  return (
    <>
      <OperationInfo>
        {cellValue[position][operation]
          .split(",")
          .map((v) => v.trim())
          .join(", ")}
      </OperationInfo>
    </>
  )
}

interface BetSizingValueProps {
  value: SimplePositionSizing
  street: string
}

export const BetSizingValue = (props: BetSizingValueProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <BetSizingOpValue position={"OOP"} operation={"BET"} {...props} />
      <BetSizingOpValue position={"OOP"} operation={"RAISE"} {...props} />
      <BetSizingOpValue position={"IP"} operation={"BET"} {...props} />
      <BetSizingOpValue position={"IP"} operation={"RAISE"} {...props} />
    </Box>
  )
}

const RangeEditLabel = ({ label }: { label: string }) => {
  return (
    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
      {label}
    </Typography>
  )
}

interface StreetBetSizingManageProps {
  street: string
  range?: BetSizingRange
}
const StreetBetSizingManage = (props: StreetBetSizingManageProps) => {
  const { street } = props
  return (
    <Box
      sx={{
        p: 1,
        my: 0.5,
        display: "flex",
        borderRadius: "12px",
        border: "1px solid #DDDDDD",
      }}
    >
      <Box sx={{ pr: 2, display: "flex" }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: "bold", textTransform: "capitalize" }}
        >
          {street.toLowerCase()}
        </Typography>
      </Box>
      <RangeBetSizingManage position="IP" {...props} />
      <Box sx={{ pr: 2 }}></Box>
      <RangeBetSizingManage position="OOP" {...props} />
    </Box>
  )
}

const RangeBetSizingManage = ({
  position,
  street,
  range,
}: {
  position: string
  street: string
  range?: BetSizingRange
}) => {
  const betValue =
    range === undefined
      ? []
      : range.betSizes[street as keyof PostFlopStreetsSizing][
          position as keyof SimplePositionSizing
        ].BET?.split(",")?.map((v) => v.trim())
  const betInputValue = betValue
    .filter((v) => {
      if (v.endsWith("c") || v.endsWith("x")) {
        const numVal = v.slice(0, -1)
        return !Number.isNaN(Number(numVal))
      }
      return !Number.isNaN(Number(v))
    })
    .join(", ")

  const raiseValue =
    range === undefined
      ? []
      : range.betSizes[street as keyof PostFlopStreetsSizing][
          position as keyof SimplePositionSizing
        ].RAISE?.split(",")?.map((v) => v.trim())
  const raiseInputValue = raiseValue
    .filter((v) => {
      if (v.endsWith("c") || v.endsWith("x")) {
        const numVal = v.slice(0, -1)
        return !Number.isNaN(Number(numVal))
      }
      return !Number.isNaN(Number(v))
    })
    .map((v) => v.trim())
    .join(", ")
  return (
    <Box>
      <RangeEditLabel label={position} />
      <Box>
        <TextField
          size="small"
          name={`${street}_${position}_BET`}
          margin="dense"
          label="Bet"
          type="name"
          fullWidth
          defaultValue={betInputValue}
        />
        <TextField
          size="small"
          name={`${street}_${position}_RAISE`}
          margin="dense"
          label="Raise"
          type="name"
          fullWidth
          defaultValue={raiseInputValue}
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              name={`${street}_${position}_ALLIN`}
              defaultChecked={raiseValue?.indexOf("AllIn") >= 0}
            />
          }
          label="All In"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              name={`${street}_${position}_GEO`}
              defaultChecked={raiseValue?.indexOf("Geo") >= 0}
            />
          }
          label="Geo"
        />
      </Box>
    </Box>
  )
}
interface RangeViewProps {
  rangeGroup: BetSizingRangeGroup
  admin: boolean
  manageable: boolean
}

export function RangeView(props: RangeViewProps) {
  const { rangeGroup, admin, manageable } = props
  const { ranges: stackSizesx } = rangeGroup

  const [copyRangeGroup] = useCopyBetSizesRangeGroupMutation()
  const [addRange, { error: addError }] = useAddBetSizesRangeMutation()
  const [updateRange, { error: updateError }] = useUpdateBetSizesRangeMutation()
  const [deleteRange, { error: deleteError }] = useDeleteRangeMutation()
  const [deleteRangeGroup] = useDeleteRangeGroupMutation()
  const [clone, showCloneDialog] = React.useState(false)
  const [addrange, showAddRangeDialog] = React.useState(false)
  const [editrange, showEditRangeDialog] = React.useState<
    BetSizingRange | undefined
  >()
  const [deleteOp, showDeleteDialog] = React.useState(false)
  const [deleteRangeOp, showDeleteRangeDialog] = React.useState<
    BetSizingRange | undefined
  >(undefined)
  const [cloneName, setCloneName] = React.useState("")

  const [manageError, setManageError] = React.useState<string | undefined>()

  const sortedRanges = React.useMemo(() => {
    if (!stackSizesx) return []
    return [...stackSizesx].sort((a, b) => a.rangeStart - b.rangeStart)
  }, [stackSizesx])

  const rows = React.useMemo(() => {
    if (!sortedRanges) return []
    const rv = []
    rv.push({ id: 1, street: "flop", range: sortedRanges })
    rv.push({ id: 2, street: "turn", range: sortedRanges })
    rv.push({ id: 3, street: "river", range: sortedRanges })
    return rv
  }, [sortedRanges])

  React.useEffect(() => {
    if (updateError) {
      const errorData = getErrorData<UpdateBetSizingRangeRequest>(updateError)
      const msg = errorData?.errors
        ? errorData?.errors[0]?.defaultMessage
        : errorData?.message
      setManageError(msg)
      return
    }
    if (addError) {
      const errorData = getErrorData<AddBetSizingRangeRequest>(addError)
      const msg = errorData?.errors
        ? errorData?.errors[0]?.defaultMessage
        : errorData?.message
      setManageError(msg)
      return
    }
    setManageError(undefined)
  }, [updateError, addError, setManageError])

  const handleAddRange = () => {
    showAddRangeDialog(true)
  }

  const handleEditRange = (range: BetSizingRange) => {
    showEditRangeDialog(range)
  }

  const closeManageRangeDialog = () => {
    addrange && showAddRangeDialog(false)
    editrange && showEditRangeDialog(undefined)
    setManageError(undefined)
  }

  const handleClone = () => {
    showCloneDialog(true)
  }

  const closeCloneDialog = () => {
    clone && showCloneDialog(false)
  }

  const handleCloneOk = () => {
    clone &&
      copyRangeGroup({
        id: rangeGroup.id,
        name: cloneName,
      })
    closeCloneDialog()
  }

  const handleDelete = () => {
    showDeleteDialog(true)
  }

  const closeDeleteDialog = () => {
    deleteOp && showDeleteDialog(false)
  }

  const handleCloneNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCloneName(event.target.value)
  }

  const handleDeleteOk = () => {
    deleteOp && deleteRangeGroup(rangeGroup.id)
    closeDeleteDialog()
  }

  const handleDeleteRange = (range: BetSizingRange) => {
    showDeleteRangeDialog(range)
  }

  const closeDeleteRangeDialog = () => {
    deleteRangeOp && showDeleteRangeDialog(undefined)
  }

  const handleDeleteRangeOk = () => {
    deleteRangeOp && deleteRange(deleteRangeOp.id)
    closeDeleteRangeDialog()
  }

  const formInputVal = (form: FormData, name: string): string => {
    const rv = (form.get(name) || "").toString()
    return rv
  }

  const formBetSizingValue = (
    form: FormData,
    street: string,
    position: string,
    operation: string
  ): string => {
    return [
      ...formInputVal(form, `${street}_${position}_${operation}`).split(","),
      ...(formInputVal(form, `${street}_${position}_GEO`) != "" ? ["Geo"] : []),
      ...(formInputVal(form, `${street}_${position}_ALLIN`) != ""
        ? ["AllIn"]
        : []),
    ].join(",")
  }

  const handleRangeSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)

    const request = {
      id: "",
      rangeStart: Number((formData.get("rangefrom") || "").toString()),
      rangeEnd: formData.get("rangeto")
        ? Number(formData.get("rangeto"))
        : undefined,
      betSizes: {
        FLOP: {
          OOP: {
            BET: formBetSizingValue(formData, "FLOP", "OOP", "BET"),
            RAISE: formBetSizingValue(formData, "FLOP", "OOP", "RAISE"),
          },
          IP: {
            BET: formBetSizingValue(formData, "FLOP", "IP", "BET"),
            RAISE: formBetSizingValue(formData, "FLOP", "IP", "RAISE"),
          },
        },
        TURN: {
          OOP: {
            BET: formBetSizingValue(formData, "TURN", "OOP", "BET"),
            RAISE: formBetSizingValue(formData, "TURN", "OOP", "RAISE"),
          },
          IP: {
            BET: formBetSizingValue(formData, "TURN", "IP", "BET"),
            RAISE: formBetSizingValue(formData, "TURN", "IP", "RAISE"),
          },
        },
        RIVER: {
          OOP: {
            BET: formBetSizingValue(formData, "RIVER", "OOP", "BET"),
            RAISE: formBetSizingValue(formData, "RIVER", "OOP", "RAISE"),
          },
          IP: {
            BET: formBetSizingValue(formData, "RIVER", "IP", "BET"),
            RAISE: formBetSizingValue(formData, "RIVER", "IP", "RAISE"),
          },
        },
      },
    }
    // XXX validate form inputs
    if (addrange) {
      request.id = rangeGroup.id
      addRange(request as AddBetSizingRangeRequest)
        .unwrap()
        .then(() => closeManageRangeDialog())
    }
    if (editrange) {
      request.id = editrange.id
      updateRange(request as UpdateBetSizingRangeRequest)
        .unwrap()
        .then(() => {
          closeManageRangeDialog()
        })
    }

    // if (!validate(formData)) return
    // const data: LoginRequest = {
    //   login: (formData.get("login") || "").toString(),
    //   password: (formData.get("password") || "").toString(),
    // }

    // login(data)
  }

  const specialColumns: GridColDef[] = [
    {
      field: "street",
      width: 180,
      editable: false,
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box>
          <Box sx={{ lineHeight: "1rem" }}>SPR</Box>
          <Box sx={{ lineHeight: "1rem" }}>Effective Stack</Box>
        </Box>
      ),
      renderCell(params) {
        return (
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              alignItems: "stretch",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
              }}
            >
              {params.value}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <OperationInfo>OOP</OperationInfo>
              <OperationInfo>IP</OperationInfo>
            </Box>
            <Box>
              <OperationInfo>Bet</OperationInfo>
              <OperationInfo>Raise</OperationInfo>
              <OperationInfo>Bet</OperationInfo>
              <OperationInfo>Raise</OperationInfo>
            </Box>
          </Box>
        )
      },
    },
    {
      field: "addaction",
      width: 80,
      editable: false,
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      renderHeader: () => (
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "stretch",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={handleAddRange}
          >
            <PlaylistAddIcon />
          </IconButton>
        </Box>
      ),
      renderCell() {
        return null
      },
    },
  ]

  const stacks: GridColDef[] = []
  stacks.push(specialColumns[0])
  sortedRanges.forEach((v, i) => {
    stacks.push({
      field: `range_${i}`,
      flex: 1,
      editable: false,
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      renderHeader: () => (
        <>
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ lineHeight: "1rem" }}>x</Box>
            <Box sx={{ lineHeight: "1rem" }}>
              {v.rangeEnd
                ? `${v.rangeStart} - ${v.rangeEnd}`
                : `${v.rangeStart}+`}
            </Box>
          </Box>
          {manageable && (
            <>
              <IconButton
                size="small"
                aria-label="delete"
                color="primary"
                sx={{ color: "black", mr: -1 }}
                onClick={() => {
                  handleEditRange(v)
                }}
              >
                <ModeEditIcon sx={{ width: "16px" }} />
              </IconButton>
              <IconButton
                aria-label="delete"
                color="primary"
                sx={{ color: "black", mr: -1 }}
                onClick={() => {
                  handleDeleteRange(v)
                }}
              >
                <DeleteForeverIcon sx={{ width: "16px" }} />
              </IconButton>
            </>
          )}
        </>
      ),
      renderCell(params) {
        const street = params.row.street as string
        const rangeN = params.field.split("_").map((v) => Number(v))[1]
        const range = sortedRanges[rangeN]
        const cellValue =
          range.betSizes[street.toUpperCase() as keyof PostFlopStreetsSizing]

        return <BetSizingValue value={cellValue} street={street} />
      },
    })
  })
  if (manageable) stacks.push(specialColumns[1])
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Box sx={{ display: "flex", py: 1 }}>
        <Typography variant="h6">RangeSet: {rangeGroup.name}</Typography>
        {!admin && (
          <ActionButton
            variant="contained"
            sx={{ ml: 1 }}
            endIcon={<CopyAllIcon />}
            onClick={handleClone}
          >
            Clone
          </ActionButton>
        )}
        {rangeGroup.ownerId && (
          <ActionButton
            variant="contained"
            sx={{ ml: 1 }}
            endIcon={<HighlightOffIcon />}
            onClick={handleDelete}
          >
            Delete
          </ActionButton>
        )}
      </Box>
      <DataGrid
        rows={rows}
        autoHeight={true}
        disableExtendRowFullWidth={true}
        rowHeight={100}
        columns={stacks}
        showCellRightBorder={true}
        showColumnRightBorder={true}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        hideFooter={true}
        sx={{
          "& .uiDataGrid-footerContainer": {
            display: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            flexGrow: 1,
          },
        }}
      />
      <Dialog open={clone} onClose={closeCloneDialog}>
        <DialogTitle>{clone && <span>Clone RangeSet</span>}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="name"
            fullWidth
            variant="standard"
            value={cloneName}
            onChange={handleCloneNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCloneDialog}>Cancel</Button>
          <Button onClick={handleCloneOk}>OK</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteOp} onClose={closeDeleteDialog}>
        <DialogTitle>
          {deleteOp && <span>Delete rangeset confirmation</span>}
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6">
            Are you shure want to delete rangeset {rangeGroup.name}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancel</Button>
          <Button onClick={handleDeleteOk}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteRangeOp !== undefined}
        onClose={closeDeleteRangeDialog}
      >
        <DialogTitle>
          {deleteRangeOp && <span>Delete range confirmation</span>}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Are you shure want to delete range
            <br />
            {rangeGroup.name} ({deleteRangeOp && rangeLabel(deleteRangeOp)})?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteRangeDialog}>Cancel</Button>
          <Button onClick={handleDeleteRangeOk}>OK</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={addrange || editrange !== undefined}
        onClose={closeManageRangeDialog}
      >
        <DialogTitle>
          {addrange && <span>Add Range to rangeset: {rangeGroup.name}</span>}
          {editrange && (
            <span>
              Manage Range of rangeset: {rangeGroup.name}{" "}
              {rangeLabel(editrange)}
            </span>
          )}
        </DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleRangeSubmit}>
            {manageError && (
              <Alert severity="error">
                {manageError || "Internal server error"}
              </Alert>
            )}
            <Box display="flex" sx={{ alignItems: "center" }}>
              <Typography variant="h6" sx={{ whiteSpace: "nowrap", pr: 2 }}>
                Stack size
              </Typography>
              <TextField
                autoFocus
                required
                size="small"
                margin="dense"
                name="rangefrom"
                label="From"
                type="name"
                fullWidth
                defaultValue={editrange ? editrange.rangeStart : "0"}
              />
              <Box sx={{ px: 2 }}>-</Box>
              <TextField
                size="small"
                margin="dense"
                name="rangeto"
                label="To"
                type="name"
                fullWidth
                defaultValue={editrange ? editrange.rangeEnd : ""}
              />
            </Box>
            {/* {editrange && ( */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <StreetBetSizingManage street="FLOP" range={editrange} />
              <StreetBetSizingManage street="TURN" range={editrange} />
              <StreetBetSizingManage street="RIVER" range={editrange} />
            </Box>
            {/* )} */}
            <DialogActions>
              <Button type="button" onClick={closeManageRangeDialog}>
                Cancel
              </Button>
              <Button type="submit">OK</Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export interface BetSizingProps {
  admin?: boolean
}

export function BetSizing(props: BetSizingProps) {
  const { admin } = props

  const [pfaction, choosePFAction] = React.useState("RFI")
  const {
    data: rangeSets,
    // isLoading,
    isSuccess: haveRangeSets,
    // isError,
  } = useGetBetSizesRangeGroupsQuery(pfaction)

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment) choosePFAction(newAlignment)
  }

  if (!haveRangeSets) {
    return <div>Loading...</div>
  }

  if (!haveRangeSets) {
    return <div>Loading...</div>
  }

  const defaultRangeSet = rangeSets.filter((v) => !v.ownerId)[0]
  const userRanges = rangeSets.filter((v) => v.ownerId)

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <PageTitle text="Bet Sizing" />
      {!admin && <BetSizingConfig />}
      <Box sx={{ mt: 1 }}></Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6">PF Actions</Typography>
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={pfaction}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
          sx={{ pl: 1 }}
        >
          <PFActionButton value="SRP_VS_BLINDS">SRP vs. Blinds</PFActionButton>
          <PFActionButton value="SRP_IP_FLAT">SRP, IP flat</PFActionButton>
          <PFActionButton value="IP_THREE_BET">IP 3Bet</PFActionButton>
          <PFActionButton value="BVB_SB_LIMP">BvB limped</PFActionButton>
          <PFActionButton value="BVB_SB_RFI">BVB SB RFI</PFActionButton>
          <PFActionButton value="BB_ISO">BB ISO</PFActionButton>
          <PFActionButton value="FOUR_BET">4 bet</PFActionButton>
        </ToggleButtonGroup>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflowY: "scroll",
        }}
      >
        {defaultRangeSet && (
          <RangeView
            rangeGroup={defaultRangeSet}
            admin={admin || false}
            manageable={admin || false}
          />
        )}
        <Box sx={{ pt: 3 }}></Box>
        {!admin &&
          userRanges.map((r) => (
            <RangeView
              key={r.id}
              rangeGroup={r}
              admin={false}
              manageable={true}
            />
          ))}
      </Box>
    </Box>
  )
}

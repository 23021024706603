import ClearIcon from "@mui/icons-material/Clear"
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd"
import SearchIcon from "@mui/icons-material/Search"
import { TextField } from "@mui/material"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import IconButton from "@mui/material/IconButton"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { HandPosition } from "features/HHReplay/types"
import { PositionLabels } from "features/types"
import { ChangeEvent, useEffect, useState } from "react"
import {
  ACTION_NAMES,
  FilterAction,
  HERO_POSITION,
  RangeParameters,
} from "../types"
import { ActionKind } from "./Ranges"

interface AddActionProps {
  positions: HERO_POSITION[]
  registerAction: (action: FilterAction) => void
}

function AddAction(props: AddActionProps) {
  const { positions, registerAction } = props
  const [action, setAction] = useState<keyof typeof ACTION_NAMES | "">("")
  const [position, setPosition] = useState<HandPosition | "">("")
  const [raiseAmount, setRaiseAmount] = useState("1")

  const handleActionChange = (event: SelectChangeEvent) => {
    setAction(event.target.value as keyof typeof ACTION_NAMES)
  }

  const handlePositionChange = (event: SelectChangeEvent) => {
    setPosition(event.target.value as HandPosition)
  }

  const handleRaiseAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRaiseAmount(event.target.value)
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ maxWidth: 100, minWidth: 100 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Position</InputLabel>
          <Select
            size="small"
            value={position}
            label="Position"
            onChange={handlePositionChange}
          >
            {[
              positions.map((v) => (
                <MenuItem key={v.position} value={v.position}>
                  {v.label}
                </MenuItem>
              )),
            ]}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ pl: 1, maxWidth: 100, minWidth: 100 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Action</InputLabel>
          <Select
            size="small"
            value={action}
            label="Action"
            onChange={handleActionChange}
          >
            {[
              Object.keys(ACTION_NAMES).map((v) => (
                <MenuItem key={v} value={v}>
                  {ACTION_NAMES[v as keyof typeof ACTION_NAMES]}
                </MenuItem>
              )),
            ]}
          </Select>
        </FormControl>
      </Box>
      {action === "RAISE" && (
        <TextField
          required
          error={!(parseFloat(raiseAmount) > 0)}
          size="small"
          label="Raise Amount"
          variant="outlined"
          sx={{ pl: 1 }}
          value={raiseAmount}
          type="number"
          onChange={handleRaiseAmountChange}
        />
      )}
      <IconButton
        aria-label="add an action"
        onClick={() => {
          if (
            position != "" &&
            action != "" &&
            (action != "RAISE" || parseFloat(raiseAmount) > 0)
          ) {
            const fAction = {
              position: position,
              action: action,
              raiseAmount:
                action === "RAISE" ? parseFloat(raiseAmount) : undefined,
            }
            registerAction(fAction)
          }
        }}
      >
        <PlaylistAddIcon />
      </IconButton>
    </Box>
  )
}

interface ShowActionProps {
  action: FilterAction
  actionIndex: number
  dropAction: (index: number) => void
}

function ShowAction(props: ShowActionProps) {
  const { action, dropAction, actionIndex } = props
  return (
    <Box display="flex" sx={{ p: 0.25, alignItems: "center" }}>
      <Box
        sx={{
          background: "darkcyan",
          color: "white",
          p: 1,
          borderRadius: "50px",
        }}
      >
        {PositionLabels[action.position]}-{ACTION_NAMES[action.action]}
        {action.raiseAmount ? "-" + action.raiseAmount + "BB" : ""}
      </Box>
      <IconButton
        aria-label="add an action"
        onClick={() => {
          dropAction(actionIndex)
        }}
      >
        <ClearIcon />
      </IconButton>
    </Box>
  )
}

interface CategoryFilterProps {
  handleChange: (
    field: ActionKind,
    value: string | HandPosition | FilterAction[]
  ) => void
  state: RangeParameters
  positions: HERO_POSITION[]
}

export function ActionsFilter(props: CategoryFilterProps) {
  const { state, handleChange } = props
  const [actions, setActions] = useState<FilterAction[]>([])

  const registerAction = (action: FilterAction) => {
    setActions([...actions, action])
  }

  const dropAction = (index: number) => {
    actions.splice(index, 1)
    setActions([...actions])
  }

  const handleSearch = () => {
    handleChange("filterActions", actions)
  }

  useEffect(() => {
    setActions([...(state.filterActions || [])])
  }, [state])

  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Box>
        Actions
        <IconButton onClick={handleSearch}>
          <SearchIcon />
        </IconButton>
      </Box>
      {actions.map((v, idx) => (
        <ShowAction
          key={idx}
          action={v}
          actionIndex={idx}
          dropAction={dropAction}
        />
      ))}
      <Box sx={{ pt: 1 }}></Box>
      <AddAction registerAction={registerAction} {...props} />
    </Box>
  )
}

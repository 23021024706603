import { Box } from "@mui/material"
import { HandActionChar } from "features/HHReplay/types"
import { useContext, useEffect, useMemo, useState } from "react"
import { useGetPostflopRangeQuery } from "services/rangesApi"
import { hhContext } from "../../HHReplay/HHContext"
import { cardToString } from "../../types"
import { cardInBoard } from "./PFRangeView"
import { RangeHeader } from "./RangeHeader"

export interface PFRangeProps {
  id: string
  path: string
}

export const operationOrder = ["b", "r", "c", "x", "f"]

export type StrategyOperation = {
  op: HandActionChar
  value: string
  ratio: number
}

export const sortedOperations = (
  strategy: Record<string, number>
): StrategyOperation[] => {
  return Object.keys(strategy)
    .map((op) => {
      return {
        op: op.slice(0, 1) as HandActionChar,
        value: op.slice(1),
        ratio: strategy[op],
      }
    })
    .sort((a, b) => {
      if (a.op == b.op)
        return parseFloat(a.value) > parseFloat(b.value) ? -1 : 1
      return operationOrder.indexOf(a.op) > operationOrder.indexOf(b.op)
        ? 1
        : -1
    })
}

export function PostflopRangeController(props: PFRangeProps) {
  const { id, path: initialPath } = props
  const context = useContext(hhContext)
  const [path, setPath] = useState(initialPath)

  useEffect(() => setPath(initialPath), [initialPath])

  const query = useGetPostflopRangeQuery({
    id: id,
    path: path,
  })

  const diffWithBoard = useMemo(() => {
    const diff = (context.hand.flopCards?.cards || []).map((v) =>
      cardInBoard((query.data?.board || "").substring(0, 6), cardToString(v))
    )
    return diff.filter((v) => !v).length > 0
  }, [query, context])

  if (diffWithBoard && query.data?.board) {
    return (
      <Box
        className="missed_pflabel"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box>Not applicable... Simulation board differ from hand one</Box>
        <Box sx={{ display: "flex" }}>
          <Box>{query.data.board.substring(0, 6)}</Box>
          <Box sx={{ width: "10px" }}></Box>
          <Box>
            {(context.hand.flopCards?.cards || [])
              .map((v) => cardToString(v))
              .join("")}
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      className={`${!query.currentData ? "missed_range" : ""}`}
      sx={{
        minWidth: "1000px",
        minHeight: "100px",
        display: "flex",
        position: "relative",
      }}
    >
      <Box sx={{ pt: 3 }}>
        {query.data && <RangeHeader range={query.data} setPath={setPath} />}
      </Box>

      {!query.isError && query.isFetching && (
        <Box className={"missed_pflabel"}>Loading...</Box>
      )}
      {query.isError && (
        <Box className={"missed_pflabel"}>Ranges not exists.</Box>
      )}
    </Box>
  )
}

import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormLabel from "@mui/material/FormLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import { useEffect, useState } from "react"
import {
  useGetBetSizesConfigQuery,
  useUpdateBetSizesConfigMutation,
} from "services/betSizesApi"

export function BetSizingConfig() {
  const { data: configResponse, isSuccess } = useGetBetSizesConfigQuery()
  const [updateConfig] = useUpdateBetSizesConfigMutation()

  const [config, setConfig] = useState<{
    betModelingConfig: string
    potModelingConfig: string
  }>({
    betModelingConfig: "USE_ACTUAL_AS_ADDITION",
    potModelingConfig: "USE_ACTUAL",
  })

  const handleBetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateConfig({
      betModelingConfig: (event.target as HTMLInputElement).value,
      potModelingConfig: config.potModelingConfig,
    })
  }

  const handlePotChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateConfig({
      betModelingConfig: config.betModelingConfig,
      potModelingConfig: (event.target as HTMLInputElement).value,
    })
  }

  useEffect(() => {
    if (configResponse) {
      setConfig({
        betModelingConfig: configResponse.betModelingConfig,
        potModelingConfig: configResponse.potModelingConfig,
      })
    }
  }, [configResponse, setConfig])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Box
        sx={{
          minWidth: "160px",
          display: "flex",
          alignItems: "center",
          border: "1px solid #CCCCCC",
          borderRadius: "8px",
          p: 1,
          mr: {
            xs: 0,
            md: 1,
          },
        }}
      >
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            How to model pre-flop bet sizings
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={config.betModelingConfig}
            onChange={handleBetChange}
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="USE_ACTUAL_AS_ADDITION"
              control={<Radio />}
              label="Use actual sizings as played, in addition to defaults"
              sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" } }}
            />
            <FormControlLabel
              value="REPLACE_CLOSEST_DEFAULTS"
              control={<Radio />}
              label="Use actual sizings as played and replace the closest default sizing"
              sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" } }}
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box
        sx={{
          minWidth: "160px",
          display: "flex",
          alignItems: "center",
          border: "1px solid #CCCCCC",
          borderRadius: "8px",
          p: 1,
          mt: { xs: 1, md: 0 },
        }}
      >
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            How to model post-flop pot sizings
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={config.potModelingConfig}
            onChange={handlePotChange}
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="USE_ACTUAL"
              control={<Radio />}
              label="Use actual pot size as played"
              // sx={{ whiteSpace: "nowrap" }}
              sx={{ "& .MuiFormControlLabel-label": { fontSize: "12px" } }}
            />
            <FormControlLabel
              value="APPROXIMATE_TO_CLOSEST_STACK"
              control={<Radio />}
              label="Approximate pot size based on closest preflop stack depth available"
              sx={{ "& .MuiFormControlLabel-label": { fontSize: "12px" } }}
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  )
}

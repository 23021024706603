import Box from "@mui/material/Box"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import { PageTitle } from "lib/Components/PageTitile"
import { useCallback, useMemo, useReducer, useState } from "react"
import { useGetStrategiesQuery } from "services/rangesApi"
import { RangeSet } from "services/types"
import { HandPosition } from "../../HHReplay/types"
import {
  ActionRatios,
  CATEGORY_ACTION,
  FilterAction,
  RangeParameters,
} from "../types"
import "./index.css"
import { RangeEditController } from "./RangeEdit"
import { RangeParametersView } from "./RangeParametersView"
import { ManageStrategiesToolbar } from "./RangesToolbar"
import { RangeController } from "./RangeView"

export interface CardInfo {
  cardId: string
  ratio?: ActionRatios
}

const initialState: RangeParameters = {
  category: "RFI",
}
export type ActionKind =
  | "stackSize"
  | "category"
  | "heroPosition"
  | "rangeset"
  | "filterActions"

type ReducerAction = {
  type: ActionKind
  payload: string | number | HandPosition | FilterAction[]
}

function reducer(
  state: RangeParameters,
  action: ReducerAction
): RangeParameters {
  const { type, payload } = action
  switch (type) {
    default:
      return {
        ...state,
        [type]: payload,
      }
  }
}

export interface RangeViewerProps {
  handleChange: (
    field: ActionKind,
    value: string | number | HandPosition | FilterAction[]
  ) => void
  parameters: RangeParameters
  strategies: RangeSet[]
}

function RangeViewer(props: RangeViewerProps) {
  const { handleChange, parameters, strategies } = props

  const filtersFilled =
    parameters.stackSize &&
    parameters.heroPosition &&
    parameters.rangeset &&
    (parameters.category !== CATEGORY_ACTION || parameters.filterActions)

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      <Box
        sx={{
          flexGrow: { xs: 0, md: 1 },
          minWidth: { sm: "450px" },
          display: "flex",
          alignItems: filtersFilled ? "flex-start" : "center",
          justifyContent: "center",
        }}
      >
        {(filtersFilled && <RangeController parameters={parameters} />) || (
          <Box>Please, select filters.</Box>
        )}
      </Box>

      <RangeParametersView
        handleChange={handleChange}
        state={parameters}
        strategies={strategies}
      />
    </Box>
  )
}

export interface RangeEditorProps {
  // handleChange: (field: ActionKind, value: string | HandPosition) => void
  handleChange: (
    field: ActionKind,
    value: string | number | HandPosition | FilterAction[]
  ) => void
  parameters: RangeParameters
  strategies: RangeSet[]
}

function RangeEditor(props: RangeEditorProps) {
  const { handleChange, parameters, strategies } = props
  const filtersFilled =
    parameters.stackSize && parameters.heroPosition && parameters.rangeset

  const rangeset = useMemo(() => {
    for (const current of strategies) {
      if (current.id == parameters.rangeset) return current
    }
    return undefined
  }, [strategies, parameters])

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      <Box
        sx={{
          flexGrow: { xs: 0, md: 1 },
          minWidth: "450px",
          display: "flex",
          alignItems: filtersFilled ? "flex-start" : "center",
          justifyContent: "center",
        }}
      >
        {(filtersFilled && <RangeEditController parameters={parameters} />) || (
          <Box>Please, select filters.</Box>
        )}
      </Box>
      {rangeset && (
        <RangeParametersView
          handleChange={handleChange}
          state={parameters}
          strategies={strategies}
        />
      )}
    </Box>
  )
}

export interface RangesProps {
  manageable?: boolean
}

export function Ranges(props: RangesProps) {
  // const { handle } = useParams()
  // console.log("XXXX", handle)
  const { manageable } = props
  const [parameters, dispatch] = useReducer(reducer, initialState)
  const [inEditor, switchToEditor] = useState(false)

  const showEditor = (event: React.ChangeEvent<HTMLInputElement>) => {
    switchToEditor(event.target.checked)
  }

  const {
    data: strategies,
    // isLoading,
    isSuccess: haveStrategies,
    // isError,
  } = useGetStrategiesQuery()

  const handleChange = useCallback(
    (
      field: ActionKind,
      value: string | number | HandPosition | FilterAction[]
    ) => {
      dispatch({
        type: field,
        payload: value,
      })
    },
    [dispatch]
  )

  if (!haveStrategies) {
    return <div>Loading...</div>
  }

  if (haveStrategies && strategies.length == 0) {
    return <div>No strategies defined in system...</div>
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflowY: "scroll",
      }}
    >
      <PageTitle text="Preflop Ranges" />
      {manageable && (
        <>
          <ManageStrategiesToolbar
            parameters={parameters}
            strategies={strategies}
          />
          <FormControlLabel
            control={
              <Switch
                checked={inEditor}
                onChange={showEditor}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Show Editor"
          />
        </>
      )}
      {!inEditor && (
        <RangeViewer
          strategies={
            manageable ? strategies : strategies.filter((v) => v.is_default)
          }
          parameters={parameters}
          handleChange={handleChange}
        />
      )}
      {inEditor && parameters.rangeset && (
        <RangeEditor
          strategies={
            manageable ? strategies : strategies.filter((v) => v.is_default)
          }
          parameters={parameters}
          handleChange={handleChange}
        />
      )}
    </Box>
  )
}

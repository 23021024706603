import { Box, ButtonProps } from "@mui/material"
import Button from "@mui/material/Button"
import { styled } from "@mui/system"
import {
  getActionString,
  getCurrentAction,
  getPrevPositionState,
} from "features/HHReplay/ActionPointer"
import {
  HandActionIndex,
  HHContext,
  hhContext,
} from "features/HHReplay/HHContext"
import { useGetPositionRange } from "features/HHReplay/hooks"
import {
  HandAction,
  HandActionChar,
  handFromString,
  HandPlayerStateInfo,
  HandState,
  handToString,
  stripHandToSection,
} from "features/HHReplay/types"
import { getNextAction } from "features/HHReplay/utils"
import { useContext, useMemo } from "react"

export const ActionButton = styled((props: ButtonProps) => (
  <Box sx={{ p: "2px" }}>
    <Button variant="contained" {...props} />
  </Box>
))((props) => ({
  textTransform: "none",
  "&.MuiButton-root": {
    fontSize: "10px",
    minWidth: { sm: "50px", md: "80px" },
    maxWidth: { sm: "50px", md: "80px" },
    whiteSpace: "nowrap",
    minHeight: "40px",
    borderRadius: "20px",
    flexDirection: "column",
    padding: "0px",
    [props.theme.breakpoints.down("sm")]: {
      fontSize: "8px",
      minWidth: "50px",
      maxWidth: "50px",
      minHeight: "30px",
      borderRadius: "15px",
    },
  },
  "&.MuiButton-root.MuiButton-contained": {
    backgroundColor: "#35654d",
  },
}))

export function playerAction(
  context: HHContext,
  action: HandAction,
  actionIndex: HandActionIndex,
  chr: HandActionChar,
  amount?: number
) {
  changeHandAction(context, actionIndex, {
    actionChar: chr,
    actionString: getActionString(chr),
    position: action.position,
    raisingAmount: amount || 0,
    state: action.state as HandState,
    isManual: true,
  } as HandAction)
}

export const changeHandAction = (
  context: HHContext,
  actionIndex: HandActionIndex,
  action: HandAction
) => {
  let hhString = handToString(context.hand)
  const actionPointer = actionIndex
  switch (actionPointer.street) {
    case "preflop":
      if (!context.hand.preFlop?.actions) return
      context.hand.preFlop.actions[actionPointer.index] = action
      context.hand.preFlop.actions.splice(actionPointer.index + 1)
      hhString = stripHandToSection("PREFLOP_ACTIONS", context.hand)
      break
  }
  const newHand = handFromString(context.hand.id, hhString)
  context.setHand(newHand)

  const next = getNextAction(actionPointer, newHand)
  if (next) {
    context.setCurrentActionIndex(next)
  } else {
    context.setCurrentActionIndex(actionIndex)
  }
}

export function CurrentTurnActions() {
  const context = useContext(hhContext)
  const action = getCurrentAction(context)

  const range = useGetPositionRange(
    action?.position || "SB",
    context.currentActionIndex
  )

  const recommendedRaise = range?.raiseSizing || 0

  const allowedActions = useMemo(() => {
    const rv: string[] = []
    for (const op of Object.keys(range?.operationCombos || {})) {
      if ((range?.operationCombos || {})[op] > 0) {
        rv.push(op)
      }
    }
    return rv
  }, [range])
  if (!action) return null

  const curentPlayerState: HandPlayerStateInfo | undefined =
    getPrevPositionState(context, context.currentActionIndex, action.position)
  // const actionPointer = context.currentActionIndex

  if (curentPlayerState === undefined) return null

  // const changeHandAction = (action: HandAction) => {
  //   let hhString = handToString(context.hand)
  //   switch (actionPointer.street) {
  //     case "preflop":
  //       if (!context.hand.preFlop?.actions) return
  //       context.hand.preFlop.actions[actionPointer.index] = action
  //       context.hand.preFlop.actions.splice(actionPointer.index + 1)
  //       hhString = stripHandToSection("PREFLOP_ACTIONS", context.hand)
  //       break
  //   }
  //   const newHand = handFromString(context.hand.id, hhString)
  //   context.setHand(newHand)

  //   const next = getNextAction(context.currentActionIndex, newHand)
  //   if (next) {
  //     context.setCurrentActionIndex(next)
  //   }
  // }

  function changeTo(action: HandAction, chr: HandActionChar, amount?: number) {
    playerAction(context, action, context.currentActionIndex, chr, amount)
    // if (action.actionChar == chr) {
    //   if (!amount || amount == action.raisingAmount) context.moveToNextAction()
    // } else {
    //   changeHandAction(context, {
    //     actionChar: chr,
    //     actionString: getActionString(chr),
    //     position: action.position,
    //     raisingAmount: amount || 0,
    //     state: action.state as HandState,
    //     isManual: true,
    //   } as HandAction)
    // }
  }

  return (
    <Box
      sx={{
        minHeight: "50px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {allowedActions.indexOf("FOLD") != -1 && (
        <ActionButton onClick={() => changeTo(action, "f")}>Fold</ActionButton>
      )}
      {allowedActions.indexOf("CALL") != -1 && (
        <ActionButton onClick={() => changeTo(action, "c")}>Call</ActionButton>
      )}
      {allowedActions.indexOf("RAISE") != -1 && (
        <>
          {recommendedRaise > 0 && (
            <ActionButton
              onClick={() => changeTo(action, "r", range?.raiseSizing || 0)}
              disabled={(range?.raiseSizing || 0) <= 0}
            >
              <Box>Raise {recommendedRaise}BB</Box>
            </ActionButton>
          )}
        </>
      )}
      {allowedActions.indexOf("CHECK") != -1 && (
        <ActionButton onClick={() => changeTo(action, "x")}>Check</ActionButton>
      )}
      {allowedActions.indexOf("ALL_IN") != -1 && (
        <ActionButton onClick={() => changeTo(action, "a")}>
          All In
        </ActionButton>
      )}
    </Box>
  )
}

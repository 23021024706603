import { Box } from "@mui/material"
import { useContext, useMemo } from "react"
import { RangeSet } from "services/types"
import { HandActionIndex, hhContext } from "./HHContext"
import { useGetDefaultStrategy } from "./hooks"
import { SpecifiedPositionRangeView } from "./PositionRangeView"
import {
  Hand,
  HandAction,
  HandPosition,
  HandPositionOrder,
  StreetSection,
} from "./types"

export function getCurrentSection(
  hand: Hand,
  actionIndex: HandActionIndex
): StreetSection | undefined {
  if (actionIndex === undefined) return undefined
  let streetSection: StreetSection | undefined = undefined
  switch (actionIndex.street) {
    case "preflop":
      streetSection = hand.preFlop
      break
    case "flop":
      streetSection = hand.flop
      break
    case "turn":
      streetSection = hand.turn
      break
    case "river":
      streetSection = hand.river
      break
  }
  return streetSection
}

export function getActionPosition(
  hand: Hand,
  action: HandActionIndex
): HandPosition | undefined {
  const streetSection: StreetSection | undefined = getCurrentSection(
    hand,
    action
  )
  if (!streetSection || !streetSection.actions) return undefined
  return streetSection.actions[action.index].position
}

export function checkActionPositionStillInGame(
  hand: Hand,
  action: HandActionIndex,
  currentAction: HandActionIndex
): boolean {
  const position = getActionPosition(hand, action)
  if (position === undefined) return true
  return positionInGame(hand, position, currentAction)
}

export function positionInGame(
  hand: Hand,
  position: HandPosition,
  action: HandActionIndex | undefined
): boolean {
  if (action === undefined) return true
  const streetSection: StreetSection | undefined = getCurrentSection(
    hand,
    action
  )
  if (!streetSection || !streetSection.actions) return true
  const streetActions: HandAction[] = streetSection.actions
  const playerStates = streetActions[action.index].state.playerStates || []
  return (
    ["IN_GAME", "ALL_IN"].indexOf(playerStates[position]?.state || "UNKNOWN") >=
      0 || streetActions[action.index].position == position
  )
}

export function HHARangesView({
  skipNotSuggested,
}: {
  skipNotSuggested: boolean
}) {
  const context = useContext(hhContext)
  const strategy = useGetDefaultStrategy()
  const actionsToShow = useMemo(() => {
    if (
      context.currentActionIndex === undefined ||
      context.currentActionIndex.street !== "preflop"
    )
      return []

    const rv: { action: number; position: HandPosition }[] = []

    if (
      context.currentActionIndex.index < 0 ||
      !context.hand.preFlop ||
      !context.hand.preFlop.actions
    )
      return []

    const actionPosition = getActionPosition(
      context.hand,
      context.currentActionIndex
    )

    if (!actionPosition) return []

    const inGamePositions = HandPositionOrder.filter((position) =>
      positionInGame(context.hand, position, context.currentActionIndex)
    )
    inGamePositions.sort(
      (a, b) => HandPositionOrder.indexOf(b) - HandPositionOrder.indexOf(a)
    )
    const orderedPositions = inGamePositions

    for (const position of orderedPositions) {
      rv.push({
        action: context.currentActionIndex.index,
        position: position,
      })
    }

    return rv
  }, [context])

  if (context.currentActionIndex.street !== "preflop") {
    return null
  }

  if (!strategy) {
    return null
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: { xs: "nowrap", sm: "wrap" },
        overflowX: { xs: "scroll", sm: "auto" },
        width: { xs: "calc(100vw - 8px)", sm: "auto" },
        scrollSnapType: "x mandatory",
      }}
    >
      {actionsToShow.map((v, idx) => {
        return (
          <SpecifiedPositionRangeView
            key={idx}
            strategy={strategy}
            actionIndex={{ street: "preflop", index: v.action }}
            actionPosition={v.position}
            skipNotSuggested={skipNotSuggested}
          />
        )
      })}
    </Box>
  )
}

export interface HHARangeViewProps {
  actionIndex: HandActionIndex
  actionPosition: HandPosition
  strategy: RangeSet
  skipNotSuggested: boolean
}

import { Box } from "@mui/material"
import { hhContext } from "features/HHReplay/HHContext"
import { HHARangesView } from "features/HHReplay/RangesView"
import { ScriptView } from "features/HHReplay/ScriptView"
// import { ScriptView } from "features/HHReplay/ScriptView"
import {
  AccordionInfo,
  AccordionLabel,
  AccordionWrapper,
} from "lib/Components/CustomAccordion"
import { useContext } from "react"
import { useSearchParams } from "react-router-dom"
import { useGetStrategiesQuery } from "services/rangesApi"
import { AnyScenarioGameParameters } from "./AnyScenatioGameParameters"
import { TableView } from "./TableView"

export function AnyScenarioExplorerView(): JSX.Element {
  const [searchParams] = useSearchParams()
  const showScript = searchParams.get("showScript") == "1"
  const context = useContext(hhContext)
  // const pfRangePath = getPFRangePath(context)

  const {
    data: strategies,
    // isLoading,
    isSuccess: haveStrategies,
    // isError,
  } = useGetStrategiesQuery()

  if (!strategies) return <></>

  const strategy = strategies.filter((v) => v.is_default)[0]
  if (!strategy) return <></>

  return (
    <>
      <AnyScenarioGameParameters strategy={strategy} />
      {/* <GameTreeView /> */}
      <Box
        className="overflow-check2"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflowY: "scroll",
          overflowX: "hidden",
          width: { xs: "100vw", sm: "100vw", md: "auto" },
        }}
      >
        {showScript && (
          <AccordionWrapper>
            <AccordionLabel>Script</AccordionLabel>
            <AccordionInfo>
              <ScriptView />
            </AccordionInfo>
          </AccordionWrapper>
        )}
        <AccordionWrapper defaultExpanded={true}>
          <AccordionLabel>Table</AccordionLabel>
          <AccordionInfo>
            <TableView />
          </AccordionInfo>
        </AccordionWrapper>
        <AccordionWrapper defaultExpanded={true}>
          <AccordionLabel>Range Explorer</AccordionLabel>
          <AccordionInfo>
            <Box
              sx={{ display: "flex", overflowX: "scroll", overflowY: "hidden" }}
            >
              {context.currentActionIndex.street == "preflop" && (
                <HHARangesView skipNotSuggested={true} />
              )}
            </Box>
          </AccordionInfo>
        </AccordionWrapper>
      </Box>
    </>
  )
}

import { Box } from "@mui/material"
import { useContext, useMemo } from "react"
import { getStreetHeader } from "./GameTreeView"
import { hhContext } from "./HHContext"
import { HandAction, HandStreet } from "./types"

const PlayerAction = ({ action }: { action: HandAction }) => {
  return (
    <Box
      className={`gtaction-${action.actionChar}`}
      sx={{
        m: "1px",
        p: "2px",
        border: "1px solid lightgrey",
        borderRadius: "4px",
        fontSize: "10px",
      }}
    >
      {action.position} {action.actionString}{" "}
      {action.raisingAmount ? parseFloat(action.raisingAmount.toFixed(2)) : ""}
    </Box>
  )
}

export const ActionPath = () => {
  const context = useContext(hhContext)

  const actions: { [key in HandStreet]: JSX.Element[] } = useMemo(() => {
    const handActions: { [key in HandStreet]: JSX.Element[] } = {
      preflop: [],
      flop: [],
      turn: [],
      river: [],
    }

    if (
      context.currentActionIndex.index == -1 &&
      context.currentActionIndex.street == "preflop"
    )
      return handActions

    const preflopActions = context.hand.preFlop?.actions || []
    const flopActions = context.hand.flop?.actions || []
    const turnActions = context.hand.turn?.actions || []
    const riverActions = context.hand.river?.actions || []

    handActions["preflop"] = preflopActions.map((action, idx) => (
      <PlayerAction key={"preflop" + idx} action={action} />
    ))
    handActions["flop"] = flopActions.map((action, idx) => (
      <PlayerAction key={"flop" + idx} action={action} />
    ))
    handActions["turn"] = turnActions.map((action, idx) => (
      <PlayerAction key={"turn" + idx} action={action} />
    ))
    handActions["river"] = riverActions.map((action, idx) => (
      <PlayerAction key={"river" + idx} action={action} />
    ))

    switch (context.currentActionIndex.street) {
      case "preflop":
        handActions["preflop"] = preflopActions
          .slice(0, context.currentActionIndex.index)
          .map((action, idx) => (
            <PlayerAction key={"preflop" + idx} action={action} />
          ))
        handActions["flop"] = []
        handActions["turn"] = []
        handActions["river"] = []
        break
      case "flop":
        handActions["flop"] = flopActions
          .slice(0, context.currentActionIndex.index)
          .map((action, idx) => (
            <PlayerAction key={"flop" + idx} action={action} />
          ))
        handActions["turn"] = []
        handActions["river"] = []
        break
      case "turn":
        handActions["turn"] = turnActions
          .slice(0, context.currentActionIndex.index)
          .map((action, idx) => (
            <PlayerAction key={"turn" + idx} action={action} />
          ))
        handActions["river"] = []
        break
      case "river":
        handActions["river"] = riverActions
          .slice(0, context.currentActionIndex.index)
          .map((action, idx) => (
            <PlayerAction key={"river" + idx} action={action} />
          ))
        break
    }
    return handActions
  }, [context])

  return (
    <>
      {actions.preflop.length > 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            minHeight: "30px",
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          <Box>{getStreetHeader("preflop")}:</Box>
          {actions["preflop"]}
        </Box>
      )}
      {actions.flop.length > 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            minHeight: "30px",
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          <Box sx={{ pr: "10px" }}>Postflop:</Box>
          {actions.flop.length > 0 && (
            <Box sx={{ display: "flex" }}>{actions.flop}</Box>
          )}
          {actions.turn.length > 0 && (
            <Box sx={{ display: "flex", pl: "10px" }}>turn: {actions.turn}</Box>
          )}
          {actions.river.length > 0 && (
            <Box sx={{ display: "flex", pl: "10px" }}>
              river: {actions.river}
            </Box>
          )}
        </Box>
      )}
    </>
  )
}

import GroupIcon from "@mui/icons-material/Group"
import { Avatar, Box, Button } from "@mui/material"
import { Link } from "react-router-dom"
import { FoundUser } from "services/types"

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = "#"

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  }
}

export function UserCard({ user }: { user: FoundUser }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "start",
        gap: "24px",
        maxWidth: "300px",
        borderRadius: "8px",
        border: "1px solid #dddddd",
        p: "8px",
        ":hover": {
          bgcolor: "#cccccc",
        },
      }}
    >
      <Box>
        {user.imageURL && <Avatar src={user.imageURL} />}
        {!user.imageURL && <Avatar {...stringAvatar(user.name || "N A")} />}
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Box>{user.name}</Box>
        {user.publicHandle && (
          <Box sx={{ display: "flex" }}>
            <Button
              component={Link}
              variant="text"
              to={`/@${user.publicHandle}`}
              sx={{
                textDecoration: "none",
                textTransform: "none",
                "&.MuiButton-root": {
                  p: 0,
                  minWidth: 0,
                },
              }}
            >
              @{user.publicHandle}
            </Button>
          </Box>
        )}
        {(user.followed || user.following) && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <GroupIcon />
            {user.followed && !user.following && <Box>Following</Box>}
            {user.following && !user.followed && <Box>Follows you</Box>}
            {user.followed && user.following && <Box>Friend</Box>}
          </Box>
        )}
      </Box>
    </Box>
  )
}

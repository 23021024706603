import { api } from "./api"
import {
  PostflopRange,
  PostflopRangeRequest,
  RangeInfo,
  RangeSet,
  RangesFilter,
  SaveRangeRequest,
  SimulationTreeInfo,
} from "./types"

const rangesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStrategies: builder.query<RangeSet[], void>({
      query: () => "api/preflop/rangeset/",
      keepUnusedDataFor: 3600,
      providesTags: ["RangeStretegies"],
    }),
    deleteStrategy: builder.mutation<void, string>({
      query: (strategyId: string) => ({
        url: `api/preflop/rangeset/${strategyId}`,
        method: "delete",
      }),
      invalidatesTags: ["RangeStretegies"],
    }),
    cloneStrategy: builder.mutation<
      RangeSet,
      Partial<RangeSet> & Pick<RangeSet, "id">
    >({
      query: ({ id, ...patch }) => ({
        url: `api/preflop/rangeset/${id}/clone`,
        method: "post",
        body: patch,
      }),
      invalidatesTags: ["RangeStretegies"],
    }),
    changeStrategy: builder.mutation<
      RangeSet,
      Partial<RangeSet> & Pick<RangeSet, "id">
    >({
      query: ({ id, ...patch }) => ({
        url: `api/preflop/rangeset/${id}`,
        method: "POST",
        body: patch,
      }),
      invalidatesTags: ["RangeStretegies"],
    }),
    saveRange: builder.mutation<RangeInfo, SaveRangeRequest>({
      query: (body) => ({
        url: "api/preflop/range/save",
        method: "post",
        body,
      }),
    }),
    deleteStackSize: builder.mutation<void, { rangeset: string; size: string }>(
      {
        query: ({ rangeset, size }) => ({
          url: `api/preflop/rangeset/${rangeset}/stacksize/${size}`,
          method: "delete",
        }),
        invalidatesTags: ["RangeStretegies"],
      }
    ),
    getPostflopRange: builder.query<PostflopRange, PostflopRangeRequest>({
      query: ({ id, path }) => ({
        url: `api/postflop/range/${id}/`,
        keepUnusedDataFor: 3600,
        params: { path },
      }),
    }),
    getRangeByActions: builder.query<RangeInfo, RangesFilter>({
      query: (filter) => ({
        url: "api/preflop/range_by_path",
        keepUnusedDataFor: 3600,
        params: filter,
      }),
    }),
    getRange: builder.query<RangeInfo, RangesFilter>({
      query: (filter) => ({
        url: "api/preflop/range",
        keepUnusedDataFor: 3600,
        params: filter,
      }),
    }),
    getSimulationTreeInfo: builder.query<SimulationTreeInfo, string>({
      query: (id) => ({
        url: `/api/postflop/range/${id}/tree_info`,
        keepUnusedDataFor: 3600,
      }),
    }),
    getRangesByActions: builder.query<RangeInfo[], RangesFilter>({
      query: (filter) => ({
        url: "api/preflop/ranges_by_paths",
        keepUnusedDataFor: 3600,
        params: filter,
      }),
    }),
  }),
})

export const {
  useGetStrategiesQuery,
  useDeleteStrategyMutation,
  useCloneStrategyMutation,
  useChangeStrategyMutation,
  useSaveRangeMutation,
  useDeleteStackSizeMutation,
  useGetPostflopRangeQuery,
  useGetRangeQuery,
  useGetRangeByActionsQuery,
  useGetSimulationTreeInfoQuery,
} = rangesApi

import { Box } from "@mui/material"
import { styled } from "@mui/system"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { useMemo } from "react"
import { useGetSimulationTreeInfoQuery } from "services/rangesApi"
import { PostFlopStreetsSizing } from "../../../../services/types"
import { BetSizingValue } from "../../../BetSizing/BetSizing"
import "../index.css"
import { getRows } from "../PFRangeView"

interface SimulationTreeInfoView {
  hhId: string
}

export function SimulationTreeInfoView(props: SimulationTreeInfoView) {
  const simulationTreeInfoQuery = useGetSimulationTreeInfoQuery(props.hhId)
  const oopRows = useMemo(() => {
    return getRows(
      simulationTreeInfoQuery.data ? simulationTreeInfoQuery.data.oopRange : {},
      undefined,
      () => void 0
    )
  }, [simulationTreeInfoQuery])

  const ipRows = useMemo(() => {
    return getRows(
      simulationTreeInfoQuery.data ? simulationTreeInfoQuery.data?.ipRange : {},
      undefined,
      () => void 0
    )
  }, [simulationTreeInfoQuery])

  const betSizes = simulationTreeInfoQuery.data
    ? simulationTreeInfoQuery.data?.betSizes
    : undefined

  const rows = useMemo(() => {
    if (!betSizes) return []
    const rv = []
    rv.push({ id: 1, street: "flop", range: betSizes })
    rv.push({ id: 2, street: "turn", range: betSizes })
    rv.push({ id: 3, street: "river", range: betSizes })
    return rv
  }, [betSizes])

  const OperationInfo = styled(Box)({
    height: "1.5rem",
    lineHeight: "1.5rem",
  })

  const columns: GridColDef[] = []
  columns.push({
    field: "street",
    width: 180,
    editable: false,
    hide: false,
    sortable: false,
    disableColumnMenu: true,
    disableReorder: true,
    renderHeader: () => <Box />,
    renderCell(params) {
      return (
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "stretch",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
            }}
          >
            {params.value}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <OperationInfo>OOP</OperationInfo>
            <OperationInfo>IP</OperationInfo>
          </Box>
          <Box>
            <OperationInfo>Bet</OperationInfo>
            <OperationInfo>Raise</OperationInfo>
            <OperationInfo>Bet</OperationInfo>
            <OperationInfo>Raise</OperationInfo>
          </Box>
        </Box>
      )
    },
  })
  if (betSizes) {
    columns.push({
      field: `range`,
      flex: 1,
      editable: false,
      hide: false,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      renderHeader: () => <Box />,
      renderCell(params) {
        const street = params.row.street as string
        const cellValue =
          betSizes[street.toUpperCase() as keyof PostFlopStreetsSizing]
        return <BetSizingValue value={cellValue} street={street} />
      },
    })
  }

  return (
    <Box sx={{ display: "flex", overflowX: "scroll", overflowY: "hidden" }}>
      <Box>
        <Box sx={{ textAlign: "center" }}>OOP range</Box>
        <Box className="pfrange" sx={{ pt: 0.5 }}>
          {oopRows}
        </Box>
      </Box>
      <Box sx={{ minWidth: "10px" }}></Box>
      <Box>
        <Box sx={{ textAlign: "center" }}>IP range</Box>
        <Box className="pfrange" sx={{ pt: 0.5 }}>
          {ipRows}
        </Box>
      </Box>
      <Box sx={{ minWidth: "10px" }}></Box>
      <Box>
        <Box sx={{ textAlign: "center" }}>Bet sizing</Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            minWidth: "400px",
            paddingTop: "4px",
          }}
        >
          <DataGrid
            rows={rows}
            autoHeight={true}
            disableExtendRowFullWidth={true}
            rowHeight={100}
            columns={columns}
            showCellRightBorder={true}
            showColumnRightBorder={true}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            hideFooter={true}
            headerHeight={0}
            sx={{
              "& .uiDataGrid-footerContainer": {
                display: "none",
              },
              "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
                fontWeight: "bold",
              },
              "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                flexGrow: 1,
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

import { Box, useMediaQuery, useTheme } from "@mui/material"
import { RangeView } from "features/Ranges/preflop/RangeView"
import {
  Action,
  ACTION_CHARS,
  CATEGORY_ACTION,
  CHAR_ACTIONS,
  FilterAction,
  RangeParameters,
} from "features/Ranges/types"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useGetRangeByActionsQuery } from "services/rangesApi"
import { RangeInfo, RangesFilter } from "services/types"
import { getCurrentAction } from "./ActionPointer"
import { hhContext } from "./HHContext"
import { HHARangeViewProps } from "./RangesView"

interface PreflopRangeViewFilter {
  pathActions: FilterAction[]
  rangeActionFilter: Action | undefined
}

export function SpecifiedPositionRangeView(props: HHARangeViewProps) {
  const { actionIndex, actionPosition, strategy, skipNotSuggested } = props
  const context = useContext(hhContext)
  const action = getCurrentAction(context)
  const currentRange = useRef<HTMLDivElement | null>(null)
  const [range, setRange] = useState<RangeInfo | undefined>()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"))

  const [requestFilter, setFilter] = useState<RangesFilter | undefined>()
  const playerStackSize =
    context.hand.beginningState?.playerStates[actionPosition]?.stack

  const currentAction =
    context.currentActionIndex.index >= 0 &&
    context.currentActionIndex.index == actionIndex.index &&
    actionPosition == action?.position

  const rangeByActionsQuery = useGetRangeByActionsQuery(requestFilter || {}, {
    skip: requestFilter === undefined,
  })

  const filterActions: PreflopRangeViewFilter = useMemo(() => {
    const emptyResult: PreflopRangeViewFilter = {
      pathActions: [],
      rangeActionFilter: undefined,
    }
    if (actionIndex === undefined) return emptyResult
    if (actionIndex.index < 0) return emptyResult

    const state = context.hand.preFlop?.actions[actionIndex.index].state
    const ps = state ? state.playerStates[actionPosition]! : undefined
    const filter = ps ? ps.rangeFilters!.preflop : undefined

    if (!filter) return emptyResult

    const result: PreflopRangeViewFilter = {
      pathActions: filter.path.map((action) => {
        const rv: FilterAction = {
          position: action.position,
          action: CHAR_ACTIONS[action.actionChar] || "FOLD",
          raiseAmount: action.raisingAmount ? action.raisingAmount : undefined,
        }
        return rv
      }),
      rangeActionFilter: filter.actionChar
        ? CHAR_ACTIONS[filter.actionChar]
        : undefined,
    }
    return result
  }, [context.hand.preFlop?.actions, actionIndex, actionPosition])

  const filter = useMemo(() => {
    if (
      strategy &&
      strategy.id &&
      playerStackSize &&
      filterActions !== undefined
    ) {
      const closestStackSize = strategy.sizes.reduce(function (prev, curr) {
        return Math.abs(curr.size - playerStackSize) <
          Math.abs(prev.size - playerStackSize)
          ? curr
          : prev
      })

      return {
        rangeset: strategy?.id,
        stackSize: closestStackSize.size,
        heroPosition: actionPosition,
        category: CATEGORY_ACTION,
        filterActions: filterActions.pathActions,
      } as RangeParameters
    }
  }, [strategy, playerStackSize, actionPosition, filterActions])

  useEffect(() => {
    if (
      filter != undefined &&
      filter.category &&
      filter.heroPosition &&
      filter.rangeset &&
      filter.stackSize
    ) {
      setFilter({
        rangeset_id: filter.rangeset,
        stack_size: filter.stackSize,
        position: filter.heroPosition,
        path: filter.filterActions
          ? filter.filterActions
              .map(
                (x) =>
                  x.position +
                  " " +
                  ACTION_CHARS[x.action] +
                  (x.action === "RAISE" ? x.raiseAmount : "")
              )
              .join(", ")
          : "",
      })
    }
  }, [filter, setFilter, actionPosition])

  useEffect(() => {
    if (
      mobileView &&
      currentRange.current != null &&
      rangeByActionsQuery.data
    ) {
      currentRange.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      })
    }
  })

  useEffect(() => {
    if (rangeByActionsQuery.data !== undefined)
      setRange(rangeByActionsQuery.data)
  }, [rangeByActionsQuery.data, actionPosition])

  if (range === undefined) {
    return null
  }

  if (rangeByActionsQuery.error) {
    setRange(undefined)
    return null // TODO?
  }

  return (
    <Box
      className={`${currentAction ? "currentActionRange" : ""}`}
      sx={{
        maxWidth: { xs: "calc(100vw - 48px)", sm: "450px" },
        width: { xs: "calc(100vw - 48px)", sm: "450px" },
        flexShrink: 0,
        scrollSnapAlign: "center",
        display: "flex",
      }}
    >
      <div ref={currentAction ? currentRange : null}></div>
      <RangeView
        rangeInfo={range}
        parameters={filter || {}}
        actionFilter={filterActions.rangeActionFilter}
        skipNotSuggested={skipNotSuggested}
      />
    </Box>
  )
}

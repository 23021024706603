import { HHReplay } from "features/HHReplay"
import "features/HHReplay/styles.css"
import { AnyScenarioExplorerView } from "./AnyScenarioExplorerView"
import { CommonSpotsExplorerView } from "./CommonSpotsExplorerView"

export function AnyScenario() {
  const defaultStackSize = 30
  const str = `8-handed;BB ${defaultStackSize},
  SB ${defaultStackSize},
  BTN ${defaultStackSize},
  CO ${defaultStackSize},
  HJ ${defaultStackSize},
  LJ ${defaultStackSize},
  UTG7 ${defaultStackSize},
  UTG8 ${defaultStackSize};
  SB 0.5, BB 1;
  SB AcKc;`
  return (
    <HHReplay data={str}>
      <AnyScenarioExplorerView />
    </HHReplay>
  )
}

export function CommonSpotsScenario() {
  const defaultStackSize = 30
  const str = `8-handed;BB ${defaultStackSize},
  SB ${defaultStackSize},
  BTN ${defaultStackSize},
  CO ${defaultStackSize},
  HJ ${defaultStackSize},
  LJ ${defaultStackSize},
  UTG7 ${defaultStackSize},
  UTG8 ${defaultStackSize};
  SB 0.5, BB 1;
  SB AcKc;`
  return (
    <HHReplay data={str}>
      <CommonSpotsExplorerView />
    </HHReplay>
  )
}

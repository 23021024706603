import { Box } from "@mui/material"
import { useContext } from "react"
import { hhContext } from "./HHContext"
import { HHProp } from "./HHHeader"

export function HHInfo() {
  const context = useContext(hhContext)
  const position = context.hand.hero?.heroPosition
  if (!position) return null
  const nick = context.hand.nicknames?.[position]
  const stack = context.hand.beginningState?.playerStates[position]?.stack
  const pot = context.hand.lastState?.pot.toFixed(2)
  const ts = new Date(context.hand.game?.playedOn || new Date())
  return (
    <>
      {context.hand.id && (
        <Box sx={{ display: "flex" }}>
          <Box
            className="hh-info"
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: "250px",
              maxWidth: "400px",
            }}
          >
            <HHProp label="ID" value={context.hand.id} />
            <HHProp label="Type" value={context.hand.game?.gameKind} />
            <HHProp label="Tournament" value={context.hand.game?.tournament} />
            <HHProp label="Date" value={ts.toLocaleString()} />
            <HHProp
              label="Player"
              value={`${nick} ${context.hand.hero?.heroPosition} ${stack}BB`}
            />
            <HHProp label="Final pot" value={`${pot}BB`} />
          </Box>
          {/* <Box sx={{ flexGrow: 1 }} />
          <Box>
            <HandSharingView handle={context.hand.id} />
          </Box> */}
        </Box>
      )}
    </>
  )
}

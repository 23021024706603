import { Box } from "@mui/material"
import { cardToEmoji } from "features/HandHistories/HandHistories"
import { hhContext } from "features/HHReplay/HHContext"
import { HandPosition } from "features/HHReplay/types"
import { Cards } from "features/types"
import { useCallback, useContext, useMemo, useState } from "react"
import { PFCardPair, PostflopRange } from "services/types"
import { getCellId, getPFCellBackground } from "../utils"
import "./index.css"
import { comparePair } from "./PFStrategyView"

export type PFRANGE_TYPE = "IP" | "OOP" | "STRATEGY"

interface PFRangeViewProps {
  range: PostflopRange | null
  rangeType: PFRANGE_TYPE
  position: HandPosition
  actor: boolean
}

export const cardInBoard = (board: string, card: string): boolean => {
  const boardCards = [
    board.substring(0, 2),
    board.substring(2, 4),
    board.substring(4, 6),
    board.substring(6, 8),
    board.substring(8, 10),
  ]
  return boardCards.indexOf(card) >= 0
}

export const pairInBoard = (board: string, pair: string): boolean => {
  const boardCards = [
    board.substring(0, 2),
    board.substring(2, 4),
    board.substring(4, 6),
    board.substring(6, 8),
    board.substring(8, 10),
  ]
  const pairCards = [pair.substring(0, 2), pair.substring(2, 4)]
  return (
    boardCards.indexOf(pairCards[0]) >= 0 ||
    boardCards.indexOf(pairCards[1]) >= 0
  )
}

export function getRows(
  ratios: { [key in string]: number },
  currentCell: string | undefined,
  selectCellCallback: (cellId: string | undefined) => void
) {
  const rows = []
  for (let i = 0; i < Cards.length; i++) {
    const cells = []
    for (let j = 0; j < Cards.length; j++) {
      const cellId: string = getCellId(i, j)
      const ratio: number = ratios[cellId] ? ratios[cellId] : 0
      const cell = (
        <Box
          onClick={() => selectCellCallback(cellId)}
          key={cellId}
          className={`pfrange-cell ${
            cellId == currentCell ? "pfrange-selected" : ""
          }`}
          style={{
            background: getPFCellBackground(
              ratio,
              i == j ? "#cccccc" : "#aaaaaa",
              "#90dd90"
            ),
          }}
        >
          <Box sx={{ flexGrow: 1 }}>{cellId}</Box>
          {ratio > 0 && <Box sx={{ alignSelf: "end" }}>{ratio.toFixed(2)}</Box>}
        </Box>
      )
      cells.push(cell)
    }
    const row = (
      <div key={i} className="pfrange-row">
        {cells}
      </div>
    )
    rows.push(row)
  }
  return rows
}

export function PFRangeView(props: PFRangeViewProps) {
  const context = useContext(hhContext)
  const { range, rangeType, position, actor } = props
  const [currentCell, setCurrentCell] = useState<string | undefined>("AA")

  const selectCell = useCallback(
    (card: string | undefined) => {
      setCurrentCell(card)
    },
    [setCurrentCell]
  )

  const rows = useMemo(() => {
    if (range !== null) {
      const ratios: { [key in string]: number } = {}
      Object.entries(range.rangeGroups).forEach(
        ([key, value]) =>
          (ratios[key] = rangeType == "IP" ? value.ipRange : value.oopRange)
      )
      return getRows(ratios, currentCell, selectCell)
    }
  }, [range, rangeType, selectCell, currentCell])

  const PairInfo = ({ pair }: { pair: PFCardPair }) => {
    const ratio = rangeType == "IP" ? pair.ipRangeInfo : pair.oopRangeInfo
    return (
      <Box
        sx={{
          my: "1px",
          display: "flex",
          flex: 1,
          border: "1px solid gray",
          background: getPFCellBackground(
            ratio.probability,
            "#cccccc",
            "#90dd90"
          ),
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          {cardToEmoji(pair.hand.substring(0, 2), "streetCard")}
          {cardToEmoji(pair.hand.substring(2, 4), "streetCard")}
        </Box>
        {!pairInBoard(range?.board || "", pair.hand) && (
          <Box sx={{ alignSelf: "end" }}>{ratio.probability.toFixed(2)}</Box>
        )}
      </Box>
    )
  }

  const CellInfo = () => {
    if (!currentCell || range === null) return null

    const pairs = range.rangeGroups[currentCell].hands
    const pairKeys = Object.keys(pairs).sort(comparePair)
    const nPairs = pairKeys.length

    const rows: JSX.Element[] = []
    if (nPairs % 3 == 0) {
      for (let i = 0; i < nPairs; i += 3) {
        const row = []
        let key = pairKeys[i]
        row.push(<PairInfo key={key} pair={pairs[key]} />)
        key = pairKeys[i + 1]
        row.push(<PairInfo key={key} pair={pairs[key]} />)
        key = pairKeys[i + 2]
        row.push(<PairInfo key={key} pair={pairs[key]} />)
        rows.push(
          <Box key={i} sx={{ display: "flex" }}>
            {row}
          </Box>
        )
      }
    } else if (nPairs % 2 == 0) {
      for (let i = 0; i < nPairs; i += 2) {
        const row = []
        let key = pairKeys[i]
        row.push(<PairInfo key={key} pair={pairs[key]} />)
        key = pairKeys[i + 1]
        row.push(<PairInfo key={key} pair={pairs[key]} />)
        rows.push(
          <Box key={i} sx={{ display: "flex" }}>
            {row}
          </Box>
        )
      }
    }

    return <Box>{rows}</Box>
  }

  const nick = context.hand?.nicknames?.[position]

  return (
    <Box className={`${actor ? "activePlayer" : ""}`}>
      <Box
        sx={{ textAlign: "center" }}
        className={`${actor ? "actorPFRange" : ""}`}
      >
        {rangeType} range ({position}){" "}
        <span className="pfrange_nick">{nick}</span>
      </Box>
      <Box className="pfrange" sx={{ pt: 0.5 }}>
        {rows}
      </Box>
      <CellInfo />
    </Box>
  )
}

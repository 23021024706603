import { AccountCircle } from "@mui/icons-material"
import MenuIcon from "@mui/icons-material/Menu"
import { AppBar, Menu, MenuItem, Toolbar, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { User } from "services/types"
import { useLogoutMutation } from "services/userApi"
import { menuItems } from "./LeftNavigation"
import { NotificationBadge } from "./NotificationBadge"

export function TopBar({ user }: { user: User }) {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [logout] = useLogoutMutation()
  const handleLogout = () => {
    logout({}).then(() => navigate("/signin"))
  }

  const [menuShown, showMenu] = useState(false)

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return
      }
      showMenu(open)
    }

  const topMenu = () => (
    <Box
      sx={{ width: "auto" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuItems.map((menuItem) => {
          return (
            <ListItem key={menuItem.link} disablePadding>
              <ListItemButton to={menuItem.link} component={NavLink}>
                <ListItemIcon>{menuItem.icon}</ListItemIcon>
                <ListItemText primary={menuItem.label} />
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
    </Box>
  )

  return (
    <AppBar
      position="static"
      sx={{
        "&.MuiAppBar-root": {
          background: "#666666",
        },
      }}
    >
      <Toolbar>
        <IconButton
          aria-label="delete"
          color="primary"
          onClick={toggleDrawer(true)}
          sx={{ color: "white", display: { sm: "block", md: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor={"top"} open={menuShown} onClose={toggleDrawer(false)}>
          {topMenu()}
        </Drawer>

        <Box sx={{ flexGrow: 1 }}></Box>
        <Box sx={{ pr: 2 }}>
          <NotificationBadge />
        </Box>

        <Box>
          <Typography variant="h6" pr={2} sx={{ lineHeight: 1 }}>
            {user.name}
          </Typography>
          {user.username && (
            <Typography variant="body2" pr={2}>
              @{user.username}
            </Typography>
          )}
        </Box>

        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            sx={{
              height: "40px",
              "&.MuiIconButton-root": {
                padding: 0,
              },
            }}
          >
            {user.imageUrl && (
              <img
                src={user.imageUrl}
                style={{ height: "100%", borderRadius: "8px" }}
              />
            )}
            {!user.imageUrl && <AccountCircle />}
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              component={NavLink}
              to={user.username ? `/@${user.username}` : "/profile"}
              onClick={handleClose}
            >
              Details
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  )
}

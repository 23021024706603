import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { Alert, Box } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import classnames from "classnames"
import { CardSelector, SelectedCardView } from "features/HHReplay/CardSelector"
import {
  HandActionChar,
  PostflopHandPositionOrder,
} from "features/HHReplay/types"
import {
  sortedOperations,
  StrategyOperation,
} from "features/Ranges/postflop/PostflopRangeView"
import { pairToCombo } from "features/types"
import { ReactElement, useEffect, useMemo, useState } from "react"
import { useGetPostflopRangeQuery } from "services/rangesApi"
import {
  getErrorData,
  PFActionPath,
  PostflopRange,
  PostflopRangeRequest,
} from "services/types"
import { betColors, opColors } from "../PFStrategyView"
import { gameEnded } from "../RangeHeader"
import "./tree.css"
import { PlayerInfo, PopoverRangeInfo } from "./TreeView"

const playerAction = (range: PostflopRange): boolean => {
  return range.rangeGroups[22].strategy !== null
}

interface TreeNodeControllerProps {
  simId: string
  path: string
  title?: string | ReactElement
  open: boolean
  loadChildren?: boolean
  collapsable?: boolean
  op?: HandActionChar
  operation?: StrategyOperation
  color?: string
  card?: string
  totalCombos?: number
  board?: string
  pot: number
  players: PlayerInfo[]
  oopTurn: boolean
  showDetails: (
    event: React.MouseEvent<HTMLDivElement>,
    rangeInfo: PopoverRangeInfo
  ) => void
}

export function TreeNodeController(props: TreeNodeControllerProps) {
  const {
    simId,
    path,
    title,
    open,
    op,
    color,
    operation,
    totalCombos,
    loadChildren,
    collapsable,
    card,
    board,
    pot,
    players,
    oopTurn,
    showDetails,
  } = props

  const [expanded, setExpanded] = useState(open)

  useEffect(() => setExpanded(open), [open, setExpanded])

  const query = useGetPostflopRangeQuery(
    {
      id: simId,
      path: path,
    },
    { skip: !expanded && !loadChildren }
  )

  const { data, isLoading, error } = query

  const errorData = getErrorData<PostflopRangeRequest>(error)
  const msg = errorData?.errors
    ? errorData?.errors[0]?.defaultMessage
    : errorData?.message

  return (
    <TreeNode
      range={data}
      open={expanded}
      loading={isLoading}
      collapsable={collapsable}
      title={title}
      simId={simId}
      path={path}
      op={op}
      color={color}
      operation={operation}
      card={card}
      totalCombos={totalCombos}
      setExpanded={(open: boolean) => setExpanded(open)}
      board={board}
      pot={pot}
      error={msg}
      players={players}
      oopTurn={oopTurn}
      showDetails={showDetails}
    />
  )
}

interface TreeNodeProps {
  setExpanded: (open: boolean) => void
  range: PostflopRange | undefined
  title?: string | ReactElement
  open: boolean
  loading: boolean
  simId: string
  path: string
  op?: HandActionChar
  collapsable?: boolean
  color?: string
  totalCombos?: number
  operation?: StrategyOperation
  card?: string
  board?: string
  pot: number
  error?: string
  players: PlayerInfo[]
  oopTurn: boolean
  showDetails: (
    event: React.MouseEvent<HTMLDivElement>,
    rangeInfo: PopoverRangeInfo
  ) => void
}

const streetActionPath = (path: string): string => {
  const pathActions = path.split(",").reverse()
  const streetPath: string[] = []
  pathActions.every((action) => {
    if (["c", "x", "b", "r", "f"].indexOf(action[0]) < 0) {
      // card selection?
      return false
    }
    streetPath.push(action)
    return true
  })
  return streetPath.reverse().join(",")
}

export function TreeNode(props: TreeNodeProps) {
  const {
    range,
    title,
    open,
    simId,
    path,
    setExpanded,
    op,
    color,
    operation,
    totalCombos,
    collapsable,
    card,
    board,
    pot,
    loading,
    error,
    players,
    oopTurn,
    showDetails,
  } = props

  const streetPot: number = useMemo(
    () =>
      players.reduce(
        (accumulator, player) => accumulator + player.streetBid,
        pot
      ),
    [players, pot]
  )

  const effectiveStack = Math.min(
    ...players.map((p) => p.initialStreetStack - p.streetBid)
  )
  const spr = effectiveStack / streetPot

  const whichTurnNext = useMemo(() => {
    const streetPlayerActions = streetActionPath(path)
      .split(",")
      .filter((v) => v.length > 0)

    // if oop ends the street it acts again
    let ipoopTurn = streetPlayerActions.length % 2 == 0 ? "OOP" : "IP"
    if (ipoopTurn == "IP" && operation?.op == "c") {
      // street finished by OOP and oop turn again
      ipoopTurn = "OOP"
    }
    return streetPlayerActions.length % 2 == 0 ? "OOP" : "IP"
  }, [path, operation])

  const operations = useMemo(() => {
    if (!range) return

    const cardSelection = !playerAction(range)
    if (cardSelection) {
      return []
    }

    const rv: Record<string, number> = {}
    const combos = Object.keys(range?.rangeGroups || {})
    for (const group of combos) {
      const hands = range?.rangeGroups[group].hands
      const pairs = Object.keys(hands)
      for (const pair of pairs) {
        const strategy = range?.rangeGroups[group].hands[pair].strategy
        const handProbability =
          whichTurnNext == "IP"
            ? range.rangeGroups[group].hands[pair].ipRangeInfo.probability
            : range.rangeGroups[group].hands[pair].oopRangeInfo.probability

        const operations = Object.keys(strategy)
        for (const op of operations) {
          const opRatio = strategy[op]
          rv[op] = (rv[op] || 0) + opRatio * handProbability
        }
      }
    }
    return sortedOperations(rv)
  }, [range, whichTurnNext])

  const position: PlayerInfo | undefined = useMemo(() => {
    if (!whichTurnNext) return

    if (oopTurn)
      return players.sort((a, b) =>
        PostflopHandPositionOrder.indexOf(a.position) >
        PostflopHandPositionOrder.indexOf(b.position)
          ? -1
          : 1
      )[0]

    return players.sort((a, b) =>
      PostflopHandPositionOrder.indexOf(b.position) >
      PostflopHandPositionOrder.indexOf(a.position)
        ? -1
        : 1
    )[0]
  }, [whichTurnNext, players, oopTurn])

  function getOpPath(pfaction: PFActionPath, op: string) {
    let action: PFActionPath | null = pfaction
    const path: string[] = []
    while (action && action.action !== null) {
      path.push(action.action)
      action = action.parent
    }
    path.reverse().push(op)
    return path.join(",")
  }

  const opCombos =
    operations?.reduce((accumulator, op) => accumulator + op.ratio, 0) || 0
  const gameFinished = range !== undefined && gameEnded(range)

  let opColorIdx = 0
  const showNode = !operation || (operation && operation.op != "f")
  const linkWidth = Math.max(
    ((operation?.ratio || 1) / (totalCombos || 1326)) * 0.5,
    0.0625
  )
  const cardSelection = range && operations && operations.length == 0
  const playerHasActions = open && range && operations && operations.length > 0

  return (
    <Box component="li">
      <Box
        component="span"
        sx={{
          display: "flex",
          alignItems: "center",
          "&.link-w1:before": {
            borderTopWidth: `${linkWidth}em`,
            transform: "translate(0, -50%)",
          },
        }}
        className={classnames("tf-nc", {
          [`op-${op}`]: op,
          ["link-w1"]: operation !== undefined,
        })}
      >
        {operation && totalCombos && (
          <>
            <NodeLink
              color={color}
              percent={(operation.ratio * 100) / totalCombos}
              title={`${operation.op}${operation.value}`}
              hasNode={showNode}
            />
          </>
        )}
        {showNode && (
          <Box
            className={classnames({
              ["node"]: true,
              ["error-node"]: error,
              ["loading"]: loading,
              [`op-${op}`]: op,
              ["street-switch"]: card,
              ["player-action"]: !card,
            })}
            onClick={() => collapsable && setExpanded(!open)}
            sx={{ cursor: gameFinished ? "default" : "pointer" }}
          >
            {open && error && <ErrorIndicator error={error} />}
            {loading && <LoadingIndicator />}
            {!card && position && (
              <>
                <Box>{title}</Box>
                <>
                  <Box
                    sx={{ display: "flex", gap: "0.5em", alignItems: "center" }}
                  >
                    <Box component="span" className="ipoop">{`${
                      oopTurn ? "OOP" : "IP"
                    }`}</Box>
                    <Box
                      component="span"
                      className="player-position"
                    >{`(${position.position})`}</Box>
                    <Box component="span" className="player-stack">
                      {`${position.initialStreetStack - position.streetBid}BB`}
                    </Box>
                    {range && !cardSelection && (
                      <Box
                        onClick={(e) =>
                          showDetails(e, {
                            path: path,
                            range: range,
                            position: position.position,
                            ipoop: oopTurn ? "OOP" : "IP",
                            combo: position.cards
                              ? pairToCombo(position.cards)
                              : undefined,
                          })
                        }
                        sx={{
                          width: "1.5em",
                          height: "1.5em",
                        }}
                      >
                        <InfoOutlinedIcon
                          sx={{
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </>
                <Box className="player-pot">Pot {streetPot || "?"}BB</Box>
                <Box className="player-spr">SPR {spr.toFixed(2)}</Box>
              </>
            )}
            {card && position && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SelectedCardView card={card} />
                {range && (
                  <Box
                    onClick={(e) =>
                      showDetails(e, {
                        path: path,
                        range: range,
                        position: position.position,
                        ipoop: oopTurn ? "OOP" : "IP",
                      })
                    }
                    sx={{
                      width: "1.5em",
                      height: "1.5em",
                    }}
                  >
                    <InfoOutlinedIcon
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
      {playerHasActions && (
        <Box component="ul">
          {operations.map((op, idx) => {
            const opPath = getOpPath(range.actionPath, op.op + op.value)

            const currentPosition = {
              ...players.filter((v) => v.position == position?.position)[0],
            }
            const opponentPosition = players.filter(
              (v) => v.position != position?.position
            )[0]
            if (["b", "r"].indexOf(op.op) >= 0) {
              currentPosition.streetBid = Number(op.value)
            }
            if (["c"].indexOf(op.op) >= 0) {
              currentPosition.streetBid = opponentPosition.streetBid
            }
            const newState = [opponentPosition, currentPosition]

            return (
              <TreeNodeController
                key={`op-${idx}`}
                simId={simId}
                open={false}
                loadChildren={true}
                path={opPath}
                op={op.op}
                operation={op}
                collapsable={true}
                totalCombos={opCombos}
                color={opColors[op.op] || betColors[opColorIdx++]}
                pot={pot}
                players={newState}
                oopTurn={oopTurn && op.op == "c" ? oopTurn : !oopTurn} // oop ends the street 'x, b, c' he acts again!
                showDetails={showDetails}
              />
            )
          })}
        </Box>
      )}
      {open && cardSelection && !gameFinished && (
        <CardSelectionTreeNodeController
          {...props}
          pot={streetPot}
          players={players.map((p) => {
            const rv = { ...p }
            rv.initialStreetStack = p.initialStreetStack - p.streetBid
            rv.streetBid = 0
            return rv
          })}
        />
      )}
    </Box>
  )
}

const CardSelectionTreeNodeController = (props: TreeNodeProps) => {
  const { range, simId, path, pot, players, showDetails } = props
  const [cards, setCards] = useState<string[]>([])
  const [open, setOpen] = useState(false)
  const [showSelector, setShowSelector] = useState(false)
  const street = (range?.board || "").length / 2 == 3 ? "Turn" : "River"

  useEffect(() => {
    if (open || cards.length == 0) setShowSelector(true)
    else setShowSelector(false)
  }, [cards, open])

  const chooseCard = (c: string) => {
    if (cards.indexOf(c) >= 0) setCards(cards.filter((v) => v != c))
    else setCards([...cards, c])
  }

  const effectiveStack = Math.min(
    ...players.map((p) => p.initialStreetStack - p.streetBid)
  )
  const spr = effectiveStack / pot

  return (
    <>
      <Box component="ul">
        <Box component="li">
          <Box
            className="tf-nc"
            sx={{
              display: "flex",
              flex: 1,
              cursor: "pointer",
            }}
          >
            <Box className="node street-switch" onClick={() => setOpen(!open)}>
              <Box className="street-pot">
                {street} {pot}BB
              </Box>
              <Box className="street-estack">E-Stack {effectiveStack}BB</Box>
              <Box className="street-spr">SPR {spr.toFixed(2)}</Box>
            </Box>
          </Box>
          {!showSelector && cards.length > 0 && (
            <>
              <Box component="ul">
                {cards.map((c) => (
                  <TreeNodeController
                    key={c}
                    simId={simId}
                    open={true}
                    path={path + "," + c}
                    card={c}
                    collapsable={true}
                    pot={pot}
                    players={players}
                    oopTurn={true}
                    showDetails={showDetails}
                  />
                ))}
              </Box>
            </>
          )}
          {showSelector && (
            <Box component="ul">
              <Box component="li">
                <Box
                  className="tf-nc"
                  sx={{
                    display: "flex",
                    flex: 1,
                    borderRadius: "0 !important",
                  }}
                >
                  <CardSelector
                    onCardSelected={(v) => {
                      chooseCard(v)
                      setOpen(false)
                    }}
                    cardIsUsed={(card) => {
                      for (const v of [0, 2, 4, 6, 8]) {
                        const boardCard = range?.board.substring(v, v + 2)
                        if (boardCard == card) return "board"
                      }
                      return undefined
                    }}
                    cardClass={(c) => {
                      return cards.indexOf(c) >= 0 ? "card-choosen" : ""
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

const LoadingIndicator = () => {
  return (
    <Box
      className="node-loading"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0.5em",
        lineHeight: 1,
        position: "absolute",
        opacity: 1,
      }}
    >
      <CircularProgress size="1em" />
      <Box>...</Box>
    </Box>
  )
}

const ErrorIndicator = ({ error }: { error: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        lineHeight: 1,
        px: "1em",
      }}
    >
      <Alert
        severity="error"
        sx={{
          "&.MuiPaper-root": {
            // color: "black",
            fontSize: "0.75em",
          },
        }}
      >
        Error - {error}
      </Alert>
    </Box>
  )
}

const NodeLink = ({
  title,
  percent,
  color,
  hasNode,
}: {
  title: string
  percent: number
  color: string | undefined
  hasNode: boolean
}) => {
  return (
    <>
      <Box
        component="span"
        className="link"
        sx={{
          background: color,
          fontSize: "0.75em",
          display: "flex",
          alignItems: "center",
          gap: "0.5em",
        }}
      >
        <Box component="span" sx={{ pr: "0.25em", fontWeight: "600" }}>
          {title}
        </Box>
        <Box component="span">{percent.toFixed(2)}%</Box>
      </Box>
      {hasNode && (
        <Box sx={{ width: "1em", borderTop: "0.0625em solid #000" }}></Box>
      )}
    </>
  )
}

import PushPinIcon from "@mui/icons-material/PushPin"
import ZoomInIcon from "@mui/icons-material/ZoomIn"
import ZoomOutIcon from "@mui/icons-material/ZoomOut"
import { Box, Popover } from "@mui/material"
import { SelectedCardView } from "features/HHReplay/CardSelector"
import {
  HandPosition,
  PostflopHandPositionOrder,
} from "features/HHReplay/types"
import { TreeNodeController } from "features/Ranges/postflop/simulation_tree/TreeNode"
import { Card } from "features/types"
import { useMemo, useState } from "react"
import { useGetPostflopRangeQuery } from "services/rangesApi"
import { PostflopRange } from "services/types"
import { PFRANGE_TYPE } from "../PFStrategyView"
import "./custom-tree.css"
import { StrategyTreeView } from "./StrategyTreeView"
import "./tree.css"

export interface PlayerInfo {
  position: HandPosition
  initialStreetStack: number
  streetDebt: number
  streetBid: number
  cards?: Card[]
}
export interface PFTreeProps {
  id: string
  path: string
  initialStreetPot: number
  players: PlayerInfo[]
}

export type PopoverRangeInfo = {
  node?: HTMLDivElement
  path: string
  range?: PostflopRange
  position: HandPosition
  ipoop: PFRANGE_TYPE
  combo?: string
}

export type ZOOM_MODE = "zoom-normal" | "zoom-small" | "zoom-smallest"

export function PostflopTreeViewController(props: PFTreeProps) {
  const { id, path: initialPath, initialStreetPot: pot, players } = props
  const [scale, setScale] = useState(1)

  const zoomIn = () => {
    if (scale < 2) setScale(scale * 1.2)
  }

  const zoomOut = () => {
    if (scale > 0.2) setScale(scale / 1.2)
  }

  const [pinnedDetails, setPinnedDetails] = useState(false)
  const [popoverRangeInfo, setPopoverRangeInfo] =
    useState<PopoverRangeInfo | null>(null)

  const openPopover = (
    event: React.MouseEvent<HTMLDivElement>,
    rangeInfo: PopoverRangeInfo
  ) => {
    event.stopPropagation()
    setPopoverRangeInfo({
      node: event.currentTarget,
      path: rangeInfo.path,
      range: rangeInfo.range,
      position: rangeInfo.position,
      ipoop: rangeInfo.ipoop,
      combo: rangeInfo.combo,
    })
  }

  const closePopover = () => {
    setPopoverRangeInfo(null)
  }

  const mode: ZOOM_MODE = useMemo(() => {
    if (scale < 0.5) return "zoom-smallest"
    if (scale < 0.75) return "zoom-small"
    return "zoom-normal"
  }, [scale])

  const query = useGetPostflopRangeQuery({
    id: id,
    path: "",
  })

  // effective stack / pot
  const effectiveStack = Math.min(
    ...players.map((p) => p.initialStreetStack - p.streetBid)
  )
  const spr = effectiveStack / pot

  const playerOrder = players.sort((a, b) =>
    PostflopHandPositionOrder.indexOf(a.position) >
    PostflopHandPositionOrder.indexOf(b.position)
      ? -1
      : 1
  )

  if (!query || !query.data) return null
  const showDetails = Boolean(popoverRangeInfo)

  return (
    <>
      <Box
        sx={{
          minHeight: "100px",
          display: "flex",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "relative",
            fontSize: `${16 * scale}px`,
            flexGrow: 1,
          }}
          className={`tree-wrapper ${mode}`}
        >
          <Box className="tf-horizontal-tree tf-custom" sx={{ pt: 3 }}>
            <Box component="ul">
              <li>
                <Box className="tf-nc">
                  <Box className="node street-switch">
                    <Box className="street-pot">Flop {pot || "?"}BB</Box>
                    <Board board={query.data.board} />
                    <Box className="street-estack">
                      E-Stack {effectiveStack}BB
                    </Box>
                    <Box className="street-spr">SPR {spr.toFixed(2)}</Box>
                  </Box>
                </Box>
                <Box component="ul">
                  <TreeNodeController
                    open={true}
                    simId={id}
                    path={""}
                    board={query.data.board}
                    pot={pot}
                    players={players}
                    oopTurn={true}
                    showDetails={openPopover}
                  />
                </Box>
              </li>
            </Box>
          </Box>
        </Box>
        {popoverRangeInfo && popoverRangeInfo.range && (
          <div
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            {!pinnedDetails && (
              <Popover
                id="mouse-over-popover"
                open={showDetails}
                anchorEl={popoverRangeInfo.node}
                onClose={closePopover}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                BackdropProps={{ invisible: false }}
              >
                <Box
                  sx={{
                    minWidth: "500px",
                    minHeight: "300px",
                    p: "2px",
                    position: "relative",
                  }}
                >
                  <StrategyTreeView
                    range={popoverRangeInfo.range}
                    rangeType={popoverRangeInfo.ipoop}
                    position={popoverRangeInfo.position}
                    combo={popoverRangeInfo.combo}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      left: "0%",
                      top: "0%",
                      color: pinnedDetails ? "black" : "gray",
                    }}
                  >
                    <PushPinIcon
                      onClick={() => setPinnedDetails(!pinnedDetails)}
                    />
                  </Box>
                </Box>
              </Popover>
            )}
            {pinnedDetails && (
              <Box
                sx={{
                  minWidth: "500px",
                  minHeight: "300px",
                  p: "2px",
                  position: "relative",
                }}
              >
                <StrategyTreeView
                  range={popoverRangeInfo.range}
                  rangeType={popoverRangeInfo.ipoop}
                  position={popoverRangeInfo.position}
                  combo={popoverRangeInfo.combo}
                />
                <Box
                  sx={{
                    position: "absolute",
                    left: "0%",
                    top: "0%",
                    color: pinnedDetails ? "black" : "gray",
                  }}
                >
                  <PushPinIcon
                    onClick={() => setPinnedDetails(!pinnedDetails)}
                  />
                </Box>
              </Box>
            )}
          </div>
        )}
        <Box
          sx={{
            position: "absolute",
            cursor: "pointer",
            left: 0,
            top: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
            }}
          >
            <ZoomInIcon onClick={() => zoomIn()} />
            <ZoomOutIcon onClick={() => zoomOut()} />
            <Box component="span">{(scale * 100).toFixed(0)}%</Box>
          </Box>
          <Box
            className="legend"
            sx={{
              display: "none",
              // display: "flex",
              cursor: "help",
              opacity: 0.8,
              top: 0,
              border: "1px solid #000",
              borderRadius: "8px",
              backgroundColor: "darkgrey",
              userSelect: "none",
              p: "8px",
              fontSize: "12px",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ fontWeight: "600" }}>IP</Box>
              <Box>{playerOrder[1].position}</Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ fontWeight: "600" }}>OOP</Box>
              <Box>{playerOrder[0].position}</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

const Board = ({ board }: { board: string }) => {
  return (
    <Box sx={{ display: "flex" }} className="board">
      <SelectedCardView card={board.slice(0, 2)} />
      <SelectedCardView card={board.slice(2, 4)} />
      <SelectedCardView card={board.slice(4, 6)} />
    </Box>
  )
}

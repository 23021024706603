import { User } from "services/types"
import { useProfileQuery } from "services/userApi"

export function useAuthUser(): User | undefined {
  const profileQuery = useProfileQuery()

  if (profileQuery.error) {
    return undefined
  }

  return profileQuery.data
}

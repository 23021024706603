import GroupIcon from "@mui/icons-material/Group"
import SearchIcon from "@mui/icons-material/Search"
import {
  Avatar,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import { stringAvatar } from "features/profile/UserCard"
import { useEffect, useState } from "react"
import { FoundUser, SharedTo } from "services/types"
import { useSearchUsersQuery } from "services/userApi"
import { useDebounce } from "use-debounce"

interface SearchUsersProps {
  onChoose(user: SharedTo): void
}

export const AddUserControl = (props: SearchUsersProps) => {
  const [value, setValue] = useState<FoundUser | null>(null)
  const [candidates, setCandidates] = useState<FoundUser[]>([])
  const [searchInputValue, setSearchInputValue] = useState<string>("")
  const [searchFilter, setSearchFilter] = useState<string>("")
  const [filterValue] = useDebounce(searchInputValue, 1000, {})

  useEffect(() => {
    if (filterValue) {
      setSearchFilter(filterValue)
    }
  }, [filterValue, setSearchFilter])

  const usersQuery = useSearchUsersQuery(
    {
      filter: searchFilter || "",
    },
    { skip: !searchFilter || searchFilter == "" || searchFilter == "@" }
  )

  useEffect(() => {
    if (usersQuery.data) setCandidates(usersQuery.data.users)
  }, [usersQuery, setCandidates])

  return (
    <Autocomplete
      autoComplete
      includeInputInList
      onChange={(event: unknown, newValue: FoundUser | null) => {
        setValue(newValue)
      }}
      filterOptions={(x) => x}
      value={value}
      sx={{ width: 250 }}
      options={candidates}
      size="small"
      noOptionsText="No users"
      onInputChange={(event, newInputValue) => {
        setSearchInputValue(newInputValue)
      }}
      renderOption={(optionProps, option) => {
        return (
          <UserCard key={option.id} user={option} onChoose={props.onChoose} />
        )
      }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.publicHandle
      }
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
            label="Add user"
          />
        )
      }}
    />
  )
}

interface UserProps {
  user: FoundUser
  onChoose(user: SharedTo): void
}
function UserCard(props: UserProps) {
  const { user, onChoose } = props
  return (
    <Box
      onClick={() =>
        onChoose({
          user_id: user.id,
          username: user.publicHandle,
          avatar: user.imageURL,
        } as SharedTo)
      }
      sx={{
        display: "flex",
        alignItems: "start",
        gap: "24px",
        maxWidth: "300px",
        borderRadius: "8px",
        border: "1px solid #dddddd",
        p: "8px",
        ":hover": {
          bgcolor: "#cccccc",
        },
      }}
    >
      <Box>
        {user.imageURL && <Avatar src={user.imageURL} />}
        {!user.imageURL && <Avatar {...stringAvatar(user.name || "N A")} />}
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Box>{user.name}</Box>
        {user.publicHandle && (
          <Box sx={{ display: "flex" }}>
            <Button
              sx={{
                textDecoration: "none",
                textTransform: "none",
                "&.MuiButton-root": {
                  p: 0,
                  minWidth: 0,
                },
              }}
            >
              @{user.publicHandle}
            </Button>
          </Box>
        )}
        {(user.followed || user.following) && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <GroupIcon />
            {user.followed && !user.following && <Box>Following</Box>}
            {user.following && !user.followed && <Box>Follows you</Box>}
            {user.followed && user.following && <Box>Friend</Box>}
          </Box>
        )}
      </Box>
    </Box>
  )
}

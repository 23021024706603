import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown"
import { Box } from "@mui/material"
import { cardToEmoji } from "features/HandHistories/HandHistories"
import { CardSelector } from "features/HHReplay/CardSelector"
import { hhContext, HHContext } from "features/HHReplay/HHContext"
import {
  HandActionChar,
  HandPosition,
  HAND_ACTIONS_STRINGS,
  PostflopHandPositionOrder,
} from "features/HHReplay/types"
import { deepCopy } from "features/types"
import {
  AccordionInfo,
  AccordionLabel,
  AccordionWrapper,
  CheckboxWrapper,
} from "lib/Components/CustomAccordion"
import { useContext, useEffect, useMemo, useState } from "react"
import {
  CombinationPriorities,
  DrawCombination,
  DrawPriorities,
  HandCombination,
  PFActionPath,
  PFCardPairInfo,
  PostflopRange,
} from "services/types"
import { pairInBoard, PFRangeView } from "./PFRangeView"
import {
  betColors,
  opColors,
  PFRANGE_TYPE,
  PFStrategyView,
} from "./PFStrategyView"
import { sortedOperations } from "./PostflopRangeView"

const actionPath = (pfaction: PFActionPath): string => {
  let action: PFActionPath | null = pfaction
  const path: string[] = []
  while (action && action.action !== null) {
    path.push(action.action)
    action = action.parent
  }
  return path.reverse().join(",")
}

const streetActionPath = (pfaction: PFActionPath): string => {
  let action: PFActionPath | null = pfaction
  const path: string[] = []
  while (action && action.action !== null) {
    if (["c", "x", "b", "r", "f"].indexOf(action.action[0]) < 0) {
      // card selection?
      break
    }
    path.push(action.action)
    action = action.parent
  }
  return path.reverse().join(",")
}

const PFRangeAction = ({
  pfaction,
  onClick,
}: {
  pfaction: PFActionPath
  onClick: (path: string) => void
}) => {
  const operations = pfaction.alternatives
    ?.filter((op) => ["r", "b"].indexOf(op[0]) >= 0)
    .sort()
    .reverse()
  const color =
    opColors[pfaction.action] ||
    betColors[operations?.indexOf(pfaction.action) || 0]

  return (
    <Box
      onClick={() => onClick(actionPath(pfaction))}
      sx={{
        p: "2px",
        border: `1px solid gray`,
        fontSize: "12px",
        borderRadius: "4px",
        background: `${color}`,
        cursor: "pointer",
      }}
    >
      {`${
        HAND_ACTIONS_STRINGS[pfaction.action[0] as HandActionChar]
      } ${pfaction.action.substring(1)}`}
    </Box>
  )
}

const PFRangeCard = ({
  pfaction,
  onClick,
}: {
  pfaction: PFActionPath
  onClick: (path: string) => void
}) => {
  return (
    <Box
      onClick={() => onClick(actionPath(pfaction))}
      sx={{ cursor: "pointer" }}
    >
      {cardToEmoji(pfaction.action, "streetCard")}
    </Box>
  )
}

const RangeActions = ({
  range,
  navigateTo,
}: {
  range: PostflopRange
  navigateTo: (path: string) => void
}) => {
  const actions = useMemo(() => {
    const rv: JSX.Element[] = []
    let action: PFActionPath = range.actionPath
    while (action && action != null && action.action != null) {
      const playerAction =
        HAND_ACTIONS_STRINGS[action.action[0] as HandActionChar]
      if (playerAction)
        rv.push(
          <PFRangeAction
            pfaction={action}
            onClick={(path: string) => {
              navigateTo(path)
            }}
          />
        )
      else {
        const cardAction = action
        rv.push(
          <Box key={3}>
            <PFRangeCard
              pfaction={cardAction}
              onClick={(path: string) => {
                navigateTo(path)
              }}
            />
          </Box>
        )
      }

      if (action.parent == null) break

      action = action.parent
    }
    rv.push(
      <Box
        sx={{
          p: "2px",
          border: `1px solid gray`,
          fontSize: "12px",
          borderRadius: "4px",
          background: "hsl(240, 3%, 80%)",
          cursor: "pointer",
        }}
        onClick={() => {
          navigateTo("")
        }}
      >
        <span>{"Flop =>"}</span>
      </Box>
    )
    return rv.reverse()
  }, [range, navigateTo])

  return (
    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
      {actions.map((v, idx) => (
        <Box key={idx} sx={{ p: "1px" }}>
          {v}
        </Box>
      ))}
    </Box>
  )
}

function getIPOOPPositions(context: HHContext): {
  [key in PFRANGE_TYPE]: HandPosition
} {
  const action = context.hand.flop?.actions[0]
  const inGame = Object.keys(action?.state.playerStates || {}).filter(
    (position) =>
      ["IN_GAME", "ALL_IN"].indexOf(
        action?.state.playerStates[position as HandPosition]?.state || "NA"
      ) >= 0
  )
  const one = inGame[0] as HandPosition
  const two = inGame[1] as HandPosition
  const ip =
    PostflopHandPositionOrder.indexOf(one) <
    PostflopHandPositionOrder.indexOf(two)
  return {
    IP: ip ? one : two,
    OOP: ip ? two : one,
  }
}

const playerAction = (range: PostflopRange): boolean => {
  // ugly hack, if no strategy defined we expect card selection?
  return range.rangeGroups[22].strategy !== null
}

export const gameEnded = (range: PostflopRange): boolean => {
  return (
    range.actionPath.action == "f" ||
    (!playerAction(range) && range.board.length / 2 == 5)
  )
}

const suitablePair = (
  pair: PFCardPairInfo,
  comboFilter: HandCombination[],
  drawFilter: DrawCombination[]
): boolean => {
  if (comboFilter.length == 0 && drawFilter.length == 0) return true
  if (
    comboFilter.length &&
    comboFilter.indexOf(pair.combo as HandCombination) >= 0
  )
    return true
  if (
    drawFilter.length &&
    drawFilter.indexOf(pair.draw as DrawCombination) >= 0
  )
    return true
  return false
}

const filterRange = (
  range: PostflopRange,
  comboFilter: HandCombination[],
  drawFilter: DrawCombination[],
  board: string
): PostflopRange => {
  if (comboFilter.length == 0 && drawFilter.length == 0) return range
  const rv = deepCopy(range)

  for (const groupKey of Object.keys(rv.rangeGroups)) {
    const group = rv.rangeGroups[groupKey]

    let groupIPSum = 0,
      groupOOPSum = 0,
      groupPairsNum = 0
    for (const handKey of Object.keys(group.hands)) {
      if (pairInBoard(board, handKey)) {
        continue
      }
      groupPairsNum++
      const hand = group.hands[handKey]
      if (!suitablePair(hand.ipRangeInfo, comboFilter, drawFilter)) {
        hand.ipRangeInfo.probability = 0
      } else {
        groupIPSum += hand.ipRangeInfo.probability
      }

      if (!suitablePair(hand.oopRangeInfo, comboFilter, drawFilter)) {
        hand.oopRangeInfo.probability = 0
      } else {
        groupOOPSum += hand.oopRangeInfo.probability
      }
    }
    group.ipRange = groupIPSum / groupPairsNum
    group.oopRange = groupOOPSum / groupPairsNum
  }
  return rv
}

export function RangeHeader({
  range: xrange,
  setPath,
}: {
  range: PostflopRange
  setPath: (path: string) => void
}) {
  const [showStrategy, setShowStrategy] = useState(false)
  const [range, setRange] = useState(xrange)

  const [comboFilter, setComboFilter] = useState<HandCombination[]>([])
  const [drawFilter, setDrawFilter] = useState<DrawCombination[]>([])
  const board = range.board || ""

  useEffect(() => {
    if (!playerAction(xrange)) setShowStrategy(false)
    else setShowStrategy(true)

    setComboFilter([])
    setDrawFilter([])
  }, [xrange, setShowStrategy, setComboFilter, setDrawFilter])

  useEffect(() => {
    setRange(filterRange(xrange, comboFilter, drawFilter, xrange.board))
  }, [setRange, xrange, comboFilter, drawFilter])

  function navigateTo(path: string) {
    setPath(path)
  }

  function changeTo(pfaction: PFActionPath, op: string) {
    let action: PFActionPath | null = pfaction
    const path: string[] = []
    while (action && action.action !== null) {
      path.push(action.action)
      action = action.parent
    }
    path.reverse().push(op)
    navigateTo(path.join(","))
  }

  const availableHandCombinations: HandCombination[] = useMemo(() => {
    const rv = Object.keys(range.ipComboProbabilities).sort((a, b) => {
      return CombinationPriorities.indexOf(a as HandCombination) <
        CombinationPriorities.indexOf(b as HandCombination)
        ? 1
        : -1
    })
    return rv.map((v) => v as HandCombination)
  }, [range])

  const availableDrawCombinations: DrawCombination[] = useMemo(() => {
    const rv = Object.keys(range.ipDrawProbabilities).sort((a, b) => {
      return DrawPriorities.indexOf(a as DrawCombination) <
        DrawPriorities.indexOf(b as DrawCombination)
        ? 1
        : -1
    })
    return rv.map((v) => v as DrawCombination)
  }, [range])

  const totalIPCombos = useMemo(() => {
    return Object.values(range.ipComboProbabilities).reduce(
      (accumulator, op) => accumulator + op,
      0
    )
  }, [range])

  const totalOOPCombos = useMemo(() => {
    return Object.values(range.oopComboProbabilities).reduce(
      (accumulator, op) => accumulator + op,
      0
    )
  }, [range])

  const context = useContext(hhContext)
  const ipoopPlayers = getIPOOPPositions(context)

  const streetPlayerActions = streetActionPath(range.actionPath)
    .split(",")
    .filter((v) => v.length > 0)

  const cardSelection = !playerAction(range)
  const gameFinished = gameEnded(range)
  const strategyType = cardSelection
    ? "OOP"
    : streetPlayerActions.length % 2 == 0
    ? "OOP"
    : "IP"

  const strategyOperations = useMemo(() => {
    if (!range) return

    if (!playerAction(range)) return

    const rv: Record<string, number> = {}
    const combos = Object.keys(range?.rangeGroups || {})
    for (const group of combos) {
      const hands = range?.rangeGroups[group].hands
      const pairs = Object.keys(hands)
      for (const pair of pairs) {
        const strategy = range?.rangeGroups[group].hands[pair].strategy
        const handProbability =
          strategyType == "IP"
            ? range?.rangeGroups[group].hands[pair].ipRangeInfo.probability
            : range?.rangeGroups[group].hands[pair].oopRangeInfo.probability
        const operations = Object.keys(strategy)
        for (const op of operations) {
          const opRatio = strategy[op]
          rv[op] = (rv[op] || 0) + opRatio * handProbability
        }
      }
    }
    return rv
  }, [range, strategyType])

  const operations = sortedOperations(strategyOperations || {})
  const totalCombos = operations.reduce(
    (accumulator, op) => accumulator + op.ratio,
    0
  )
  let opColorIdx = 0
  const haveStrategy = playerAction(range)

  const comboFilterChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newFilter = []
    if (event.target.checked) {
      newFilter = [...comboFilter, event.target.id as HandCombination]
    } else {
      newFilter = [...comboFilter.filter((v) => v != event.target.id)]
    }
    setComboFilter(newFilter)
  }

  const drawFilterChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newFilter = []
    if (event.target.checked) {
      newFilter = [...drawFilter, event.target.id as DrawCombination]
    } else {
      newFilter = [...drawFilter.filter((v) => v != event.target.id)]
    }
    setDrawFilter(newFilter)
  }

  const ComboFilterOption = ({ combo }: { combo: HandCombination }) => {
    const ipCombos = range.ipComboProbabilities[combo] || 0
    const oopCombos = range.oopComboProbabilities[combo] || 0
    const ipPercent = (100 * ipCombos) / totalIPCombos
    const oopPercent = (100 * oopCombos) / totalOOPCombos
    return (
      <Box sx={{ display: "flex", fontSize: "12px", alignItems: "center" }}>
        <CheckboxWrapper
          checked={comboFilter.indexOf(combo) >= 0}
          onChange={comboFilterChecked}
          id={combo}
        />
        <Box sx={{ flexGrow: 1, pr: 1, minWidth: "160px" }}>
          {combo.toLocaleLowerCase()}
        </Box>
        <Box sx={{ whiteSpace: "nowrap" }}>{`
        ${oopCombos.toFixed(1)}/
        ${ipCombos.toFixed(1)}
        `}</Box>
        <Box
          sx={{
            pl: "8px",
            whiteSpace: "nowrap",
            minWidth: "90px",
            textAlign: "right",
          }}
        >{`
        ${oopPercent.toFixed(1)}%/
        ${ipPercent.toFixed(1)}%
        `}</Box>
      </Box>
    )
  }

  const DrawFilterOption = ({ combo }: { combo: DrawCombination }) => {
    const ipCombos = range.ipDrawProbabilities[combo] || 0
    const oopCombos = range.oopDrawProbabilities[combo] || 0
    const ipPercent = (100 * ipCombos) / totalIPCombos
    const oopPercent = (100 * oopCombos) / totalOOPCombos
    return (
      <Box sx={{ display: "flex", fontSize: "12px", alignItems: "center" }}>
        <CheckboxWrapper
          checked={drawFilter.indexOf(combo) >= 0}
          onChange={drawFilterChecked}
          id={combo}
        />
        <Box sx={{ flexGrow: 1, pr: 1, minWidth: "160px" }}>
          {combo.toLocaleLowerCase()}
        </Box>
        <Box sx={{ whiteSpace: "nowrap" }}>{`
        ${oopCombos.toFixed(1)}/
        ${ipCombos.toFixed(1)}
        `}</Box>
        <Box
          sx={{
            pl: "8px",
            whiteSpace: "nowrap",
            minWidth: "90px",
            textAlign: "right",
          }}
        >{`
        ${oopPercent.toFixed(1)}%/
        ${ipPercent.toFixed(1)}%
        `}</Box>
      </Box>
    )
  }

  if (!range) return null

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "stretch", height: "65px" }}>
        {/* board & made actions */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            border: "1px solid #dddddd",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {[0, 2, 4].map((v) => (
              <Box key={v}>
                {cardToEmoji(board.substring(v, v + 2), "streetCard")}
              </Box>
            ))}
          </Box>
          <Box sx={{ borderLeft: "1px solid gray", flexGrow: 1 }}>
            <RangeActions range={range} navigateTo={navigateTo} />
          </Box>
        </Box>
      </Box>
      {/* card selector || available actions */}
      {strategyOperations === undefined && !gameFinished && (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            border: "1px solid gray",
            borderRadius: "0 0 4px 4px",
          }}
        >
          <CardSelector
            onCardSelected={(v) => {
              const treePath = actionPath(range.actionPath) + "," + v
              navigateTo(treePath)
            }}
            cardIsUsed={(card) => {
              for (const v of [0, 2, 4, 6, 8]) {
                const boardCard = board.substring(v, v + 2)
                if (boardCard == card) return "board"
              }
              return undefined
            }}
          />
        </Box>
      )}
      <Box sx={{ display: "flex" }}>
        <Box>
          {strategyOperations !== undefined && (
            <Box
              sx={{
                display: "flex",
                mt: "5px",
                flex: 1,
              }}
            >
              {operations.map((op) => (
                <Box
                  key={`${op.op}_${op.value}`}
                  onClick={() => changeTo(range.actionPath, op.op + op.value)}
                  sx={{
                    fontSize: "12px",
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    m: "2px",
                    border: "1px solid gray",
                    borderRadius: "16px",
                    boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
                    cursor: "pointer",
                    background: opColors[op.op] || betColors[opColorIdx++],
                  }}
                >
                  <Box>
                    {HAND_ACTIONS_STRINGS[op.op as HandActionChar]} {op.value}
                  </Box>
                  <Box>{op.ratio.toFixed(1)} combos</Box>
                  <Box>{((op.ratio * 100) / totalCombos).toFixed(2)}%</Box>
                </Box>
              ))}
            </Box>
          )}
          {showStrategy && (
            <AccordionWrapper defaultExpanded={true}>
              <AccordionLabel
                expandIcon={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      transform: "rotate(-90deg)",
                    }}
                  >
                    <ExpandCircleDownIcon />
                  </Box>
                }
              >
                Strategy
              </AccordionLabel>
              <AccordionInfo>
                <Box sx={{ display: "flex", mt: "5px" }}>
                  {haveStrategy && (
                    <PFStrategyView
                      range={range}
                      rangeType={strategyType}
                      position={ipoopPlayers[strategyType]}
                    />
                  )}
                </Box>
              </AccordionInfo>
            </AccordionWrapper>
          )}
          <AccordionWrapper defaultExpanded={true}>
            <AccordionLabel
              expandIcon={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    transform: "rotate(-90deg)",
                  }}
                >
                  <ExpandCircleDownIcon />
                </Box>
              }
            >
              Ranges
            </AccordionLabel>
            <AccordionInfo>
              <Box sx={{ display: "flex", mt: "5px" }}>
                <PFRangeView
                  range={range}
                  rangeType="OOP"
                  position={ipoopPlayers["OOP"]}
                  actor={
                    strategyType == "OOP" && strategyOperations !== undefined
                  }
                />
                <Box sx={{ minWidth: "10px" }}></Box>
                <PFRangeView
                  range={range}
                  rangeType="IP"
                  position={ipoopPlayers["IP"]}
                  actor={
                    strategyType == "IP" && strategyOperations !== undefined
                  }
                />
              </Box>
            </AccordionInfo>
          </AccordionWrapper>
        </Box>
        <Box sx={{ pl: 1 }}>
          <Box sx={{ fontWeight: "bold" }}>Explorer</Box>
          <Box>
            <Box>
              <Box sx={{ display: "flex", fontSize: "12px" }}>
                <Box sx={{ flexGrow: 1, minWidth: "160px" }}>Combination</Box>
                <Box sx={{ whiteSpace: "nowrap" }}># combos (oop/ip)</Box>
              </Box>
              {availableHandCombinations.map((c) => (
                <ComboFilterOption key={c} combo={c} />
              ))}
              <Box sx={{ minHeight: "10px" }}></Box>
              {availableDrawCombinations.map((c) => (
                <DrawFilterOption key={c} combo={c} />
              ))}
              <Box sx={{ minHeight: "10px" }}></Box>
              <Box sx={{ display: "flex", fontSize: "12px" }}>
                <Box sx={{ flexGrow: 1, minWidth: "160px" }}>Total</Box>
                <Box sx={{ whiteSpace: "nowrap" }}>{`${totalOOPCombos.toFixed(
                  1
                )}/${totalIPCombos.toFixed(1)}`}</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

import { Box, Tooltip, useMediaQuery } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import classnames from "classnames"
import { cardToEmoji } from "features/HandHistories/HandHistories"
import { cardToString } from "features/types"
import { useTheme } from "lib/useTheme"
import { useContext, useMemo, useState } from "react"
import { hhContext } from "./HHContext"
import { HandPosition } from "./types"

const suits = { c: "\u2663", d: "\u2666", s: "\u2660", h: "\u2665" }

export const SelectedCardView = ({ card }: { card: string }) => {
  const value = card[0]
  const suit = card[1]
  return (
    <Box
      key={`${value}`}
      className={classnames(`suit-${suit}`)}
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          textAlign: "center",
          fontSize: "1em",
        }}
      >
        {value + suits[suit as "s" | "h" | "d" | "c"]}
      </Box>
    </Box>
  )
}

interface CardSelectorProps {
  card?: string
  onCardSelected(selected: string): void
  cardIsUsed?: (card: string) => string | undefined
  cardClass?: (card: string) => string
}

export const CardSelector = (props: CardSelectorProps): JSX.Element => {
  const {
    card,
    onCardSelected,
    cardIsUsed: externalUsedCheck,
    cardClass,
  } = props
  const [choosenCard, chooseCard] = useState<string | undefined>(card)
  const context = useContext(hhContext)
  const values = "AKQJT98765432"

  const selectCard = (card: string): void => {
    chooseCard(card)
    onCardSelected(card)
  }

  const usedCards = useMemo(() => {
    const playerCards: { [key in HandPosition]?: string[] } = {}
    if (context.hand.hero?.heroCards && context.hand.hero?.heroPosition) {
      const position = context.hand.hero.heroPosition
      playerCards[position] = context.hand.hero.heroCards.map((v) =>
        cardToString(v)
      )
    }

    if (context.hand.showDownSection) {
      for (const position of Object.keys(context.hand.showDownSection)) {
        const positionCards =
          context.hand.showDownSection[position as HandPosition] || []
        playerCards[position as HandPosition] = positionCards.map((v) =>
          cardToString(v)
        )
      }
    }

    const flopCards = context.hand.flopCards?.cards || []
    const turnCards = context.hand.turnCards?.cards || []
    const riverCards = context.hand.riverCards?.cards || []
    const rv = {
      player: playerCards,
      flop: flopCards.map((v) => cardToString(v)),
      turn: turnCards.map((v) => cardToString(v)),
      river: riverCards.map((v) => cardToString(v)),
    }

    return rv
  }, [context])

  const cardIsUsed = (cardToCheck: string): string | undefined => {
    if (cardToCheck == card) return undefined
    if (usedCards["flop"].indexOf(cardToCheck) >= 0) return "flop"
    if (usedCards["turn"].indexOf(cardToCheck) >= 0) return "turn"
    if (usedCards["river"].indexOf(cardToCheck) >= 0) return "river"
    for (const position of Object.keys(usedCards["player"])) {
      const playerCards = usedCards["player"][position as HandPosition]
      if (playerCards && playerCards.indexOf(cardToCheck) >= 0) return position
    }
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {card && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>{cardToEmoji(card, "tableCard")}</Box>
          <Box> {"=>"} </Box>
          <Box>{cardToEmoji(choosenCard || card, "tableCard")}</Box>
        </Box>
      )}

      <Box className="cardSelector">
        {["s", "h", "d", "c"].map((suit) => {
          const rv: JSX.Element[] = []
          for (const value of values) {
            const usedBy = externalUsedCheck
              ? externalUsedCheck(value + suit)
              : cardIsUsed(value + suit)
            const selected = choosenCard == value + suit
            const customClass: string = cardClass ? cardClass(value + suit) : ""
            const cell = (
              <Box
                key={`${value}`}
                className={classnames(`suit-${suit} cardSelectorCell`, {
                  disabled: usedBy !== undefined,
                  selected: selected,
                  [customClass]: customClass !== undefined,
                })}
                onClick={() => usedBy === undefined && selectCard(value + suit)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "2em",
                  minHeight: "3em",
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    textAlign: "center",
                    fontSize: "1em",
                  }}
                >
                  {value + suits[suit as "s" | "h" | "d" | "c"]}
                </Box>
              </Box>
            )
            if (usedBy)
              rv.push(
                <Tooltip key={value} title={`Used by ${usedBy}`} arrow>
                  {cell}
                </Tooltip>
              )
            else rv.push(cell)
          }
          return (
            <Box
              className={"cardSelectorRow"}
              key={`suit-${suit}`}
              sx={{ display: "flex" }}
            >
              {rv}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
interface CardSelectorDialogProps {
  card: string
  open: boolean
  close(selected: string | undefined): void
}

export function CardSelectorDialog(props: CardSelectorDialogProps) {
  const { card, open, close } = props
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"))
  const [choosenCard, setCard] = useState<string>(card)
  return (
    <Dialog open={open} fullScreen={mobileView}>
      <DialogTitle>{`Change card`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To change card, select new one and click &apos;Save&apos;
        </DialogContentText>
        <CardSelector card={card} onCardSelected={(v) => setCard(v)} />
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box>{cardToEmoji(card, "tableCard")}</Box>
            <Box> {"=>"} </Box>
            <Box>{cardToEmoji(choosenCard, "tableCard")}</Box>
          </Box>

          <Box className="cardSelector">
            {["s", "h", "d", "c"].map((suit) => {
              const rv: JSX.Element[] = []
              for (const value of values) {
                const usedBy = cardIsUsed(value + suit)
                const selected = choosenCard == value + suit
                const cell = (
                  <Box
                    key={`${value}`}
                    className={classnames(`suit-${suit} cardSelectorCell`, {
                      ["disabled"]: usedBy !== undefined,
                      ["selected"]: selected,
                    })}
                    onClick={() =>
                      usedBy === undefined && setCard(value + suit)
                    }
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "30px",
                      minHeight: "50px",
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                    >
                      {value + suits[suit as "s" | "h" | "d" | "c"]}
                    </Box>
                  </Box>
                )
                if (usedBy)
                  rv.push(
                    <Tooltip key={value} title={`Used by ${usedBy}`} arrow>
                      {cell}
                    </Tooltip>
                  )
                else rv.push(cell)
              }
              return (
                <Box
                  className={"cardSelectorRow"}
                  key={`suit-${suit}`}
                  sx={{ display: "flex" }}
                >
                  {rv}
                </Box>
              )
            })}
          </Box>
        </Box> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close(undefined)}>Cancel</Button>
        <Button onClick={() => close(choosenCard)}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

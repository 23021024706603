import { Box } from "@mui/material"
import { ReactElement, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useHandHistoryDetailsQuery } from "../../services/handHistoryApi"
import { HandHistoryDetails } from "../../services/types"
import { HandActionIndex, hhContext, HHContext } from "./HHContext"
import { HHReplayView } from "./HHReplayView"
import "./styles.css"
import { Hand, handFromString, HandPosition } from "./types"
import { getNextAction, getPrevAction } from "./utils"

export function DefaultHHReplay() {
  const str =
    "8-handed;BB 12, SB 12, BTN 12, CO 12, HJ 12, LJ 12, UTG7 12, UTG8 12;SB 0.5, BB 1;SB AcKc; UTG8 c, LJ r2, CO c, SB r3, LJ c, CO c; flop: AdKdQd;SB x,LJ x, CO x;Jd;SB b2, LJ c,CO c;Td;SB a, LJ a, CO a; showdown: CO AsKs, LJ AhKh;"
  return (
    <HHReplay data={str}>
      <HHReplayView id={undefined} />
    </HHReplay>
  )
}

export function ShowHHReply() {
  const { id } = useParams()
  const { data, isLoading } = useHandHistoryDetailsQuery(id)

  if (isLoading) {
    return <div>Loading...</div>
  }
  if (!data) return null
  return (
    <HHReplay {...data}>
      <HHReplayView id={id} />
    </HHReplay>
  )
}

export function HHReplay({
  id,
  data,
  nicknames,
  winners,
  game,
  metadata,
  children,
}: HandHistoryDetails & { children: ReactElement[] | ReactElement }) {
  const [hand, setHand] = useState<Hand>()
  const [currentAction, setCurrentAction] = useState<HandActionIndex>({
    street: "preflop",
    index: -1,
  })
  const hasSimulation = !!(
    metadata && metadata.simulationRunStatus === "FINISHED"
  )

  const [miniRFIRangePosition, setMiniRFIRangePosition] = useState<
    HandPosition | undefined
  >()

  const defaultHH = useMemo(() => {
    if (hand) {
      return undefined
    }
    const rv = handFromString(id, data, nicknames, winners, hasSimulation, game)
    setHand(rv)
    const haveActions = (rv.preFlop?.actions || []).length > 0
    if (haveActions)
      setCurrentAction({
        street: "preflop",
        index: 0,
      })
    return rv
  }, [hand, setHand, id, data, nicknames, winners, hasSimulation, game])

  if (hand && hand.id !== id) {
    setHand(undefined)
  }

  const contextHand = hand || defaultHH

  const hh = {
    hand: contextHand,
    currentActionIndex: currentAction,
    shownRangePosition: miniRFIRangePosition,
    setHand: (hand: Hand) => {
      if (!hand?.preFlop?.actions) {
        setCurrentAction({
          street: "preflop",
          index: -1,
        })
      }
      hand.hasSimulation = hasSimulation || false
      hand.nicknames = contextHand?.nicknames
      hand.metadata = metadata
      setHand(hand)
    },

    setCurrentActionIndex: (index: HandActionIndex) => {
      if (
        index.street == currentAction.street &&
        index.index == currentAction.index
      )
        return
      setCurrentAction(index)
      if (
        miniRFIRangePosition &&
        index.street === "preflop" &&
        index.index >= 0
      ) {
        setMiniRFIRangePosition(hand?.preFlop?.actions[index.index].position)
      }
    },

    moveToNextAction: (skipImplicit?: boolean) => {
      if (!contextHand) return
      const next = getNextAction(
        hh.currentActionIndex,
        contextHand,
        skipImplicit
      )
      if (next) {
        hh.setCurrentActionIndex(next)
      }
      return next
    },

    moveToPrevAction: (skipImplicit?: boolean) => {
      if (!contextHand) return
      const next = getPrevAction(
        hh.currentActionIndex,
        contextHand,
        skipImplicit
      )
      if (next) {
        hh.setCurrentActionIndex(next)
      }
      return next
    },

    setShownRangePosition: (position: HandPosition) =>
      setMiniRFIRangePosition(position),

    getAction: () => {
      if (currentAction === undefined || currentAction.index < 0)
        return undefined
      if (hand === undefined) return undefined
      switch (currentAction.street) {
        case "preflop":
          return hand.preFlop?.actions
            ? hand.preFlop?.actions[currentAction.index]
            : undefined
        case "flop":
          return hand.flop?.actions
            ? hand.flop?.actions[currentAction.index]
            : undefined
        case "turn":
          return hand.turn?.actions
            ? hand.turn?.actions[currentAction.index]
            : undefined
        case "river":
          return hand.river?.actions
            ? hand.river?.actions[currentAction.index]
            : undefined
      }
    },
  } as HHContext

  return (
    <Box
      sx={{
        display: "flex",
        overflowY: "scroll",
        flexDirection: "column",
        flexGrow: 1,
        width: { xs: "100vw", sm: "100vw", md: "auto" },
      }}
    >
      <hhContext.Provider value={hh}>{children}</hhContext.Provider>
    </Box>
  )
}

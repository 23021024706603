import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"
import { Checkbox, CheckboxProps } from "@mui/material"
import Accordion, { AccordionProps } from "@mui/material/Accordion"
import AccordionDetails, {
  AccordionDetailsProps,
} from "@mui/material/AccordionDetails"
import AccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary"
import { styled } from "@mui/material/styles"

export const AccordionWrapper = styled((props: AccordionProps) => (
  <Accordion {...props} />
))(() => ({
  "&.MuiAccordion-root.Mui-expanded": {
    margin: "0px",
  },
}))

export const CheckboxWrapper = styled((props: CheckboxProps) => (
  <Checkbox {...props} />
))(() => ({
  "&.MuiCheckbox-root": {
    margin: "0px",
    padding: "0px",
  },
}))

export const AccordionLabel = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.5rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    fontSize: "14px",
    fontWeight: 500,
    margin: "4px",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    marginLeft: theme.spacing(1),
    fontSize: "14px",
    margin: "4px",
  },
  "&.MuiAccordionSummary-root": {
    minHeight: 0,
    padding: "0 4px",
  },
}))

export const AccordionInfo = styled((props: AccordionDetailsProps) => (
  <AccordionDetails {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  "&.MuiAccordionDetails-root": {
    minHeight: 0,
    padding: "0 4px",
  },
}))

import { HandPosition } from "../features/HHReplay/types"

type SBError = {
  defaultMessage?: string
}
export type APIErrorResponse<RequestType> = {
  detail: string
  message: string
  errors: SBError[]
} & Partial<RequestType>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorData<RequestType>(error: any) {
  return error && "data" in error && error.data
    ? (error.data as APIErrorResponse<RequestType>)
    : undefined
}

export type LoginRequest = {
  login: string
  password: string
}

export type RegisterRequest = {
  username: string
  password: string
  email: string
  first_name: string
  last_name: string
}

export type VerifyRegisterRequest = {
  user_id: string
  timestamp: string
  signature: string
}

export type ChangePasswordRequest = {
  old_password: string
  password: string
  password_confirm: string
}

export type SendResetPasswordLinkRequest = {
  email: string
}

export type SendResetPasswordLinkResponse = {
  detail: string
}

export type ResetPasswordParams = {
  user_id: string
  timestamp: string
  signature: string
}

export type ResetPasswordRequest = {
  password: string
  password_confirm: string
} & ResetPasswordParams

export type ResetPasswordResponse = {
  detail: string
}

export type User = {
  id: number
  email: string
  name: string
  username: string
  imageUrl: string
  systemAdmin: boolean
}

export type PrivacyVisibility = "PRIVATE" | "PUBLIC" | "FRIENDS"

export type PrivacySettingsRequest = {
  email: PrivacyVisibility
  name: PrivacyVisibility
  followers: PrivacyVisibility
  following: PrivacyVisibility
  member_since: PrivacyVisibility
}

export type PrivacySettings = {
  id: number
  email: PrivacyVisibility
  name: PrivacyVisibility
  followers: PrivacyVisibility
  following: PrivacyVisibility
  member_since: PrivacyVisibility
}

export type UpdateUserRequest = {
  username: string
}

export interface HandHistory {
  id: number
  source: string
  playedOn: string
  importedOn: string
  tournamentId: string
  sourceHandId: string
  boardCards: string // "Kd6c9s8c3s"
  pot: number
  effectiveStack: number
  position: string // maybe union 'OOP' | ...
  metadata?: SimulationMetadata
}

export interface SimulationMetadata {
  simulationAvailabilityStatus: string
  simulationAvailabilityStatusDescription: string
  simulationId: string
  simulationRunStatus: string
  simulationRunMetadata?: SimulationRunMetadata
  preflopCategory: string
}

export interface SimulationRunMetadata {
  pot: number
  effectiveStack: number
}

export interface Winner {
  cards?: string
  cardsShowed: boolean
  description: string
  position: HandPosition
  pot: number
}

export interface HHGame {
  playedOn: string
  tournament: string
  gameKind: string
}
export interface HandHistoryDetails {
  id?: string
  data: string
  nicknames?: { [key in string]: string }
  winners?: { [key in HandPosition]: Winner }
  game?: HHGame
  metadata?: SimulationMetadata
}

export interface StackSize {
  size: number
  available_position: HandPosition[]
}
export interface RangeSet {
  is_default: boolean
  id: string
  name: string
  number_of_players: number
  sizes: StackSize[]
}

export interface RangeInfo {
  raiseSizing: number
  ranges: Record<string, Record<string, number>>
  operationCombos?: Record<string, number>
}

export interface SaveRangeRequest {
  rangeset_id: string
  stack_size: number
  position: string
  path: string
  range: RangeInfo
}

export interface BetActionSizing {
  BET: string
  RAISE: string
}

export interface SimplePositionSizing {
  OOP: BetActionSizing
  IP: BetActionSizing
}

export interface PostFlopStreetsSizing {
  FLOP: SimplePositionSizing
  TURN: SimplePositionSizing
  RIVER: SimplePositionSizing
}

export interface BetSizingRange {
  id: string
  groupId: string
  rangeStart: number
  rangeEnd: number
  betSizes: PostFlopStreetsSizing
}

export type UpdateBetSizingRangeRequest = Partial<BetSizingRange> &
  Pick<BetSizingRange, "id">

export type AddBetSizingRangeRequest = UpdateBetSizingRangeRequest

export interface BetSizingRangeGroup {
  id: string
  name: string
  ownerId: number
  category: string
  ranges: BetSizingRange[]
}

export interface BetSizingConfig {
  betModelingConfig: string
  potModelingConfig: string
}

export interface PFCardPairInfo {
  probability: number
  combo: string
  draw: string
}

export interface PFCardPair {
  hand: string
  oopRangeInfo: PFCardPairInfo
  ipRangeInfo: PFCardPairInfo
  strategy: Record<string, number>
}

export interface PFCardCombo {
  combination: string
  oopRange: number
  ipRange: number
  strategy: Record<string, number>
  hands: Record<string, PFCardPair>
}

export interface PFActionPath {
  id: string
  action: string
  alternatives: string[] | null | undefined
  parent: PFActionPath | null
}

export enum DRAW_COMBINATIONS {
  NO_DRAW = "NO_DRAW",
  FOUR_OUT_STRAIGHT_DRAW = "FOUR_OUT_STRAIGHT_DRAW",
  EIGHT_OUT_STRAIGHT_DRAW = "EIGHT_OUT_STRAIGHT_DRAW",
  FLUSH_DRAW = "FLUSH_DRAW",
  COMBO_DRAW = "COMBO_DRAW",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export type DrawCombination = keyof typeof DRAW_COMBINATIONS

export const DrawPriorities: DrawCombination[] = [
  "NO_DRAW",
  "FOUR_OUT_STRAIGHT_DRAW",
  "EIGHT_OUT_STRAIGHT_DRAW",
  "FLUSH_DRAW",
  "COMBO_DRAW",
  "UNRECOGNIZED",
]

export type DrawCombinations = { [key in DrawCombination]?: number }

export enum HAND_COMBINATIONS {
  NOTHING = "NOTHING",
  KING_HIGH = "KING_HIGH",
  ACE_HIGH = "ACE_HIGH",
  LOW_PAIR = "LOW_PAIR",
  THIRD_PAIR = "THIRD_PAIR",
  SECOND_PAIR = "SECOND_PAIR",
  UNDERPAIR = "UNDERPAIR",
  TOP_PAIR = "TOP_PAIR",
  OVERPAIR = "OVERPAIR",
  TWO_PAIR = "TWO_PAIR",
  TRIPS = "TRIPS",
  SET = "SET",
  STRAIGHT = "STRAIGHT",
  FLUSH = "FLUSH",
  FULL_HOUSE = "FULL_HOUSE",
  TOP_FULL_HOUSE = "TOP_FULL_HOUSE",
  QUADS = "QUADS",
  STRAIGHT_FLUSH = "STRAIGHT_FLUSH",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export type HandCombination = keyof typeof HAND_COMBINATIONS

export const CombinationPriorities: HandCombination[] = [
  "NOTHING",
  "KING_HIGH",
  "ACE_HIGH",
  "LOW_PAIR",
  "THIRD_PAIR",
  "SECOND_PAIR",
  "UNDERPAIR",
  "TOP_PAIR",
  "OVERPAIR",
  "TWO_PAIR",
  "TRIPS",
  "SET",
  "STRAIGHT",
  "FLUSH",
  "FULL_HOUSE",
  "TOP_FULL_HOUSE",
  "QUADS",
  "STRAIGHT_FLUSH",
  "UNRECOGNIZED",
]

export type HandCombinations = { [key in HandCombination]?: number }

export interface PostflopRangeRequest {
  id: string
  path: string
}
export interface PostflopRange {
  actionPath: PFActionPath
  board: string
  rangeGroups: Record<string, PFCardCombo>
  ipComboProbabilities: HandCombinations
  oopComboProbabilities: HandCombinations
  ipDrawProbabilities: DrawCombinations
  oopDrawProbabilities: DrawCombinations
}

export type RangesFilter = Record<string, string | number | string[]>

export interface SimulationTreeInfo {
  type: string
  board: string
  pot: number
  effectiveStack: number
  oopRange: { [key in string]: number }
  ipRange: { [key in string]: number }
  betSizes: PostFlopStreetsSizing
}

export type SearchUsersQuery = Record<string, string | number | string[]>
export interface SearchFollowersRequest {
  query: SearchUsersQuery
  publicHandle: string
}

export interface FoundUser {
  id: number
  publicHandle: string
  imageURL: string

  name: string
  email: string

  member_since: string
  followed: boolean
  following: boolean
}

export interface SearchUsersResponse {
  totalItems: number
  totalPages: number
  currentPage: number
  users: FoundUser[]
}

export type SearchNotificationsQuery = Record<
  string,
  string | number | string[]
>

export interface FollowerAddedMetadata {
  message: string
  username: string
  name: string
  avatar: string
}

export interface HandSharedMetadata {
  message: string
  username: string
  avatar: string
  name: string
  hand: string
}

export interface UserNotification {
  id: number
  created_at: string
  kind: "FOLLOWER_ADDED" | "HAND_SHARED"
  acknowledged_at?: string
  metadata: FollowerAddedMetadata | unknown
}

export interface SearchNotificationsResponse {
  totalItems: number
  totalPages: number
  currentPage: number
  notifications: UserNotification[]
}

export type SearchHandsParams = Record<string, string | number | string[]>

export interface SearchHandsResponse {
  totalItems: number
  totalPages: number
  currentPage: number
  hands: HandHistory[]
}

export interface SharedTo {
  user_id: number
  username: string
  avatar: string
}

export type HandSharingPolicy = "PRIVATE" | "PUBLIC" | "INDIVIDUALS"

export interface SharedHandConfig {
  can_share: boolean
  allow_share: boolean
  policy: HandSharingPolicy
  users?: SharedTo[]
}

export interface HandShareRequest {
  allow_share: boolean
  policy: HandSharingPolicy
  users?: number[]
}

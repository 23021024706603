import Box from "@mui/material/Box"
import { Action, ACTION_COLORS } from "../types"
import { CardInfo } from "./Ranges"

export function CardComboInfoView(props: CardInfo) {
  const { cardId, ratio } = props
  const actions = []
  for (const action in ratio) {
    const probability = ratio[action as Action]
    if (!probability || probability <= 0) {
      continue
    }
    const percent = probability * 100
    const cell = (
      <Box
        key={action}
        sx={{
          minWidth: { sm: "30px", xs: "25px" },
          height: { sm: "30px", xs: "25px" },
          fontSize: { xs: "10px", sm: "12px" },

          background: ACTION_COLORS[action as Action],
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>{percent.toFixed(percent > 1 ? 0 : percent < 0.01 ? 4 : 2)}%</Box>
      </Box>
    )

    actions.push(cell)
  }
  return (
    <Box
      sx={{
        minWidth: "160px",
        display: "flex",
        alignItems: "center",
        border: "1px solid #CCCCCC",
        borderRadius: "12px",
        p: 1,
        mt: { xs: 1, sm: 0 },
      }}
    >
      <Box sx={{ fontWeight: "bold", fontSize: "14px" }}>
        {cardId || "Select card"}
      </Box>
      <Box sx={{ display: "flex", pl: 0.5 }}>{actions}</Box>
    </Box>
  )
}

import Box from "@mui/material/Box"
import { useEffect, useMemo } from "react"
import { RangeSet } from "services/types"
import { HandPosition } from "../../HHReplay/types"
import {
  CATEGORY_ACTION,
  CATEGORY_RFI,
  FilterAction,
  HERO_POSITIONS,
  RangeParameters,
} from "../types"
import { ActionsFilter } from "./ActionsFilter"
import { CategoryFilter } from "./CategoryFilter"
import { PositionFilter } from "./PositionFilter"
import { ActionKind } from "./Ranges"
import { RangeSetFilter } from "./RangeSetFilter"
import { StackSizeFilter } from "./StackSizeFilter"

export interface RangeParametersProps {
  handleChange: (
    field: ActionKind,
    value: string | number | HandPosition | FilterAction[]
  ) => void
  state: RangeParameters
  strategies: RangeSet[]
}

export function RangeParametersView(props: RangeParametersProps) {
  const { state, handleChange, strategies } = props

  useEffect(() => {
    if (state.rangeset === undefined && strategies.length > 0) {
      handleChange("rangeset", strategies[0].id)
    }
  }, [state, strategies, handleChange])

  const strategyInfo = useMemo(() => {
    const rv = new Map<string, RangeSet>()
    strategies.forEach((v) => {
      rv.set(v.id, v)
    })
    return rv
  }, [strategies])

  const stackSizes = useMemo(() => {
    if (state.rangeset === undefined || strategyInfo === undefined) return []
    const rangeset = strategyInfo.get(state.rangeset)
    if (rangeset === undefined) return []
    const n_sizes = rangeset.sizes.map((v) => v.size).sort((a, b) => a - b)
    return n_sizes
  }, [state, strategyInfo])

  const positions = useMemo(() => {
    if (state.rangeset === undefined || strategyInfo === undefined) return []
    const rangeset = strategyInfo.get(state.rangeset)
    if (rangeset === undefined) return []
    if (state.stackSize === undefined) return []
    const sizeInfo = rangeset.sizes.filter((v) => v.size == state.stackSize)[0]
    if (sizeInfo === undefined) return []
    return HERO_POSITIONS.filter(
      (v, index) => index < rangeset.number_of_players
    )
      .reverse()
      .map((v) => {
        const disabled =
          sizeInfo.available_position.indexOf(v.position) < 0 ||
          (v.position === "BB" && state.category === CATEGORY_RFI)
        v.enabled = !disabled
        return v
      })
  }, [state, strategyInfo])

  useEffect(() => {
    if (
      state.stackSize === undefined &&
      stackSizes !== undefined &&
      stackSizes.length > 0
    )
      handleChange("stackSize", stackSizes[0])
    if (
      state.heroPosition === undefined &&
      positions !== undefined &&
      positions.length > 0
    )
      handleChange("heroPosition", positions[0].position)
  }, [state, stackSizes, positions, handleChange])

  return (
    <Box
      sx={{
        ml: { xs: 0, md: 2 },
        mt: { xs: 2, md: 0 },
        p: 2,
        borderRadius: "12px",
        border: "1px solid #D3D3D3",
        backgroundColor: "#EEEEEE",
      }}
    >
      <RangeSetFilter {...props} />
      <StackSizeFilter stackSizes={stackSizes} {...props} />
      <PositionFilter positions={positions} {...props} />
      <CategoryFilter {...props} />
      {state.category === CATEGORY_ACTION && (
        <ActionsFilter positions={positions} {...props} />
      )}
    </Box>
  )
}

import { Box } from "@mui/material"
import classnames from "classnames"
import { getCurrentAction } from "features/HHReplay/ActionPointer"
import { HHContext } from "features/HHReplay/HHContext"
import { useIsCurrentActionLast } from "features/HHReplay/hooks"
import { HandPlayerStateInfo, HandPosition } from "features/HHReplay/types"
import { Card, PositionLabels } from "../types"

export interface SeatProps {
  n: number
  position: HandPosition
  stackSize?: number
  betSum?: number
  dealer?: boolean
  hero?: boolean
  cards: Card[]
  playerState?: HandPlayerStateInfo
  context: HHContext
  manageable?: boolean
}

export function TableSeat(props: SeatProps) {
  const {
    n,
    position,
    stackSize,
    dealer,
    hero,
    betSum,
    playerState: state,
    context,
  } = props

  const lastAction = useIsCurrentActionLast()
  const action = getCurrentAction(context)

  const candidate =
    lastAction &&
    !context.hand.lastState?.winner &&
    state?.state != "OUT_OF_GAME"

  const winner =
    action &&
    lastAction &&
    action.state.winner &&
    position == action.state.winner &&
    (action.isManual || action.position == action.state.winner)

  const activePosition =
    context.currentActionIndex.street == "preflop" &&
    (action?.position == position || action === undefined)

  const shouldAct = !state?.actionIsMade || state.debt > 0

  return (
    <Box
      className={`${dealer ? `btn seat-${n}-btn` : ""} ${
        hero ? " hero" : ""
      } seat-${n} seat`}
    >
      <Box className={`seat-${n}-cards`} sx={{ display: "flex" }}>
        <Box sx={{ color: "lightgray" }} className="tableCard">
          {"\uD83C\uDCA0"}
        </Box>
        <Box sx={{ color: "lightgray" }} className="tableCard">
          {"\uD83C\uDCA0"}
        </Box>
      </Box>
      <Box
        className={classnames(`seatinfo state-${state?.state}`, {
          ["winner"]: winner,
          ["candidate"]: candidate,
          ["acting"]: activePosition && !action?.isManual,
          ["shouldact"]: shouldAct,
        })}
        sx={{
          position: "absolute",
          left: "0%",
          top: "0%",
          width: "100%",
          height: "100%",
        }}
      >
        <Box sx={{ fontWeight: "600" }}>{PositionLabels[position]}</Box>
        <div>
          {stackSize !== undefined ? Number(stackSize.toFixed(2)) : "?"} BB
        </div>

        {betSum !== undefined && betSum > 0 && (
          <div className={`seat-${n}-bet seat-bet`}>
            {Number(betSum.toFixed(2))} BB
          </div>
        )}
      </Box>
    </Box>
  )
}

import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import type { RootState } from "app/store"
import { setCredentials } from "features/auth/authSlice"
import SignIn from "features/auth/SignIn"
import { BetSizing } from "features/BetSizing/BetSizing"
import { HandHistories } from "features/HandHistories/HandHistories"
import { DefaultHHReplay, ShowHHReply } from "features/HHReplay"
import { ProfileController } from "features/profile/Profile"
import { PreflopRangeController as RangeExplorer } from "features/RangeExplorer"
import { Ranges } from "features/Ranges/preflop/Ranges"
import { Simulations } from "features/Simulations/Simulations"
import { UserInfoController } from "features/userinfo"
import { NotFound } from "lib/Components/PageNotFound"
import { useTheme } from "lib/useTheme"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import { useProfileQuery } from "services/userApi"
import "./App.css"
import { LeftNavigation } from "./LeftNavigation"
import { TopBar } from "./TopBar"

function AuthenticatedApp() {
  const theme = useTheme()

  const navigate = useNavigate()
  const user = useSelector((state: RootState) => state.auth.user)
  useEffect(() => {
    if (!user) {
      let locationUrl = location.pathname
      if (location.search) locationUrl += location.search
      const redirect = encodeURIComponent(locationUrl)
      navigate(`/signin/?redirect=${redirect}`)
    }
  }, [navigate, user])

  if (!user) return null

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        className="full-height"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TopBar user={user} />

        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            overflow: "hidden",
          }}
        >
          <LeftNavigation />

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: { sm: 0, md: 0.5 },
              display: "flex",
              overflow: "hidden",
            }}
          >
            <Routes>
              <Route path="/" element={<Navigate to="/ranges" replace />} />
              <Route path="/profile/*" element={<ProfileController />} />
              <Route path="/hand-histories" element={<HandHistories />} />
              <Route path="/simulations" element={<Simulations />} />
              <Route path="/ranges" element={<Ranges />} />
              <Route path="/betsizing" element={<BetSizing />} />
              <Route path="/hhreplay" element={<DefaultHHReplay />} />
              <Route path="/pfrexplorer/*" element={<RangeExplorer />} />
              <Route path="/hhreplay/:id" element={<ShowHHReply />} />
              <Route path="/@:handle/*" element={<UserInfoController />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

function UnAuthenticatedApp() {
  const theme = useTheme()
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        className="full-height"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Routes>
          <Route path="/@:handle" element={<UserInfoController />} />
          <Route path="*" element={<SignIn />} />
        </Routes>
      </Box>
    </ThemeProvider>
  )
}

function App() {
  const dispatch = useDispatch()

  const [notLoggedIn, setNotLoggedIn] = useState(false)
  const {
    isFetching: userIsFetching,
    data: user,
    error: userError,
    refetch,
  } = useProfileQuery()
  const navigate = useNavigate()

  const authuser = useSelector((state: RootState) => state.auth.user)

  useEffect(() => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    window.addEventListener("resize", () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    })
  }, [])

  useEffect(() => {
    if (!authuser) refetch()
  }, [authuser, refetch])

  useEffect(() => {
    if (
      (!userIsFetching && !user) ||
      (userError && "status" in userError && userError.status === 403)
    ) {
      // navigate("/signin/")
      setNotLoggedIn(true)
    }
    if (user) dispatch(setCredentials({ user }))
  }, [navigate, user, userError, userIsFetching, dispatch])

  if (notLoggedIn) return <UnAuthenticatedApp />
  if (!user) return null

  return <AuthenticatedApp />
}

export default App

import { api } from "./api"
import {
  ChangePasswordRequest,
  FoundUser,
  LoginRequest,
  PrivacySettings,
  PrivacySettingsRequest,
  RegisterRequest,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SearchFollowersRequest,
  SearchNotificationsQuery,
  SearchNotificationsResponse,
  SearchUsersQuery,
  SearchUsersResponse,
  SendResetPasswordLinkRequest,
  SendResetPasswordLinkResponse,
  UpdateUserRequest,
  User,
  VerifyRegisterRequest,
} from "./types"

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<void, LoginRequest>({
      query: (body) => ({ url: "api/users/login/", method: "post", body }),
      invalidatesTags: ["User"],
    }),

    register: builder.mutation<void, RegisterRequest>({
      query: (body) => ({ url: "api/users/register/", method: "post", body }),
      invalidatesTags: ["User"],
    }),

    updateUser: builder.mutation<User, UpdateUserRequest>({
      query: (body) => ({
        url: "api/user/update/",
        method: "post",
        body,
      }),
      invalidatesTags: ["User"],
    }),

    verifyUser: builder.mutation<User, VerifyRegisterRequest>({
      query: (body) => ({
        url: "verify-registration/",
        method: "post",
        body,
      }),
      invalidatesTags: ["User"],
    }),

    changePassword: builder.mutation<void, ChangePasswordRequest>({
      query: (body) => ({
        url: "api/users/change-password/",
        method: "post",
        body,
      }),
    }),

    sendResetPasswordLink: builder.mutation<
      SendResetPasswordLinkResponse,
      SendResetPasswordLinkRequest
    >({
      query: (body) => ({
        url: "send-reset-password-link/",
        method: "post",
        body,
      }),
    }),

    resetPassword: builder.mutation<
      ResetPasswordResponse,
      ResetPasswordRequest
    >({
      query: (body) => ({
        url: "api/users/reset-password/",
        method: "post",
        body,
      }),
    }),

    logout: builder.mutation({
      query: () => ({ url: "logout", method: "post" }),
      invalidatesTags: ["User"],
    }),

    profile: builder.query<User, void>({
      query: () => "user/me/",
      providesTags: ["User"],
    }),

    privacySettings: builder.query<PrivacySettings, void>({
      query: () => "api/user/privacy/",
      providesTags: ["PrivacySettings"],
    }),

    setPrivacySettings: builder.mutation<
      PrivacySettings,
      PrivacySettingsRequest
    >({
      query: (body) => ({
        url: "api/user/privacy/",
        method: "post",
        body,
      }),
      invalidatesTags: ["PrivacySettings"],
    }),

    searchUsers: builder.query<SearchUsersResponse, SearchUsersQuery>({
      query: (filter) => ({
        url: "api/users/search",
        params: filter,
      }),
      providesTags: ["User"],
    }),

    getUserByHandle: builder.query<FoundUser, { handle: string }>({
      query: ({ handle }) => ({
        url: `api/users/by_handle/${handle}`,
      }),
      providesTags: ["User"],
    }),

    follow: builder.mutation<void, { handle: string }>({
      query: ({ handle }) => ({
        url: `api/user/follow/${handle}`,
        method: "post",
      }),
      invalidatesTags: ["User"],
    }),

    unfollow: builder.mutation<void, { handle: string }>({
      query: ({ handle }) => ({
        url: `api/user/unfollow/${handle}`,
        method: "post",
      }),
      invalidatesTags: ["User"],
    }),

    searchFollowers: builder.query<SearchUsersResponse, SearchFollowersRequest>(
      {
        query: ({ query, publicHandle }) => ({
          url: `/api/users/followers/${publicHandle}/search`,
          params: query,
        }),
        providesTags: ["User"],
      }
    ),

    searchFollowingUsers: builder.query<
      SearchUsersResponse,
      SearchFollowersRequest
    >({
      query: ({ query, publicHandle }) => ({
        url: `/api/users/following/${publicHandle}/search`,
        params: query,
      }),
      providesTags: ["User"],
    }),

    getNotifications: builder.query<
      SearchNotificationsResponse,
      SearchNotificationsQuery
    >({
      query: (filter) => ({
        url: "api/user/notifications",
        params: filter,
      }),
      providesTags: ["UserNotifications"],
    }),

    ackNotification: builder.mutation<void, { handle: number }>({
      query: ({ handle }) => ({
        url: `api/user/notifications/${handle}/ack`,
        method: "post",
      }),
      invalidatesTags: ["UserNotifications"],
    }),
  }),
})

export const {
  useLoginMutation,
  useRegisterMutation,
  useVerifyUserMutation,
  useChangePasswordMutation,
  useSendResetPasswordLinkMutation,
  useResetPasswordMutation,
  useLogoutMutation,
  useProfileQuery,
  usePrivacySettingsQuery,
  useSetPrivacySettingsMutation,
  useSearchUsersQuery,
  useUpdateUserMutation,
  useGetUserByHandleQuery,
  useFollowMutation,
  useUnfollowMutation,
  useSearchFollowersQuery,
  useSearchFollowingUsersQuery,
  useGetNotificationsQuery,
  useAckNotificationMutation,
} = userApi

import { Box } from "@mui/material"
import { cardToEmoji } from "features/HandHistories/HandHistories"
import { Card, cardToString } from "features/types"
import { useContext, useMemo } from "react"
import { ActionPath } from "./ActionPath"
import { getCurrentAction } from "./ActionPointer"
import { hhContext } from "./HHContext"
import { GameReplayView } from "./PlayerView"
import { positionInGame } from "./RangesView"
import "./styles.css"
import { getPot } from "./TableView"
import {
  HandPositionOrder,
  HandStreet,
  PostflopHandPositionOrder,
} from "./types"

export const HHProp = ({
  label,
  value,
}: {
  label: string
  value: string | undefined
}) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ pr: "8px", flexGrow: 1 }}>{label}:</Box>
      <Box>{value}</Box>
    </Box>
  )
}

export const Board = () => {
  const context = useContext(hhContext)
  const action = context.currentActionIndex

  const communityCards: { [key in HandStreet]: Card[] } = useMemo(() => {
    return {
      preflop: [],
      flop: context.hand.flopCards?.cards || [],
      turn: [
        ...(context.hand.flopCards?.cards || []),
        ...(context.hand.turnCards?.cards || []),
      ],
      river: [
        ...(context.hand.flopCards?.cards || []),
        ...(context.hand.turnCards?.cards || []),
        ...(context.hand.riverCards?.cards || []),
      ],
    }
  }, [context])

  if (action.street == "preflop") return null
  const cards = communityCards[action.street]
  return (
    <Box className="hh-info">
      <Box>Board</Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          fontWeight: "normal",
        }}
      >
        {cards.map((v, idx) => (
          <Box key={idx}>{cardToEmoji(cardToString(v), "streetCard")}</Box>
        ))}
      </Box>
    </Box>
  )
}

export const HHState = () => {
  const context = useContext(hhContext)
  const action = context.currentActionIndex
  const gameState = getCurrentAction(context)
  if (!gameState) return null
  const pot = getPot(context)
  const order =
    action.street == "preflop" ? HandPositionOrder : PostflopHandPositionOrder
  const inGamePositions = order.filter((position) =>
    positionInGame(context.hand, position, context.currentActionIndex)
  )
  inGamePositions.sort((a, b) => order.indexOf(b) - order.indexOf(a))
  const orderedPositions = inGamePositions
  return (
    <>
      <Box
        className="hh-info"
        sx={{ display: "flex", flexDirection: "column", minWidth: "100px" }}
      >
        <HHProp label="Street" value={`${action.street}`} />
        <HHProp label="Pot" value={`${pot?.toFixed(2)}BB`} />
      </Box>
      <Box
        sx={{
          display: "flex",
          border: "1px solid lightgray",
          fontSize: "12px",
          fontWeight: "bold",
          p: "4px",
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "column", minWidth: "100px" }}
        >
          {orderedPositions.slice(0, 4).map((position) => (
            <HHProp
              key={position}
              label={position}
              value={`${gameState?.state.playerStates[position]?.stack.toFixed(
                2
              )}`}
            />
          ))}
        </Box>
        {orderedPositions.length > 4 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: "100px",
              pl: "5px",
              ml: "5px",
              borderLeft: "1px solid #eeeeee",
            }}
          >
            {orderedPositions.slice(4, 8).map((position) => (
              <HHProp
                key={position}
                label={position}
                value={`${gameState?.state.playerStates[
                  position
                ]?.stack.toFixed(2)}`}
              />
            ))}
          </Box>
        )}
        {orderedPositions.length > 8 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: "100px",
              pl: "5px",
              ml: "5px",
              borderLeft: "1px solid #eeeeee",
            }}
          >
            {orderedPositions.slice(8).map((position) => (
              <HHProp
                key={position}
                label={position}
                value={`${gameState?.state.playerStates[
                  position
                ]?.stack.toFixed(2)}`}
              />
            ))}
          </Box>
        )}
      </Box>
    </>
  )
}

export const HHHeader = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          minHeight: "90px",
          flexShrink: 0,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <GameReplayView />
          <ActionPath />
        </Box>
        <Board />
        <HHState />
      </Box>
    </>
  )
}

import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { logout } from "features/auth/authSlice"
import { getCookie } from "lib/cookies"

const baseQuery = fetchBaseQuery({
  baseUrl: "/",
  prepareHeaders: (headers) => {
    const csrftoken = getCookie("csrftoken")
    if (csrftoken) headers.set("X-CSRFToken", csrftoken)
    return headers
  },
})

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    api.dispatch(logout())
  }
  return result
}

export const api = createApi({
  reducerPath: "api",
  tagTypes: [
    "User",
    "HandHistory",
    "HandHistoryDetails",
    "RangeStretegies",
    "Range",
    "BetSizingRangeGroups",
    "BetSizingUserConfig",
    "PrivacySettings",
    "UserNotifications",
    "SharedHandConfig",
  ],

  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
})

import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import { Alert, Grid, Link } from "@mui/material"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useTheme } from "lib/useTheme"
import { FormEvent, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { getErrorData, LoginRequest } from "services/types"
import { useLoginMutation } from "services/userApi"
import { useAuthUser } from "./hooks"
import SocialLogin from "./SocialLogin"

export default function SignIn() {
  const theme = useTheme()
  const [searchParams] = useSearchParams()

  const [login, { isLoading, error }] = useLoginMutation()
  const errorData = getErrorData<LoginRequest>(error)
  const navigate = useNavigate()
  const user = useAuthUser()

  useEffect(() => {
    if (user) {
      navigate(searchParams.get("redirect") || "/")
    }
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const data: LoginRequest = {
      login: (formData.get("login") || "").toString(),
      password: (formData.get("password") || "").toString(),
    }

    try {
      await login(data).unwrap()
      navigate("/")
    } catch (error) {
      ;(() => undefined)()
    }
  }

  const disabled = isLoading

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {errorData && (
            <Alert severity="error">
              {errorData.detail || "Internal server error"}
            </Alert>
          )}
          <Box sx={{ pt: 2 }}></Box>
          <SocialLogin />
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, display: "none" }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="login"
              name="login"
              label="Username"
              disabled={disabled}
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              disabled={disabled}
              autoComplete="current-password"
            />
            {/*<FormControlLabel*/}
            {/*  control={<Checkbox value="remember" color="primary" />}*/}
            {/*  label="Remember me"*/}
            {/*/>*/}
            <Button
              type="submit"
              disabled={disabled}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgot-password/" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup/" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

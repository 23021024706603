import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"
import React, { ReactElement } from "react"
import useSwr, { mutate } from "swr"

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

interface StatusMessageDetails {
  severity?: AlertColor
  message?: string
}

function CustomizedSnackbars() {
  const handleClose = () => {
    mutate("status", {}, false)
  }

  const { data: status } = useSwr<StatusMessageDetails, Error>("status")
  if (status === undefined || status.severity === undefined) {
    return <></>
  }

  return (
    <div>
      <Snackbar
        open={status.severity !== undefined}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={status.severity}>
          {status.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default function StatusMessage(): ReactElement {
  return <CustomizedSnackbars />
}

export function showError(message: string) {
  mutate(
    "status",
    {
      severity: "error",
      message: message,
    },
    false
  )
}

export function showSuccess(message: string) {
  mutate(
    "status",
    {
      severity: "success",
      message: message,
    },
    false
  )
}

import { Box } from "@mui/material"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import "features/HHReplay/styles.css"
import { NotFound } from "lib/Components/PageNotFound"
import { PageTitle } from "lib/Components/PageTitile"
import { useState } from "react"
import { Link, Route, Routes, useLocation } from "react-router-dom"
import { AnyScenario, CommonSpotsScenario } from "./Scenario"
import "./styles.css"

export const PreflopRangeController = () => {
  const path = useLocation()
  const [tab, setTab] = useState(path.pathname)

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <PageTitle text="Pre-Flop Range Explorer" />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              "& .MuiTab-root": {
                textTransform: "none",
              },
            }}
          >
            <Tab
              label="Any Scenario"
              value="/pfrexplorer/any"
              component={Link}
              to={"./any"}
            />
            <Tab
              label="Common Spots"
              value="/pfrexplorer/common"
              component={Link}
              to={"./common"}
            />
          </Tabs>
        </Box>
      </Box>
      <Routes>
        {/* <Route path="/" element={<Navigate to="./any" replace />} /> */}
        <Route path="/any" element={<AnyScenario />} />
        <Route path="/common" element={<CommonSpotsScenario />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Box>
  )
}

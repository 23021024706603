import { Box } from "@mui/material"
import classnames from "classnames"
import { HandActionIndex, hhContext } from "features/HHReplay/HHContext"
import { useGetPositionRange } from "features/HHReplay/hooks"
import { HandAction } from "features/HHReplay/types"
import { getRangeActions } from "features/Ranges/preflop/RangeView"
import { ACTION_CHARS, CHAR_ACTIONS } from "features/Ranges/types"
import { useContext, useMemo } from "react"
import { playerAction } from "./CurrentTurnActions"
import "./styles.css"

const PlayerAction = ({
  index,
  action,
  current,
}: {
  index: HandActionIndex
  action: HandAction
  current: boolean
}) => {
  const context = useContext(hhContext)

  const range = useGetPositionRange(action.position, index)

  const recommendedRaise = range?.raiseSizing
  const allowedActions = useMemo(() => {
    if (!range) return []
    return getRangeActions(range, true)
  }, [range])

  if (!action) return null
  if (!allowedActions || allowedActions.length == 0) return null

  const actor = action.position
  const choosenAction = CHAR_ACTIONS[action.actionChar]
  const streetFinished = action.isManual && action.state.streetFinished

  return (
    <Box
      className={classnames({
        ["current-action"]: current,
      })}
      sx={{
        fontSize: "12px",
        background: "#cccccc",
        px: "4px",
        borderRadius: "4px",
        minWidth: "100px",
        minHeight: "100px",
      }}
    >
      <Box className="position" sx={{ fontWeight: "600" }}>{`${actor}`}</Box>
      <Box>
        {allowedActions
          .filter((v) => (v.combinations || 0) > 0)
          .map((v) => {
            const playerSelection = choosenAction == v.action
            return (
              <Box
                key={v.action}
                className={classnames({
                  ["choosen-action"]:
                    playerSelection && (!current || streetFinished),
                })}
              >
                <Box
                  sx={{ display: "flex", cursor: "pointer" }}
                  onClick={() => {
                    playerAction(
                      context,
                      action,
                      index,
                      ACTION_CHARS[v.action] || "f",
                      v.action == "RAISE" ? recommendedRaise : undefined
                    )
                  }}
                >
                  {v.action == "RAISE" && (
                    <Box
                      sx={{ flexGrow: 1 }}
                    >{`${v.action} ${recommendedRaise}`}</Box>
                  )}
                  {v.action != "RAISE" && (
                    <Box sx={{ flexGrow: 1 }}>{v.action}</Box>
                  )}
                  {current && <Box>{`(${v.percent?.toFixed(0)}%)`}</Box>}
                </Box>
              </Box>
            )
          })}
      </Box>
    </Box>
  )
}

const StreetView = () => {
  const context = useContext(hhContext)
  const currentActionIdx = context.currentActionIndex.index

  const turns: JSX.Element[] = []
  for (let i = 0; i <= currentActionIdx; i++) {
    const action = context.hand.preFlop?.actions[i]
    if (!action) continue

    turns.push(
      <PlayerAction
        key={i}
        index={{ street: "preflop", index: i }}
        action={action}
        current={i == currentActionIdx}
      />
    )
    if (action.isManual && action.state.gameFinished) break
  }

  return (
    <>
      {
        <Box
          sx={{
            flexGrow: 1,
            gap: "8px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {turns}
        </Box>
      }
    </>
  )
}

export function GameTreeView() {
  return (
    <Box
      sx={{
        display: "flex",
        pb: "8px",
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <StreetView />
    </Box>
  )
}

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { HandPosition } from "features/HHReplay/types"
import { RangeSet } from "services/types"
import { RangeParameters } from "../types"
import { ActionKind } from "./Ranges"

export interface RangeSetFilterProps {
  handleChange: (field: ActionKind, value: string | HandPosition) => void
  state: RangeParameters
  strategies: RangeSet[]
}

export function RangeSetFilter(props: RangeSetFilterProps) {
  const { state, handleChange, strategies } = props
  return (
    <Box>
      <Box>RangeSet</Box>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {[...strategies]
          .sort((a, b) => (a.name > b.name ? -1 : 1))
          .map((rangeset) => (
            <Box
              key={rangeset.id}
              sx={{
                mr: "10px",
                mt: "10px",
              }}
            >
              <Button
                sx={{
                  textTransform: "none",
                  minWidth: "100px",
                  borderRadius: "50px",
                  "&.MuiButton-root.MuiButton-contained": {
                    backgroundColor: "darkcyan",
                  },
                }}
                variant={
                  state.rangeset !== rangeset.id ? "outlined" : "contained"
                }
                // TODO disabled state based on other filters data and backend
                onClick={() => handleChange("rangeset", rangeset.id)}
              >
                {rangeset.name}
              </Button>
            </Box>
          ))}
      </Box>
    </Box>
  )
}

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { HandPosition } from "features/HHReplay/types"
import { RangeParameters } from "../types"
import { ActionKind } from "./Ranges"

interface StackSizeFilterProps {
  handleChange: (
    field: ActionKind,
    value: string | number | HandPosition
  ) => void
  state: RangeParameters
  stackSizes: number[]
}

export function StackSizeFilter(props: StackSizeFilterProps) {
  const { state, handleChange, stackSizes } = props
  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Box>Stack size in BB</Box>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {stackSizes.map((size) => (
          <Box key={size} sx={{ mr: "10px", mb: "10px" }}>
            <Button
              sx={{
                textTransform: "none",
                minWidth: "60px",
                borderRadius: "50px",
                "&.MuiButton-root.MuiButton-contained": {
                  backgroundColor: "darkcyan",
                },
              }}
              variant={state.stackSize !== size ? "outlined" : "contained"}
              onClick={() => handleChange("stackSize", size)}
            >
              {size}
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

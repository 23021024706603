/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { HHGame, SimulationMetadata, Winner } from "services/types"
import { Card, cardToString, deepCopy } from "../types"
import { HandActionIndex, HHContext } from "./HHContext"

type BaseHandSection = {
  errors?: string[]
  warnings?: string[]
}

export enum HAND_POSITIONS {
  BB = "BB",
  SB = "SB",
  BTN = "BTN",
  CO = "CO",
  HJ = "HJ",
  LJ = "LJ",
  UTG7 = "UTG7",
  UTG8 = "UTG8",
  UTG9 = "UTG9",
  UTG10 = "UTG10",
}

export type HandPosition = keyof typeof HAND_POSITIONS

export const HandPositionOrder: HandPosition[] = [
  "BB",
  "SB",
  "BTN",
  "CO",
  "HJ",
  "LJ",
  "UTG7",
  "UTG8",
  "UTG9",
  "UTG10",
]

export const PostflopHandPositionOrder: HandPosition[] = [
  "BTN",
  "CO",
  "HJ",
  "LJ",
  "UTG7",
  "UTG8",
  "UTG9",
  "UTG10",
  "BB",
  "SB",
]

export type HandStackSizes = { [key in HandPosition]?: number }

export type HandStackSizesSection = BaseHandSection & HandStackSizes

export function handStackSizesFromString(str: string): HandStackSizesSection {
  const result: HandStackSizesSection = { warnings: [], errors: [] }
  str.split(",").forEach((val) => {
    const stackSizesArray = val.trim().replace(/  +/g, " ").split(" ")
    if (stackSizesArray.length === 2) {
      const key: HandPosition = <HandPosition>stackSizesArray[0]
      if (!Object.keys(HAND_POSITIONS).includes(stackSizesArray[0]))
        result.errors?.push(`Invalid player position ${stackSizesArray[0]}`)
      const value = Number(stackSizesArray[1])
      if (isNaN(value))
        result.errors?.push(`Invalid stack size value ${stackSizesArray[1]}`)
      result[key] = value
    } else {
      result.errors?.push(`Invalid stack size ${val}`)
    }
  })
  return result
}

export type HandPlayersCount = {
  playersCount?: number
}

export type HandPlayersCountSection = BaseHandSection & HandPlayersCount

export type HandHero = {
  heroPosition?: HandPosition
  heroCards?: Card[]
}

export type HandHeroSection = BaseHandSection & HandHero

export type Showdown = { [key in HandPosition]?: Card[] }

export type ShowdownSection = BaseHandSection & Showdown

export function handHeroFromString(str: string): HandHeroSection {
  const result: HandHeroSection = {
    warnings: [],
    errors: [],
  }

  str.split(",").forEach((val) => {
    const heroInfoArray = val.trim().replace(/  +/g, " ").split(" ")
    if (heroInfoArray.length === 2) {
      if (!Object.keys(HAND_POSITIONS).includes(heroInfoArray[0])) {
        result.errors?.push(`Invalid hero position ${heroInfoArray[0]}`)
        return result
      }
    } else {
      result.errors?.push(`Invalid cards defenition ${val}`)
      return result
    }
  })

  const [position, cards] = str.split(",")[0].split(" ")

  const heroCards: Card[] = []
  if (cards && cards.length % 2 == 0 && cards.length <= 4) {
    for (let i = 0; i < cards.length / 2; i++)
      heroCards.push({
        value: cards.substring(i * 2, i * 2 + 1),
        suit: cards.substring(i * 2 + 1, i * 2 + 2),
      })
  } else {
    result.errors?.push(`Invalid cards string ${cards}`)
    return result
  }

  result.heroPosition = position ? (position.trim() as HandPosition) : undefined
  result.heroCards = heroCards

  return result
}

export type NumericActionChar = "b" | "r"
export type NotNumericActionChar = "f" | "x" | "c" | "a"
export type HandActionChar = NumericActionChar | NotNumericActionChar

export const HAND_ACTIONS_STRINGS: { [key in HandActionChar]: string } = {
  f: "Fold",
  x: "Check",
  c: "Call",
  b: "Bet",
  r: "Raise",
  a: "All-In",
}

export type HandStreet = "preflop" | "flop" | "turn" | "river"

export const HAND_STREETS_STRINGS: { [key in HandStreet]: string } = {
  preflop: "Preflop",
  flop: "Flop",
  turn: "Turn",
  river: "River",
}

export type HandPlayerState = "IN_GAME" | "OUT_OF_GAME" | "MISSING" | "ALL_IN"

export type PlayerStateRangeFilter = {
  path: PlayerAction[]
  actionChar: HandActionChar | undefined
}

export type HandPlayerStateInfo = {
  state: HandPlayerState
  stack: number
  betSum: number
  debt: number
  forcedBet: number
  actionIsMade: boolean
  rangeFilters: { [key in HandStreet]?: PlayerStateRangeFilter }
}

export type HandPlayerStateInfos = {
  [key in HandPosition]?: HandPlayerStateInfo
}

export type HandState = {
  playerStates: HandPlayerStateInfos
  pot: number
  streetFinished: boolean
  gameFinished: boolean
  winner?: HandPosition
  lastRaiseAmount: number
  playersActions: { [key in HandStreet]: PlayerAction[] }
} & BaseHandSection

export type ForcedBetsState = {
  str: string
} & HandState

export type PlayerAction = {
  position: HandPosition
  actionChar: HandActionChar
  raisingAmount: number | null
}

export type HandAction = PlayerAction & {
  actionString: string
  state: HandState
  isManual: boolean
}

export type HandActions = {
  actions: HandAction[]
}
export type StreetSection = HandActions & BaseHandSection

export type Cards = {
  cards: Card[]
}

export type CardsSection = Cards & BaseHandSection

const APPLY_SKIPPED_ACTIONS = true

function forcedBetsFromString(
  str: string,
  beginningState: HandState
): ForcedBetsState {
  const result: ForcedBetsState = { str: str, ...deepCopy(beginningState) }
  result.errors = []
  result.warnings = []
  const playersCount = Object.keys(beginningState.playerStates).length
  for (const betString of str.split(",").map((v) => v.trim())) {
    const betArray = betString.trim().replace(/  +/g, " ").split(" ")
    if (betArray.length === 2) {
      const position: HandPosition = <HandPosition>betArray[0]
      if (
        !Object.keys(HAND_POSITIONS)
          .filter((v, i) => i < playersCount)
          .includes(betArray[0])
      )
        result.errors?.push(`Invalid forced bet player position ${betArray[0]}`)
      const amount = Number(
        betArray[1].endsWith("bb")
          ? betArray[1].substring(0, betArray[1].length - 2)
          : betArray[1]
      )
      if (isNaN(amount))
        result.errors?.push(`Invalid forced bet value ${betArray[1]}`)

      const currentPlayerState: HandPlayerStateInfo =
        result.playerStates[<HandPosition>position]!
      if (currentPlayerState.stack < amount) {
        throw Error(
          `Invalid forced bet ${position} ${amount} - player can't bet ${amount} stack is ${currentPlayerState.stack}`
        )
      }
      result.pot = result.pot + amount
      result.lastRaiseAmount =
        result.lastRaiseAmount < amount ? amount : result.lastRaiseAmount
      currentPlayerState.betSum = currentPlayerState.betSum + amount
      currentPlayerState.stack = currentPlayerState.stack - amount
      currentPlayerState.forcedBet = currentPlayerState.forcedBet + amount
      currentPlayerState.debt =
        currentPlayerState.debt > amount ? currentPlayerState.debt - amount : 0

      Object.entries(result.playerStates).forEach(
        ([playerPosition, player]) => {
          if (playerPosition !== position && player.state === "IN_GAME") {
            player.debt =
              currentPlayerState.forcedBet > player.forcedBet
                ? currentPlayerState.forcedBet - player.forcedBet
                : 0
          }
        }
      )
      // set betSum to ZERO for each player if they have no debts for ante bets skipping
      let maxDebt = 0
      Object.entries(result.playerStates).forEach(([, player]) => {
        if (player.state === "IN_GAME" && player.debt > maxDebt) {
          maxDebt = player.debt
        }
      })
      if (maxDebt === 0) {
        Object.entries(result.playerStates).forEach(([, player]) => {
          if (player.state === "IN_GAME") {
            player.betSum = 0
          }
        })
      }
    } else {
      result.errors?.push(`Invalid stack size ${betString}`)
    }
  }
  if (result.errors && result.errors.length > 0) return result
  return result
}

function processAction(
  currentPosition: HandPosition,
  actionChar: HandActionChar,
  amount: number | null,
  prevState: HandState,
  prevPosition: HandPosition,
  street: HandStreet,
  isManual = true
): HandAction[] {
  let result: HandAction[] = []
  if (prevState.gameFinished) {
    throw Error(
      `Invalid action ${currentPosition} ${actionChar} - game finished, winner is ${prevState.winner}`
    )
  }
  if (prevState.streetFinished) {
    throw Error(
      `Invalid action ${currentPosition} ${actionChar} - current street finished`
    )
  }
  const playersCount: number = Object.keys(prevState.playerStates).length
  const prevPositionIndex = Object.keys(HAND_POSITIONS).indexOf(prevPosition)
  const currentPositionIndex =
    Object.keys(HAND_POSITIONS).indexOf(currentPosition)
  let skippedPositions: HandPosition[]
  if (prevPositionIndex > currentPositionIndex) {
    skippedPositions = <Array<HandPosition>>Object.keys(HAND_POSITIONS)
      .filter((v, i) => i < playersCount)
      .filter((v, i) => i < prevPositionIndex && i > currentPositionIndex)
      .reverse()
  } else {
    skippedPositions = <Array<HandPosition>>Object.keys(HAND_POSITIONS)
      .filter((v, i) => i < playersCount)
      .filter((v, i) => i < prevPositionIndex)
      .reverse()
      .concat(
        Object.keys(HAND_POSITIONS)
          .filter((v, i) => i < playersCount)
          .filter((v, i) => i > currentPositionIndex)
          .reverse()
      )
  }
  let prevFoldState: HandState = prevState
  let prevFoldPosition: HandPosition = prevPosition
  skippedPositions.forEach((p) => {
    if (prevFoldState.playerStates[p]) {
      const playerState = prevFoldState.playerStates[p]
      if (playerState!.state === "IN_GAME") {
        if (playerState!.debt == 0) {
          const foldActions: HandAction[] = processAction(
            p,
            "x",
            null,
            prevFoldState,
            prevFoldPosition,
            street,
            false
          )
          if (APPLY_SKIPPED_ACTIONS) result = result.concat(foldActions)
          prevFoldState = foldActions[foldActions.length - 1].state
          prevFoldPosition = p
        } else if (playerState!.debt > 0) {
          const checkActions: HandAction[] = processAction(
            p,
            "f",
            null,
            prevFoldState,
            prevFoldPosition,
            street,
            false
          )
          if (APPLY_SKIPPED_ACTIONS) result = result.concat(checkActions)
          prevFoldState = checkActions[checkActions.length - 1].state
          prevFoldPosition = p
        } else {
          throw Error(
            `Invalid action ${currentPosition} ${actionChar} - ${p} player action expected`
          )
        }
      }
    } else {
      throw Error(
        `Invalid action ${currentPosition} ${actionChar} - no such player`
      )
    }
  })

  if (prevFoldState.gameFinished) {
    throw Error(
      `Invalid action ${currentPosition} ${actionChar} - game finished, winner is ${prevFoldState.winner}`
    )
  }
  if (prevFoldState.streetFinished) {
    throw Error(
      `Invalid action ${currentPosition} ${actionChar} - current street finished`
    )
  }

  const currentAction: HandAction = deepCopy({
    position: currentPosition,
    actionChar: actionChar,
    actionString: HAND_ACTIONS_STRINGS[actionChar],
    raisingAmount: amount,
    state: result.length > 0 ? result[result.length - 1].state : prevState,
    isManual: isManual,
  })
  if (currentAction.state.playerStates[currentPosition]) {
    const currentPlayerState: HandPlayerStateInfo =
      currentAction.state.playerStates[currentPosition]!
    if (currentPlayerState.state !== "IN_GAME")
      throw Error(
        `Invalid action ${currentPosition} ${actionChar} - player is out of game`
      )

    const lastAction =
      prevState.playersActions[street].length > 0
        ? prevState.playersActions[street].slice(-1)[0]
        : null

    currentAction.state.playersActions[street].push({
      actionChar: currentAction.actionChar,
      position: currentAction.position,
      raisingAmount: currentAction.raisingAmount,
    })

    Object.entries(currentAction.state.playerStates).forEach(
      ([position, player]) => {
        if (player.state === "IN_GAME" || player.state === "ALL_IN") {
          const actions = deepCopy(currentAction.state.playersActions[street])
          const popped = actions.pop()
          if (popped) {
            if (player.debt > 0 && player.state !== "ALL_IN") {
              player.rangeFilters[street] = {
                path: actions,
                actionChar: undefined,
              }
            } else if (lastAction && position === lastAction.position) {
              player.rangeFilters[street] = {
                path: player.rangeFilters[street]!.path,
                actionChar: lastAction.actionChar,
              }
            }
          }
        }
      }
    )

    let raisingAmount: number
    switch (actionChar) {
      case "f":
        currentPlayerState.state = "OUT_OF_GAME"
        break
      case "x":
        break
      case "c":
        if (currentPlayerState.stack < currentPlayerState.debt) {
          throw Error(
            `Invalid action ${currentPosition} ${actionChar} - player can't call ${currentPlayerState.debt} stack is ${currentPlayerState.stack}`
          )
        }
        currentAction.state.pot =
          currentAction.state.pot + currentPlayerState.debt
        currentPlayerState.betSum =
          currentPlayerState.betSum + currentPlayerState.debt
        currentPlayerState.stack =
          currentPlayerState.stack - currentPlayerState.debt
        currentPlayerState.debt = 0
        break
      case "b":
        if (currentPlayerState.stack < amount!) {
          throw Error(
            `Invalid action ${currentPosition} ${actionChar} - player can't bet ${amount!} stack is ${
              currentPlayerState.stack
            }`
          )
        }
        if (currentPlayerState.debt !== 0) {
          throw Error(
            `Invalid action ${currentPosition} ${actionChar} - player must call ${currentPlayerState.debt}`
          )
        }
        if (amount! < 1) {
          throw Error(
            `Invalid action ${currentPosition} ${actionChar}${amount!} - bet amount can not be less than 1bb`
          )
        }
        currentAction.state.pot = currentAction.state.pot + amount!
        currentPlayerState.betSum = currentPlayerState.betSum + amount!
        currentPlayerState.stack = currentPlayerState.stack - amount!
        Object.entries(currentAction.state.playerStates).forEach(
          ([position, player]) => {
            if (position !== currentPosition && player.state === "IN_GAME") {
              player.debt = player.debt = player.debt + amount!
            }
          }
        )
        currentAction.state.lastRaiseAmount = amount!
        break
      case "r":
        {
          if (currentPlayerState.stack < amount! - currentPlayerState.betSum) {
            throw Error(
              `Invalid action ${currentPosition} ${actionChar} - player can't raise ${amount!} stack is ${
                currentPlayerState.stack
              }`
            )
          }
          if (currentPlayerState.debt + currentPlayerState.betSum >= amount!) {
            throw Error(
              `Invalid action ${currentPosition} ${actionChar} - player can't raise ${amount!}, amount must be greater then ${
                currentPlayerState.debt + currentPlayerState.betSum
              }`
            )
          }
          if (amount! < currentAction.state.lastRaiseAmount) {
            throw Error(
              `Invalid action ${currentPosition} ${actionChar}${amount!} - raise amount can not be less than ${
                currentAction.state.lastRaiseAmount
              }`
            )
          }
          const potIncreaseAmount = amount! - currentPlayerState.betSum
          const debtIncreaseAmount = potIncreaseAmount - currentPlayerState.debt
          currentAction.state.pot = currentAction.state.pot + potIncreaseAmount
          currentPlayerState.betSum = amount!
          currentPlayerState.stack =
            currentPlayerState.stack - potIncreaseAmount
          currentPlayerState.debt = 0
          Object.entries(currentAction.state.playerStates).forEach(
            ([position, player]) => {
              if (position !== currentPosition && player.state === "IN_GAME") {
                player.debt = player.debt + debtIncreaseAmount
              }
            }
          )
          currentAction.state.lastRaiseAmount = amount!
        }
        break
      case "a":
        raisingAmount =
          currentPlayerState.stack > currentPlayerState.debt
            ? currentPlayerState.stack - currentPlayerState.debt
            : 0
        currentAction.state.pot =
          currentAction.state.pot + currentPlayerState.stack
        currentPlayerState.betSum =
          currentPlayerState.betSum + currentPlayerState.stack
        currentPlayerState.debt =
          currentPlayerState.debt > currentPlayerState.stack
            ? currentPlayerState.debt - currentPlayerState.stack
            : 0
        currentPlayerState.stack = 0
        currentPlayerState.state = "ALL_IN"
        if (raisingAmount) {
          Object.entries(currentAction.state.playerStates).forEach(
            ([position, player]) => {
              if (position !== currentPosition && player.state === "IN_GAME") {
                player.debt = player.debt + raisingAmount
              }
            }
          )
        }
        break
    }
    currentPlayerState.actionIsMade = true
    // checking for game or river is finished
    const activePlayers = Object.entries(currentAction.state.playerStates)
      .filter(([, player]) => {
        return player.state === "IN_GAME"
      })
      .map(([, player]) => player)
    if (
      activePlayers.length == 0 ||
      (activePlayers.length == 1 &&
        activePlayers[0].stack > 0 &&
        activePlayers[0].debt === 0 &&
        activePlayers[0].actionIsMade)
    ) {
      currentAction.state.streetFinished = true
      currentAction.state.gameFinished = true
    }
    const inGamePlayers = Object.entries(
      currentAction.state.playerStates
    ).filter(([, player]) => {
      return player.state === "IN_GAME" || player.state === "ALL_IN"
    })
    const inGamePlayersWithoutDebt = Object.entries(
      currentAction.state.playerStates
    ).filter(
      ([, value]) =>
        (value.state === "IN_GAME" && value.debt === 0 && value.actionIsMade) ||
        value.state === "ALL_IN"
    )
    if (inGamePlayers.length === inGamePlayersWithoutDebt.length) {
      if (inGamePlayers.length === 1) {
        currentAction.state.streetFinished = true
        currentAction.state.gameFinished = true
        currentAction.state.winner = inGamePlayers.map(
          ([key]) => <HandPosition>key
        )[0]
      } else {
        currentAction.state.streetFinished = true
      }
    }
  }
  result.push(currentAction)
  return result
}

export function handActionFromString(
  str: string,
  prevState: HandState,
  prevPosition: HandPosition,
  street: HandStreet
): HandAction[] {
  const actionDataArray = str.trim().split(" ")
  if (actionDataArray.length == 2) {
    const position: HandPosition = <HandPosition>actionDataArray[0]
    if (!Object.keys(HAND_POSITIONS).includes(actionDataArray[0]))
      throw Error(`Invalid player position ${actionDataArray[0]}`)

    const actionChar: HandActionChar = <HandActionChar>(
      actionDataArray[1].charAt(0)
    )
    if (!HAND_ACTIONS_STRINGS[actionChar])
      throw Error(`Invalid action character ${actionChar}`)
    let risingAmount = null
    if (actionChar === "b" || actionChar === "r") {
      risingAmount = Number(actionDataArray[1].substring(1))
      if (isNaN(risingAmount))
        throw Error(`Invalid action amount ${risingAmount}`)
    }
    const currentActions = processAction(
      position,
      actionChar,
      risingAmount,
      prevState,
      prevPosition,
      street,
      true
    )
    return currentActions
  } else {
    throw Error(`Invalid action ${actionDataArray}`)
  }
}

export function completeActions(
  prevState: HandState,
  prevPosition: HandPosition,
  street: HandStreet
): HandAction[] {
  let result: HandAction[] = []
  if (prevState.gameFinished) return result
  const playersCount: number = Object.keys(prevState.playerStates).length
  const prevPositionIndex = Object.keys(HAND_POSITIONS).indexOf(prevPosition)
  const skippedPositions = <Array<HandPosition>>Object.keys(HAND_POSITIONS)
    .filter((v, i) => i < playersCount)
    .filter((v, i) => i < prevPositionIndex)
    .reverse()
    .concat(
      Object.keys(HAND_POSITIONS)
        .filter((v, i) => i < playersCount)
        .filter((v, i) => i >= prevPositionIndex)
        .reverse()
    )

  let prevSkippedState: HandState = prevState
  let prevSkippedPosition: HandPosition = prevPosition
  skippedPositions.forEach((p) => {
    if (prevSkippedState.playerStates[p]) {
      const playerState = prevSkippedState.playerStates[p]
      if (playerState!.state === "IN_GAME") {
        if (playerState!.debt > 0 || !playerState!.actionIsMade) {
          const skippedActions: HandAction[] = processAction(
            p,
            playerState!.debt > 0 ? "f" : "x",
            null,
            prevSkippedState,
            prevSkippedPosition,
            street,
            false
          )
          if (APPLY_SKIPPED_ACTIONS) result = result.concat(skippedActions)
          prevSkippedState = skippedActions[skippedActions.length - 1].state
          prevSkippedPosition = p
          result.concat(skippedActions)
        }
      }
    } else {
      throw Error(`Invalid position ${p} - no such player`)
    }
  })
  return result
}

export function handStreetFromString(
  str: string,
  beginningState: HandState,
  street: HandStreet
): StreetSection {
  const result: StreetSection = { warnings: [], errors: [], actions: [] }
  const actionsStrings = str.split(",").map((v) => v.trim())
  let currentState: HandState = beginningState
  let currentPosition: HandPosition = street === "preflop" ? "BB" : "BTN"
  for (const actionString of actionsStrings) {
    if (actionString.trim().length > 0) {
      try {
        const actions = handActionFromString(
          actionString,
          currentState,
          currentPosition,
          street
        )
        if (actions.length > 0) {
          currentState = actions[actions.length - 1].state
          currentPosition = actions[actions.length - 1].position
        }
        result.actions = result.actions.concat(actions)
      } catch (ex) {
        result.errors?.push((<Error>ex).message)
        break
      }
    }
  }
  try {
    result.actions = result.actions.concat(
      completeActions(currentState, currentPosition, street)
    )
  } catch (ex) {
    result.errors?.push((<Error>ex).message)
  }
  if (result.actions && result.actions.length > 0) {
    result.actions[result.actions.length - 1].state.streetFinished = true
    if (street === "river") {
      result.actions[result.actions.length - 1].state.gameFinished = true
    }
  }

  return result
}

export type Hand = {
  id: string | undefined
  stringRepresentation: string
  nicknames?: { [key in HandPosition]?: string }
  winners?: { [key in HandPosition]: Winner }
  game?: HHGame
  playersCount?: HandPlayersCountSection
  beginningState?: HandState
  forcedBetsState?: ForcedBetsState
  hero?: HandHeroSection
  preFlop?: StreetSection
  flopCards?: CardsSection
  flop?: StreetSection
  turnCards?: CardsSection
  turn?: StreetSection
  riverCards?: CardsSection
  river?: StreetSection
  showDownSection?: ShowdownSection
  lastState?: HandState
  metadata?: SimulationMetadata
  hasSimulation: boolean
}

function cardsToString(cards: Card[], prefix?: HandStreet): string {
  let result = " "
  if (prefix) {
    result += prefix + ": "
  }
  result += cards.map((c) => cardToString(c)).join("")
  result += ";"
  return result
}

function actionsToString(actions: HandAction[]): string {
  let result = " "
  result += actions
    .filter((action) => action.isManual)
    .map(
      (action) =>
        `${action.position} ${action.actionChar}${
          action.raisingAmount ? action.raisingAmount : ""
        }`
    )
    .join(", ")
  result += ";"
  return result
}

export function handToString(hand: Hand): string {
  let rv = `${hand.playersCount?.playersCount}-handed;`
  if (hand.beginningState?.playerStates) {
    const stacks = Object.entries(hand.beginningState?.playerStates)
      .map(([k, v]) => `${k} ${v.stack}`)
      .join(", ")
    rv += stacks + ";"
  }
  if (hand.forcedBetsState?.str) {
    rv += hand.forcedBetsState?.str + ";"
  }
  if (hand.hero?.heroPosition) {
    rv += `${hand.hero?.heroPosition} `
    if (hand.hero?.heroCards)
      rv += hand.hero?.heroCards.map((c) => cardToString(c)).join("") + ";"
  }
  if (hand.preFlop?.actions) rv += actionsToString(hand.preFlop?.actions)

  if (hand.flopCards?.cards) rv += cardsToString(hand.flopCards.cards, "flop")
  if (hand.flop?.actions) rv += actionsToString(hand.flop?.actions)

  if (hand.turnCards?.cards) rv += cardsToString(hand.turnCards.cards, "turn")
  if (hand.turn?.actions) rv += actionsToString(hand.turn?.actions)

  if (hand.riverCards?.cards)
    rv += cardsToString(hand.riverCards.cards, "river")
  if (hand.river?.actions) rv += actionsToString(hand.river?.actions)

  if (hand.showDownSection) rv += showdownToString(hand.showDownSection)

  return rv
}

export function handCardsFromString(
  str: string,
  prefix: string,
  cardsCount: number
): CardsSection {
  const result: CardsSection = {
    cards: [],
    warnings: [],
    errors: [],
  }
  const cards = str
    .trim()
    .replace(/  +/g, " ")
    .replace(prefix + ":", "")
    .trim()
  if (cards && cards.length % 2 == 0) {
    if (cards.length == cardsCount * 2) {
      for (let i = 0; i < cards.length / 2; i++) {
        result.cards.push({
          value: cards.substring(i * 2, i * 2 + 1),
          suit: cards.substring(i * 2 + 1, i * 2 + 2),
        })
      }
    } else {
      result.errors?.push(`Invalid ${prefix} cards length: ${cards.length / 2}`)
    }
  } else {
    result.errors?.push(`Invalid cards string ${cards}`)
  }

  return result
}

export function showdownFromString(str: string): ShowdownSection {
  const result: ShowdownSection = { errors: [], warnings: [] }

  const positionsCardsString = str
    .trim()
    .replace(/  +/g, " ")
    .replace("showdown:", "")
    .trim()

  positionsCardsString.split(",").forEach((val) => {
    const positionCardsArray = val.trim().replace(/  +/g, " ").split(" ")
    if (positionCardsArray.length === 2) {
      if (!Object.keys(HAND_POSITIONS).includes(positionCardsArray[0])) {
        result.errors?.push(
          `Invalid showdown position ${positionCardsArray[0]}`
        )
        return result
      }
    } else {
      result.errors?.push(`Invalid showdown cards defenition ${val}`)
      return result
    }
  })

  positionsCardsString.split(",").map((value) => {
    const [positionStr, cards] = value.trim().split(" ")
    const positionCards: Card[] = []
    if (cards && cards.length % 2 == 0 && cards.length <= 4) {
      for (let i = 0; i < cards.length / 2; i++)
        positionCards.push({
          value: cards.substring(i * 2, i * 2 + 1),
          suit: cards.substring(i * 2 + 1, i * 2 + 2),
        })
      const position: HandPosition = <HandPosition>positionStr
      result[position] = positionCards
    } else {
      result.errors?.push(`Invalid showdoun cards string ${cards}`)
      return result
    }
  })

  return result
}

export function showdownToString(showdown: Showdown): string {
  let result = "showdown: "
  result += Object.entries(showdown)
    .filter(([position]) => Object.keys(HAND_POSITIONS).includes(position))
    .map(([position, cards]) => {
      return position + " " + cards.map((c) => cardToString(c)).join("")
    })
    .join(", ")
  result += ";"
  return result
}

export function handFromString(
  id: string | undefined,
  str: string,
  nicknames?: { [key in HandPosition]?: string },
  winners?: { [key in HandPosition]: Winner },
  hasSimulation?: boolean,
  game?: HHGame
): Hand {
  const result: Hand = {
    id: id,
    stringRepresentation: str,
    nicknames: nicknames,
    winners: winners,
    hasSimulation: hasSimulation || false,
    game: game,
  }
  const [
    playersCountString,
    stackSizesString,
    forcedBetsString,
    heroString,
    preflopString,
    flopCardsString,
    flopString,
    turnCardsString,
    turnString,
    riverCardsString,
    riverString,
    showdownString,
  ] = str.split(";").map((v) => v.trim())
  if (playersCountString !== null && playersCountString !== undefined) {
    const section: HandPlayersCountSection = { warnings: [], errors: [] }
    const value = Number(
      playersCountString.replace("-handed", "").split("-")[0]
    )
    if (isNaN(value) || value < 2 || value > 10) {
      section.errors?.push(
        `Invalid players count value ${playersCountString}, the number of players must be a number from 2 to 10`
      )
      return result
    } else {
      section.playersCount = value
    }
    result.playersCount = section
  }

  if (stackSizesString !== null && stackSizesString !== undefined) {
    const stackSizes = handStackSizesFromString(stackSizesString)
    result.beginningState = {
      pot: 0,
      streetFinished: false,
      gameFinished: false,
      playerStates: {},
      lastRaiseAmount: 0,
      playersActions: {
        preflop: [],
        flop: [],
        turn: [],
        river: [],
      },
      errors: [],
      warnings: [],
    }
    result.beginningState.warnings = stackSizes.warnings
    result.beginningState.errors = stackSizes.errors
    if (stackSizes.errors && stackSizes.errors.length > 0) {
      return result
    }
    const playersCount =
      result.playersCount && result.playersCount.playersCount
        ? result.playersCount.playersCount
        : 0
    for (let i = 0; i < playersCount; i++) {
      const currentPosition = HandPositionOrder[i]

      result.beginningState.playerStates[currentPosition] = {
        state: stackSizes[currentPosition] ? "IN_GAME" : "MISSING",
        stack:
          stackSizes[currentPosition] !== undefined
            ? stackSizes[currentPosition]!
            : 0,
        betSum: 0,
        debt: 0,
        forcedBet: 0,
        actionIsMade: false,
        rangeFilters: {
          preflop: {
            path:
              currentPosition === "BB"
                ? [
                    {
                      position: "SB",
                      actionChar: "c",
                      raisingAmount: null,
                    },
                  ]
                : [],
            actionChar: undefined,
          },
          flop: {
            path: [],
            actionChar: undefined,
          },
          turn: {
            path: [],
            actionChar: undefined,
          },
          river: {
            path: [],
            actionChar: undefined,
          },
        },
      }
    }
  }

  if (forcedBetsString !== null && forcedBetsString !== undefined) {
    result.forcedBetsState = forcedBetsFromString(
      forcedBetsString,
      result.beginningState!
    )
    if (
      result.forcedBetsState.errors &&
      result.forcedBetsState.errors.length > 0
    )
      return result
  }

  if (heroString !== null && heroString !== undefined) {
    result.hero = handHeroFromString(heroString)
    if (result.hero.errors && result.hero.errors.length > 0) return result
  }

  result.lastState = deepCopy(result.forcedBetsState!)
  if (
    preflopString !== null &&
    preflopString !== undefined &&
    result.forcedBetsState
  ) {
    result.preFlop = handStreetFromString(
      preflopString,
      result.forcedBetsState,
      "preflop"
    )
    if (result.preFlop.errors && result.preFlop.errors.length > 0) return result

    if (result.preFlop.actions && result.preFlop.actions.length > 0) {
      result.lastState = deepCopy(
        result.preFlop.actions[result.preFlop.actions.length - 1].state
      )
    }
  }

  if (flopCardsString) {
    result.flopCards = handCardsFromString(flopCardsString, "flop", 3)
    if (result.flopCards.errors && result.flopCards.errors.length > 0)
      return result
  }

  if (flopString !== null && flopString !== undefined) {
    const beginingState: HandState = deepCopy(result.lastState)
    Object.entries(beginingState.playerStates).forEach(([, value]) => {
      if (value.state === "IN_GAME") {
        value.actionIsMade = false
      }
      value.betSum = 0
    })
    beginingState.streetFinished = false
    beginingState.lastRaiseAmount = 0
    result.flop = handStreetFromString(flopString, beginingState, "flop")
    if (result.flop.errors && result.flop.errors.length > 0) return result

    if (result.flop.actions && result.flop.actions.length > 0) {
      result.lastState = deepCopy(
        result.flop.actions[result.flop.actions.length - 1].state
      )
    }
  }

  if (turnCardsString) {
    result.turnCards = handCardsFromString(turnCardsString, "turn", 1)
    if (result.turnCards.errors && result.turnCards.errors.length > 0)
      return result
  }

  if (turnString !== null && turnString !== undefined) {
    const beginingState: HandState = deepCopy(result.lastState)
    Object.entries(beginingState.playerStates).forEach(([, value]) => {
      if (value.state === "IN_GAME") {
        value.actionIsMade = false
      }
      value.betSum = 0
    })
    beginingState.streetFinished = false
    beginingState.lastRaiseAmount = 0
    result.turn = handStreetFromString(turnString, beginingState, "turn")
    if (result.turn.errors && result.turn.errors.length > 0) return result

    if (result.turn.actions && result.turn.actions.length > 0) {
      result.lastState = deepCopy(
        result.turn.actions[result.turn.actions.length - 1].state
      )
    }
  }

  if (riverCardsString) {
    result.riverCards = handCardsFromString(riverCardsString, "river", 1)
    if (result.riverCards.errors && result.riverCards.errors.length > 0)
      return result
  }

  if (riverString !== null && riverString !== undefined) {
    const beginingState: HandState = deepCopy(result.lastState)
    Object.entries(beginingState.playerStates).forEach(([, value]) => {
      if (value.state === "IN_GAME") {
        value.actionIsMade = false
      }
      value.betSum = 0
    })
    beginingState.streetFinished = false
    beginingState.lastRaiseAmount = 0
    result.river = handStreetFromString(riverString, beginingState, "river")
    if (result.river.errors && result.river.errors.length > 0) return result

    if (result.river.actions && result.river.actions.length > 0) {
      result.lastState = deepCopy(
        result.river.actions[result.river.actions.length - 1].state
      )
      result.lastState.gameFinished = true
    }
  }

  if (
    result.lastState &&
    result.lastState.gameFinished &&
    !result.lastState.winner
  ) {
    if (showdownString !== null && showdownString !== undefined) {
      result.showDownSection = showdownFromString(showdownString)
    } else {
      result.showDownSection = {
        errors: ["Showdown section requred, winner not deffined"],
      }
    }
  }

  // todo: define hand winner

  return result
}

function findSectionInString(src: string, delimiter: string, position: number) {
  const re = new RegExp(delimiter, "g")
  const sections = src.split(delimiter)
  const sectionIndexes = Array.from(src.matchAll(re))
  const sectionN = sectionIndexes.findIndex(
    (e) => e.index && e.index > position
  )

  const rv = sectionN < 0 ? sections.length - 1 : sectionN
  const sectionOffset = (sectionIndexes[rv - 1]?.index || 0) + 1
  const offset = position - sectionOffset - 1
  return {
    data: sections[rv],
    index: rv,
    sectionOffset: offset,
  }
}

function getExplicitActionIndex(hand: Hand, action: HandActionIndex) {
  const explicitActions: number[] = []
  switch (action.street) {
    case "preflop":
      hand.preFlop?.actions.forEach((v, index) => {
        if (v.isManual) {
          explicitActions.push(index)
        }
      })
      break
    case "flop":
      hand.flop?.actions.forEach((v, index) => {
        if (v.isManual) {
          explicitActions.push(index)
        }
      })
      break
    case "turn":
      hand.turn?.actions.forEach((v, index) => {
        if (v.isManual) {
          explicitActions.push(index)
        }
      })
      break
    case "river":
      hand.river?.actions.forEach((v, index) => {
        if (v.isManual) {
          explicitActions.push(index)
        }
      })
      break
  }
  const rv = {
    street: action.street,
    index: explicitActions[action.index] ?? -1,
  }
  return rv
}

export function actionForPosition(handString: string, cursorPosition: number) {
  const section = findSectionInString(handString, ";", cursorPosition)
  if ([4, 6, 8, 10].indexOf(section.index) < 0) return undefined
  // 4 - preflop, 6 - flop, 8 - turn, 10 - river
  const rv = {
    index: findSectionInString(section.data, ",", section.sectionOffset).index,
    street: "preflop",
  }
  switch (section.index) {
    case 4:
      rv.street = "preflop"
      break
    case 6:
      rv.street = "flop"
      break
    case 8:
      rv.street = "turn"
      break
    case 10:
      rv.street = "river"
      break
  }
  return rv
}

export function contextActionForPosition(
  context: HHContext,
  cursorPosition: number
) {
  const action = actionForPosition(
    context.hand.stringRepresentation,
    cursorPosition
  ) as HandActionIndex
  if (action) {
    const handAction = getExplicitActionIndex(context.hand, action)
    context.setCurrentActionIndex(handAction)
  }
}

function getActionIndexInString(hand: Hand, action: HandActionIndex) {
  const explicitActions: (number | undefined)[] = []
  let explicitIndex = 0
  switch (action.street) {
    case "preflop":
      hand.preFlop?.actions.forEach((v) => {
        explicitActions.push(v.isManual ? explicitIndex++ : undefined)
      })
      break
    case "flop":
      hand.flop?.actions.forEach((v) => {
        explicitActions.push(v.isManual ? explicitIndex++ : undefined)
      })
      break
    case "turn":
      hand.turn?.actions.forEach((v) => {
        explicitActions.push(v.isManual ? explicitIndex++ : undefined)
      })
      break
    case "river":
      hand.river?.actions.forEach((v) => {
        explicitActions.push(v.isManual ? explicitIndex++ : undefined)
      })
      break
  }
  const rv = {
    street: action.street,
    index: explicitActions[action.index] ?? -1,
  }
  return rv
}

export function actionPosition(context: HHContext, action: HandActionIndex) {
  if (!context.hand) return
  if (!action || action.index == -1) return
  const handString = context.hand.stringRepresentation
  const sectionsDelimiters = Array.from(handString.matchAll(/;/g))
  const sections = handString.split(";")
  const sectionIndex: { [key in HandStreet]: number } = {
    preflop: 3,
    flop: 5,
    turn: 7,
    river: 9,
  }
  const offset =
    (sectionsDelimiters[sectionIndex[action.street]].index || 0) + 1
  const section = sections[sectionIndex[action.street] + 1]
  const streetDelimiter = Array.from(section.matchAll(/,/g))
  const explicitIndex = getActionIndexInString(context.hand, action)
  const streetOffset =
    (streetDelimiter[explicitIndex.index - 1]?.index || -1) + 1
  return offset + streetOffset
}

export function setHandPositionCard(
  context: HHContext,
  position: HandPosition,
  playerCard: Card,
  index: 0 | 1
) {
  if (!context.hand) return
  if (context.hand.hero?.heroPosition == position) {
    const cards = context.hand.hero.heroCards || []
    cards[index] = playerCard
    context.hand.hero.heroCards = cards
  } else {
    // showdown cards
    if (!context.hand.showDownSection) return
    const cards = context.hand.showDownSection[position] || []
    cards[index] = playerCard
    context.hand.showDownSection[position] = cards
  }
  context.setHand(handFromString(context.hand.id, handToString(context.hand)))
}

export function setHandPositionCards(
  context: HHContext,
  position: HandPosition,
  playerCards: Card[]
) {
  if (!context.hand) return
  let hhString = handToString(context.hand)
  if (context.hand.hero?.heroPosition == position) {
    context.hand.hero.heroCards = playerCards
    hhString = stripHandToSection("HERO", context.hand)
  } else {
    // showdown cards
    if (!context.hand.showDownSection) return
    context.hand.showDownSection[position] = playerCards
  }
  context.setHand(handFromString(context.hand.id, hhString))
}

export function setCommunityCard(
  context: HHContext,
  position: number,
  card: Card
) {
  if (!context.hand) return

  let hhString = handToString(context.hand)
  switch (position) {
    case 0:
      if (!context.hand.flopCards) return
      context.hand.flopCards.cards[0] = card
      hhString = stripHandToSection("FLOP_CARDS", context.hand)
      break
    case 1:
      if (!context.hand.flopCards) return
      context.hand.flopCards.cards[1] = card
      hhString = stripHandToSection("FLOP_CARDS", context.hand)
      break
    case 2:
      if (!context.hand.flopCards) return
      context.hand.flopCards.cards[2] = card
      hhString = stripHandToSection("FLOP_CARDS", context.hand)
      break
    case 3:
      if (!context.hand.turnCards) return
      context.hand.turnCards.cards[0] = card
      hhString = stripHandToSection("TURN_CARDS", context.hand)
      break
    case 4:
      if (!context.hand.riverCards) return
      context.hand.riverCards.cards[0] = card
      hhString = stripHandToSection("RIVER_CARDS", context.hand)
      break
  }
  context.setHand(handFromString(context.hand.id, hhString))
}

type SCRIPT_SECTION =
  | "PLAYERS_COUNT"
  | "STACK_SIZES"
  | "FORCED_BETS"
  | "HERO"
  | "PREFLOP_ACTIONS"
  | "FLOP_CARDS"
  | "FLOP_ACTIONS"
  | "TURN_CARDS"
  | "TURN_ACTIONS"
  | "RIVER_CARDS"
  | "RIVER_ACTIONS"
  | "SHOWDOWN"

export const HandSectionsOrder: SCRIPT_SECTION[] = [
  "PLAYERS_COUNT",
  "STACK_SIZES",
  "FORCED_BETS",
  "HERO",
  "PREFLOP_ACTIONS",
  "FLOP_CARDS",
  "FLOP_ACTIONS",
  "TURN_CARDS",
  "TURN_ACTIONS",
  "RIVER_CARDS",
  "RIVER_ACTIONS",
  "SHOWDOWN",
]
export const stripHandToSection = (
  section: SCRIPT_SECTION,
  hand: Hand
): string => {
  return (
    handToString(hand)
      .split(";")
      .map((v) => v.trim())
      .slice(0, HandSectionsOrder.indexOf(section) + 1)
      .join(";") + ";"
  )
}
